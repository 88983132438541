/* eslint-disable react/require-default-props ,jsx-a11y/control-has-associated-label */
import React from "react";
import s from "./CircularProgressBar.module.scss";

type Props = {
  value: number;
  min?: number;
  max?: number;
  size?: number;
  foregroundColor?: string;
  backgroundColor?: string;
  insideColor?: string;
  fontColor?: string;
  text?: string;
  fontSize?: string;
};

export function CircularProgressBar({
  value,
  min = 0,
  max = 100,
  size = 36,
  foregroundColor = "#0012FF",
  backgroundColor = "#CDDFFE",
  insideColor = "#fff",
  fontColor = "#00163E",
  text = "",
  fontSize = "15px",
}: Props) {
  const style = {
    "--size": `${size / 16}rem`,
    "--fc": fontColor,
    "--fg": foregroundColor,
    "--bg": backgroundColor,
    "--value": value,
    "--max": max,
    "--fs": fontSize,
    "--percent": (value / max) * 100,
    "--ic": insideColor,
  } as React.CSSProperties;
  return (
    <div className={s.container}>
      <div
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={min}
        aria-valuemax={max}
        style={style}
      >
        {text ? text : `${value}/${max}`}
      </div>
    </div>
  );
}
