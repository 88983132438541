export type DictType = {
  [key: string]: any;
};

export enum COOKIE {
  accessToken = "access-token",
  refreshToken = "refresh-token",
  webviewType = "web_view_type",
}

export enum UrlType {
  External = 'external',
  Internal = 'internal',
}