import { UPDATE_STATUS_BAR } from "core-lib/constants/nativeBridge";
import { passMessageToNative } from "core-lib/utils/nativeBridge";
import { FunctionComponent, useEffect } from "react";

type props = {
  statusBarColor: string;
  theme?: "light" | "dark";
  cleanUpOnUnmount?: boolean;
};

export const StatusBar: FunctionComponent<props> = ({ statusBarColor, theme = "light", cleanUpOnUnmount = true }) => {
  const updateStatusBar = (bgColor: string, theme: string) => {
      passMessageToNative(UPDATE_STATUS_BAR, "", {
        bgColor,
        theme,
      });
  };

  useEffect(() => {
    updateStatusBar(statusBarColor, theme);

    return () => {
      if (cleanUpOnUnmount) {
        updateStatusBar("#ffffff", "dark");
      }
    };
  }, [statusBarColor]);

  return null;
};
