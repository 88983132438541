// EXPORT STATIC DATA FROM THIS FILE
import { API_SECRET, Host } from "../config/Env";
import { apiConfig } from "../config/config";

export const navList = [
  {
    name: "Parking",
    // icon: 'icons-parking',
    icon: "/img/parking.svg",
    // activeIcon: 'icons-parking-bold',
    activeIcon: "/img/parking-bold.svg",
    key: "home",
    route: [
      "/parking/book",
      "/",
      "/p/[dynamic_url]",
      "/p/[..extended_dynamic_url]",
    ],
    href: "/parking/book",
  },
  // { name: 'Search', icon: 'magnifier', key: 'search', route: '/search' },
  {
    name: "Challans",
    // icon: 'traffic-light',
    icon: "/img/traffic-light.svg",
    // activeIcon: 'traffic-light-bold',
    activeIcon: "/img/traffic-light-bold.svg",
    key: "trips",
    route: [
      "/services/challan",
      "/services/challan/list",
      "/c/challan-payment",
      "/c/challan-status",
      "/c/challan-status/[dynamic_url]",
      "/c/challan-payment/[dynamic_url]",
      "/c/[dynamic_url]",
    ],
    href: "/services/challan",
  },
  {
    name: "FASTag",
    // icon: 'icon-barrier',
    icon: "/img/barrier.svg",
    // activeIcon: 'icon-barrier-1',
    activeIcon: "/img/barrier-1.svg",
    key: "user",
    route: ["/fastag", "/f/[slug]", "/f/fastag-recharge"],
    href: "/fastag",
  },
  {
    name: "Vehicles",
    // icon: 'icons8-retro-car',
    icon: "/img/retro-car.svg",
    // activeIcon: 'icons8-retro-car-bold',
    activeIcon: "/img/retro-car-bold.svg",

    key: "vehicles",
    route: ["/my-vehicles"],
    href: "/my-vehicles",
  },
  {
    name: "Profile",
    // icon: 'icons8-user-settings',
    icon: "/img/profile.svg",
    // activeIcon: 'icons8-user-settings-bold',
    activeIcon: "/img/profile-bold.svg",
    key: "more",
    route: ["/profile"],
    href: "/profile",
  },
];

export const SLOT_INTERVAL = 30;

export const DATE_FORMAT = "YYYY-MM-DD HH:mm";

export const defaultCenterCoords = {
  lat: 28.6289019,
  lng: 77.2109096,
};

export const vehicleType = [
  { name: "4 Wheeler", value: 4 },
  { name: "2 Wheeler", value: 2 },
];

export const vehicleTypeWithIcon = [
  // { name: '4-Wheeler', value: 4, icon: 'retro-car1', desc: 'Car, SUV, etc.', iconType: 'svg' },
  {
    name: "4-Wheeler",
    value: 4,
    icon: "/img/retro-car-v2.svg",
    desc: "Car, SUV, etc.",
    iconType: "img",
  },
  // { name: '2-Wheeler', value: 2, icon: 'bike-front-view', desc: 'Scooter/Bike', iconType: 'svg' },
  {
    name: "2-Wheeler",
    value: 2,
    icon: "/img/bike-small.svg",
    desc: "Scooter/Bike",
    iconType: "img",
  },
];

export const fuelType = [
  { name: "Petrol", value: 0 },
  { name: "Diesel", value: 1 },
  { name: "CNG", value: 2 },
  { name: "Electric", value: 3 },
  { name: "Hybrid", value: 4 },
];

export const gender = [
  { name: "Male", value: "male", code: 1 },
  { name: "Female", value: "female", code: 2 },
];

export const projectType = {
  society: 1,
  corporate: 2,
};

export const vehicleStatus = {
  unverified: 0,
  verified: 1,
  semiVerified: 2,
  secondaryRequested: 3,
  secondaryApproved: 4,
  secondaryRejected: 5,
  addedForSomeoneElse: 6,
  secondaryPending: 7,
  secondaryUnrequested: 8,
};

export const vehicleDocStatus = {
  uploaded: 1,
  approved: 2,
  rejected: 3,
};

export const docType = [
  {
    name: "RC",
    type: "vehicle",
    label: "RC",
  },
  {
    name: "PAN_CARD",
    type: "id",
    label: "Pan Card",
  },
  {
    name: "DL",
    type: "id",
    label: "DL",
  },
  {
    name: "AADHAR",
    type: "id",
    label: "Aadhar",
  },
  {
    name: "VOTER_ID",
    type: "id",
    label: "Voter Id",
  },
  {
    name: "PASSPORT",
    type: "id",
    label: "Passport",
  },
  {
    name: "VEHICLE",
    type: "photo",
    label: "Vehicle Photo",
  },
];

export const vehicleCodeWithState = {
  AD: "Andhra Pradesh",
  AR: "Arunachal Pradesh",
  AS: "Assam",
  BR: "Bihar",
  CG: "Chattisgarh",
  DL: "Delhi",
  GA: "Goa",
  GJ: "Gujarat",
  HR: "Haryana",
  HP: "Himachal Pradesh",
  JK: "Jammu and Kashmir",
  JH: "Jharkhand",
  KA: "Karnataka",
  KL: "Kerala",
  LD: "Lakshadweep Islands",
  MP: "Madhya Pradesh",
  MH: "Maharashtra",
  MN: "Manipur",
  ML: "Meghalaya",
  MZ: "Mizoram",
  NL: "Nagaland",
  OD: "Odisha",
  PY: "Pondicherry",
  PB: "Punjab",
  RJ: "Rajasthan",
  SK: "Sikkim",
  TN: "Tamil Nadu",
  TS: "Telangana",
  TR: "Tripura",
  UP: "Uttar Pradesh",
  UK: "Uttarakhand",
  WB: "West Bengal",
  AN: "Andaman and Nicobar Islands",
  CH: "Chandigarh",
  DN: "Dadra and Nagar Haveli",
  DD: "Daman and Diu",
  LA: "Ladakh",
  OT: "Other Territory",
};

export const APP_MODE = {
  b2c: "B2C",
  corporate: "CORPORATE",
};

export const PLATFORM = {
  android: "Android",
  ios: "iOS",
  windowsPhone: "Windows Phone",
  pwa: "PWA",
};
export const ORIGIN_PARKPLUS_CAR_WASH_CLEANER_WEBVIEW =
  "parkplus_car_wash_cleaner_web_view";

export const LOCAL_STORAGE_KEYS = {
  thirdPartyPartnerId: "thirdPartyPartnerId",
  appOrigin: "appOrigin",
  fastagAdditionalData: "fastagAdditionalData",
  contentVideoId: "contentVideoId",
  contentVideoDuration: "contentVideoDuration",
  tempLocalFile: "tempLocalFile",
  upiMethods: "upiMethods",
  qrCallbackBtnClick: "qrCallbackBtnClick",
  lat: "lat",
  long: "long",
};

export const STRAPI_ENDPOINT = apiConfig(Host.strapi);
export const STRAPI_AUTH_TOKEN =
  "93e45087615fa472aa548a8d07547c8a5065149b6dffa074339f02e653eec5767efdb61968e913191a9294bb3c62c8501099dee023981903cce877e60b93a2bb68e4517d235103ad4b64bf17d05fad1c1ae7be2442877fbae15b8aa600d5f699b0df4500edc1ee2df3b7dfd77435c87801638deba8588261754e74ae64ba1a3a";

export const WEBSITE_URL = "https://parkplus.io";
export const PWA_HOSTNAME = "https://parkplus.io";
export const PWA_URL = `${PWA_HOSTNAME}/app`;
export const B2B_URL = `${PWA_URL}/b2b`;

export const PPLUS_CLIENT_ID = API_SECRET.clientId;

export const PPLUS_CLIENT_SECRET = API_SECRET.clientSecret;

export function mapURL(inputURL: string) {
  const regexMappings = [
    {
      regex: /https:\/\/ik\.imagekit\.io\/parkplus\/parkplus-community/g,
      replacement:
        "https://parkplus-bkt-img.parkplus.io/production/social-community/public",
    },
    {
      regex: /https:\/\/ik\.imagekit\.io\/parkplus\/gift-voucher/g,
      replacement:
        "https://parkplus-bkt-img.parkplus.io/production/parkplus-gamification-gv/public",
    },
    {
      regex: /https:\/\/ik\.imagekit\.io\/parkplus\/ppl/g,
      replacement:
        "https://parkplus-bkt-img.parkplus.io/production/vehicle-vertical/public",
    },
    {
      regex: /https:\/\/ik\.imagekit\.io\/parkplus\/parkplus-project-images/g,
      replacement: "https://parkplus-project-images.parkplus.io",
    },
    {
      regex: /https:\/\/ik\.imagekit\.io\/parkplus\/gcp-parkplus-production/g,
      replacement: "https://strapi-file-uploads.parkplus.io/",
    },
    {
      regex: /https:\/\/ik\.imagekit\.io\/parkplus\/parkplus-presigned-images/g,
      replacement: "https://presigned-images.parkplus.io/",
    },
    {
      regex: /https:\/\/ik\.imagekit\.io\/parkplus\/parkplus-production/g,
      replacement: "https://parkplus-production-img-bkt.parkplus.io",
    },
    {
      regex: /https:\/\/ik\.imagekit\.io\/parkplus\/catalog/g,
      replacement: "https://store-images-cdn.parkplus.io/catalog",
    },
    {
      regex: /https:\/\/ik\.imagekit\.io\/parkplus/g,
      replacement: "https://parkplus-bkt-img.parkplus.io",
    },
  ];

  for (const mapping of regexMappings) {
    if (mapping.regex.test(inputURL)) {
      return inputURL.replace(mapping.regex, mapping.replacement);
    }
  }
  return inputURL; // Default case if the inputURL does not match any pattern in the mappings
}
export function replaceImageKitUrl(obj: any) {
  if (typeof obj === "object" && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map((item) => replaceImageKitUrl(item));
    } else {
      const newObj = {};
      for (const key in obj) {
        newObj[key] = replaceImageKitUrl(obj[key]);
      }
      return newObj;
    }
  } else {
    return mapURL(obj);
  }
}
