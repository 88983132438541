import classNames from "classnames";
import { BadgeVariants } from "ui/primitives/BadgeTypes";
import s from "./Badge.module.scss";
import { BadgeVariantsType, TypeScales } from "../../primitives";
import { Typography } from "ui";

type BadgeProps = {
  variant: BadgeVariantsType;
  label?: string;
  icon?: any;
  className?: string;
  isRectangle?: boolean;
  isBadge?: boolean;
};

export const Badge = ({
  variant = BadgeVariants.Success,
  label,
  icon,
  className,
  isRectangle = false,
  isBadge = false,
}: BadgeProps) => (
  <div
    className={classNames(s.badge, s[variant], {
      [s.rounded]: !isRectangle,
      [className]: !!className,
    })}
  >
    {icon && <div className={s.icon}>{icon}</div>}
    {icon && label && <div className={s.margin} />}
    {label && (
      <Typography
        variant={isBadge ? TypeScales.tiny_uppercase : TypeScales.body_para_m}
        label={label}
      />
    )}
  </div>
);
