import { Environments, HOST_ENV_MAP } from "./Env";

export const getOrigin = () => global.origin;

export const getOriginByEnv = () => {
  let origin;
  if (process.env.APP_ENV === Environments.Staging) {
    origin = `https://mobileweb-stg.parkplus.io`;
  } else if (process.env.APP_ENV === Environments.Production) {
    origin = `https://parkplus.io`;
  } else {
    origin = `http://localhost:3001`;
  }
  return origin;
};
export const apiConfig = (repo: string, version: string | null = null) => {
  let apiHost = HOST_ENV_MAP[repo];
  if (version !== null) apiHost += `/${version}`;
  return apiHost;
};

export const COLORS = {
  black: "#000000",
  blue: "#2B79FF",
  red: "#CC001B",
  green: "#00AD47",
  darkGreen: "#026d46",
  lightGreen: "rgb(208 255 218)",
  yellow: "#ffd52a",
  darkYellow: "#F07D00",
  lightYellow: "#ffcf9a",
  warningYellow: "#f9f78e",
  lightRed: "#fce4ec",
  midRed: "#ff9a9a",
  orange: "#fd6e21",
  white: "#fff",
  greyShade1: "#F0F0F1",
  greyShade2: "#ABABB2",
  offWhite: "rgb(247 247 247)",
  blueShade1: "#FAFCFF",
  lightBlue: "#DEEAFF",
  lightGreen2: "#CEFFED",
  lightOrange: "#FFEDCB",
  darkGreen2: "#00C07B",
  darkBlue: "#2B79FF",
  pendingYellow: "#FFE7DA",
  pendingTextColor: "#FF6100",
};


export enum NATIVE_PERMISSION_TYPE {
  Location = "location",
  Contact = "contact",
  Storage = "storage",
  Notification = "notification",
}
