import { getDefaultHeaders } from "core-lib/utils/network";
import { postMethod } from "core-lib/utils/apiV2";
import { apiConfig } from "core-lib/config/config";

export async function getRefreshToken(token: string): Promise<string> {
  const headers = {
    ...getDefaultHeaders({
      browser: true,
      os: true,
      packageName: true,
      appName: true,
    }),
  };

  let endPoint = `${apiConfig("user")}/user/refreshToken/`;
  const body = { token };
  const { response, error } = await postMethod({ endPoint, body, headers });
  if (response?.token) {
    return response?.token;
  } else {
    throw error;
  }
}
