import React from "react";
import { Modal, Button, Typography } from "ui";

import { ActionButtonType } from "../AppBar";
import s from "./EditField.module.scss";
import ArrowLeft from "../icons/ArrowLeft";

type EditFieldProps = {
  show: boolean;
  onClose: () => void;
  buttonLabel?: string;
  heading?: string;
  subheading?: string;
  children?: React.ReactElement;
  showBottomButton?: boolean;
  isButtonDisabled?: boolean;
  onButtonClick: () => void;
  rightActions?: ActionButtonType[];
};

export const EditField: React.FunctionComponent<EditFieldProps> = ({
  show,
  onClose,
  buttonLabel = "Proceed",
  heading,
  subheading,
  children,
  showBottomButton = true,
  isButtonDisabled = false,
  onButtonClick,
  rightActions,
}) => {
  const getRightActions = () => {
    const obj: any = {};
    if (rightActions) {
      obj.rightActions = rightActions;
    }
    return obj;
  };

  return (
    <Modal
      show={show}
      variant="page"
      head={{
        show: true,
        title: "",
        onClose: () => onClose(),
        appBarData: {
          backButton: { show: false },
          bgColor: "background_primary",
          leftActions: [
            {
              icon: <ArrowLeft primaryColor="text_default" />,
              onClick: onClose,
              type: "back",
            },
          ],
          ...getRightActions(),
          title: "",
          onClose,
        },
      }}
      noPadding
      animation={{ type: "rtl", timeout: "m" }}
    >
      <div className={s.root}>
        <div className={s.top}>
          {heading && (
            <div className={s.heading}>
              <Typography label={heading} color="text_default" variant="h2" />
            </div>
          )}

          {subheading && (
            <div className={s.subheading}>
              <Typography
                label={subheading}
                color="text_subheading"
                variant="body_big_r"
              />
            </div>
          )}

          {children && children}
        </div>
        {showBottomButton && (
          <div className={s.buttonContainer}>
            <Button
              label={buttonLabel}
              onClick={onButtonClick}
              fullwidth
              isDisabled={isButtonDisabled}
              variant="ButtonPrimary"
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
