import { HAPTIC_FEEDBACK } from "core-lib/constants/nativeBridge";
import { passMessageToNative } from "./nativeBridge";
import { isParkPlusWebView } from "./source";

export enum VibrationType {
  VeryLight,
  Light,
  Moderate,
  Hard,
}

export const getVibrationType = (vibrationType: VibrationType) => {
  switch (vibrationType) {
    case VibrationType.VeryLight: {
      return {
        duration: 50,
        amplitude: 15,
      };
    }
    case VibrationType.Light:
      return {
        duration: 100,
        amplitude: 20,
      };
    case VibrationType.Moderate:
      return {
        duration: 125,
        amplitude: 30,
      };
    case VibrationType.Hard:
      return {
        duration: 150,
        amplitude: 40,
      };
  }
};

// repeat index -1 indicates no repeat
const vibrate = (
  vibrationType = VibrationType.Light,
  repeatIndex = -1
) => {
  const formattedValues = getVibrationType(vibrationType);

  if (isParkPlusWebView()) {
    passMessageToNative(HAPTIC_FEEDBACK, null, {
      timings: [formattedValues.duration],
      amplitudes: [formattedValues.amplitude],
      repeatIndex,
    });
  } else if (global?.window?.navigator?.vibrate) {
    global.window.navigator.vibrate(formattedValues.duration);
  }
};

export default vibrate;
