import { watchMisc } from './action';
import miscReducer from './reducer';

export function getMiscModule() {
  return {
    // Unique id of the module
    id: 'misc',
    // Maps the Store key to the reducer
    reducerMap: {
      misc: miscReducer,
    },
    // This module uses redux-saga middleware
    // This property will be be used by the SagaExtension
    // to run sagas for the moduleD
    sagas: [watchMisc],
  };
}
