// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const { SENTRY_DSN } = process.env;
const { ENV } = process.env;

Sentry.init({
  dsn: ENV === 'production' ? SENTRY_DSN : null,
  environment: ENV === 'production' ? ENV : 'development',
  // dsn: SENTRY_DSN,
  // environment: ENV,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
