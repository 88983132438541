import React from 'react';
import s from './AspectRatio.module.scss';
import { isNumeric } from 'core-lib/utils/regex';

export function AspectRatio({
  aspectRatio = '1:1',
  children,
}: {
  aspectRatio: string;
  children: JSX.Element;
}) {
  let paddingTop = '100%';
  if (aspectRatio) {
    const split = aspectRatio.split(':');
    if (isNumeric(split[0]) && isNumeric(split[1])) {
      paddingTop = `${(Number(split[1]) / Number(split[0])) * 100}%`;
    }
  }
  return (
    <div className={s.root} style={{ paddingTop }}>
      <div className={s.container}>{children}</div>
    </div>
  );
}
