/* eslint-disable no-nested-ternary */
import React from "react";

type CardProps = {
  children: React.ReactNode;
  paddingHorizontal?: 0 | 4 | 8 | 12 | 16 | 24 | 32;
  paddingVertical?: 0 | 4 | 8 | 12 | 16 | 24 | 32;
  borderRadius?: 0 | 4 | 8 | 12 | 16 | 24 | 32;
  borderStyle?: string;
  bgColor?: string;
};

export const Card = ({
                       children,
                       bgColor,
                       paddingHorizontal = 0,
                       paddingVertical = 0,
                       borderRadius = 12,
                       borderStyle = `1px solid var(--grey_30)`
                     }: CardProps) => (
  <div
    style={{
      paddingTop: `${paddingVertical ?? 0}px`,
      paddingRight: `${paddingHorizontal ?? 0}px`,
      paddingBottom: `${paddingVertical ?? 0}px`,
      paddingLeft: `${paddingHorizontal ?? 0}px`,
      border: borderStyle,
      borderRadius: `${borderRadius}px`,
      backgroundColor: bgColor
    }}
  >
    {children}
  </div>
);
