import { ButtonVariantsType, ErrorUiType } from "./Typings";

export enum ErrorUiTypeEnum {
  toast_success = "toast_success",
  toast_err = "toast_err",
  toast_warn = "toast_warn",
  toast_info = "toast_info",
  dialog_err = "dialog_err",
  dialog_warn = "dialog_warn",
  bottom_sheet_err = "bottom_sheet_err",
  bottom_sheet_warn = "bottom_sheet_warn",
}

export type CtaDataType = {
  label: string;
  onClick: () => void;
  variant?: ButtonVariantsType;
};

export type ErrorObjectType = {
  title: string;
  description?: string;
  ui_type?: ErrorUiType;
  cta?: CtaDataType[];
};

export type ErrorFullPagePopupProps = {
  show: boolean;
  onClose: () => void;
  errObj: ErrorObjectType;
  cta?: CtaDataType;
};

export type ErrorPopupProps = {
  show: boolean;
  onClose: () => void;
  errObj: ErrorObjectType;
  cta?: CtaDataType[];
};

export type ErrorBottomSheetProps = {
  show: boolean;
  onClose: () => void;
  errObj: ErrorObjectType;
  cta?: CtaDataType[];
};

export type ErrorToastProps = {
  errObj: ErrorObjectType;
  cta?: CtaDataType;
  isSticky?: boolean;
};
