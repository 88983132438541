import cn from "classnames";
import { useEffect, useMemo, useState } from "react";
import { Typography } from "ui";
import { ColorType, FontVariantType } from "ui/primitives";
import Digit from "./Digit";
import s from "./Odometer.module.scss";
import { isStringValid } from "core-lib/utils/utils";

type OdometerProps = {
  initialValue?: number;
  finalValue: number;
  duration?: number;
  delay?: number;
  format?: string;
  suffix?: string;
  prefix?: string;
  digitsMaxLength?: number;
  textColor?: ColorType;
  textVariant?: FontVariantType;
  parentClassname?: string;
  childClassname?: string;
  textClassname?: string;
};

const getPaddedValue = (value: number, maxLength: number) =>
  String(value).padStart(maxLength, "0").split("").map(Number);

const formattedCharacters = [",", ".", "%"];

const formatValue = (value: number[], format: string) => {
  const valueStr = value.join("");
  const formattedValue = [];
  let valueIndex = valueStr.length - 1;
  let formatIndex = format.length - 1;

  while (valueIndex >= 0) {
    if (formattedCharacters.includes(format[formatIndex])) {
      formattedValue.unshift(format[formatIndex]);
      formatIndex = (formatIndex - 1 + format.length) % format.length;
    } else {
      formattedValue.unshift(Number(valueStr[valueIndex]));
      valueIndex--;
      formatIndex = (formatIndex - 1 + format.length) % format.length;
    }
  }

  return formattedValue;
};

/**
 * @param originalValue - The number from which odometer will start transitioning
 * @param finalValue - the number at which odometer will end transitioning
 * @param duration - Duration of animation in milliseconds
 * @param delay - Delay after which odometer will transition
 * @param format - Change the way digit groups are formatted
 * @param suffix - Suffix to be added at the end
 * @param prefix - Prefix to be added at the end
 * @param digitsMaxLength - Number of digits in odometer
 * @param textColor - Typography color
 * @param textVariant - Typography variant
 * @param parentClassname - Classname to be applied to the parent
 * @param childClassname - Classname to be applied to digit's container
 * @param textClassname - Classname to be applied to the digit
 */
export const Odometer = ({
  initialValue = 0,
  finalValue,
  duration,
  delay = 500,
  format = "",
  prefix = "",
  suffix = "",
  digitsMaxLength = finalValue.toString().length,
  parentClassname = "",
  childClassname,
  textColor,
  textVariant,
  textClassname,
}: OdometerProps) => {
  // state
  const [value, setValue] = useState(
    getPaddedValue(initialValue, digitsMaxLength)
  );

  const formattedValue = useMemo(
    () => (isStringValid(format) ? formatValue(value, format) : value),
    [value, format]
  );

  useEffect(() => {
    setTimeout(
      () => setValue(getPaddedValue(finalValue, digitsMaxLength)),
      delay
    );
  }, [finalValue]);

  return (
    <div className={cn(s.odometer, { [parentClassname]: !!parentClassname })}>
      {prefix && (
        <Typography label={prefix} variant={textVariant} color={textColor} />
      )}
      {formattedValue.map((digit, i) =>
        typeof digit === "number" ? (
          <Digit
            key={i}
            value={Number(digit)}
            duration={duration}
            childClassname={childClassname}
            textColor={textColor}
            textVariant={textVariant}
            textClassname={textClassname}
          />
        ) : (
          <Typography
            key={i}
            label={digit}
            variant={textVariant}
            color={textColor}
          />
        )
      )}
      {suffix && (
        <Typography label={suffix} variant={textVariant} color={textColor} />
      )}
    </div>
  );
};
