import cn from "classnames";
import { useRef } from "react";
import { AnimatedImage, Button, Typography } from "ui";
import Export from "ui/components/icons/Export";
import TickLinear from "ui/components/icons/Tick";
import { ButtonVariants, Color, TypeScales } from "ui/primitives";
import s from "./FileInput.module.scss";

export type InputFieldType = {
  image?: string | ArrayBuffer;
  handleChange: (file: string, fileType: any) => void;
  title: string;
  subtitle?: string;
  isUploaded: boolean;
  isLoading: boolean;
  variant?: "default" | "no-borders";
  onImageClick?:()=>void;
};

export const FileInput = ({ image, handleChange, title, subtitle, isUploaded, isLoading, variant = "default",onImageClick }: InputFieldType) => {
  const ref = useRef<any>();
  const handleInput = (e) => {
    const fileType = e.target?.files[0]?.type?.split("/")[0];
    const file = e.target.files[0];
    handleChange(file, fileType);
  };
  return (
    <div className={cn(s.input_root, { [s.no_border]: variant == "no-borders" })}>
      <div className={s.left}>
        <div className={s.icon} onClick={!!onImageClick?onImageClick:()=>{}}>{!image ? <Export height={18} width={18} /> : <AnimatedImage src={image as string} />}</div>
        <div className={s.text}>
          <Typography label={title} variant={TypeScales.body_m} />
          {isUploaded && (
            <div className={s.uploaded}>
              <TickLinear height={16} width={16} primaryColor={Color.positive_50} />
              <Typography label="Uploaded" color={Color.positive_50} variant={TypeScales.body_para_sb} />
            </div>
          )}
          {!isUploaded && subtitle && <Typography label={subtitle} variant={TypeScales.small_m} color={Color.text_subheading} style={{ marginTop: "4px" }} />}
        </div>
      </div>

      <input type="file" ref={ref} onChange={handleInput} />
      <div className={s.right}>
        <Button
          label={isUploaded ? "Change" : "Upload"}
          variant={variant == "default" ? ButtonVariants.PrimaryNaked : ButtonVariants.ButtonSecondary}
          onClick={() => ref.current.click()}
          isLoading={isLoading}
          spinnerVariant="blue"
          size={"Small"}
        />
      </div>
    </div>
  );
};
