import {
  GENERIC_ERROR_EVENT
} from "core-lib/constants/common";
import { isServer } from "./utils";
import { ErrorObjectType, ErrorUiTypeEnum } from "ui/primitives";

export const genericErrorHandler = (errorResponse) => {
  let errorObj: ErrorObjectType = errorResponse?.data?.err_obj;
  if(!errorObj){
    errorObj = {
      ui_type: ErrorUiTypeEnum.toast_info,
      title: errorResponse?.data?.message ??  "Error"
    }
  }
  if (!isServer()) {
    const errorEvent = new CustomEvent(GENERIC_ERROR_EVENT,{detail:errorObj});
    window.dispatchEvent(errorEvent)
  }
};
