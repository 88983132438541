import * as React from "react";
import { Color } from "../../../primitives";
import { IndicatorType } from "../../../primitives/ProgressIndicatorTypes";
import s from "./Vertical.module.scss";
import CheckCircle1 from "../../newIcons/CheckCircle1";
import Clock1 from "../../newIcons/Clock1";
import Typography from "../../Typography";

export function Vertical({
                           currentStep,
                           steps,
                           color: activeColor = Color.interaction_60,
                           backgroundColor: inactiveColor = Color.grey_50,
                           size: height = 40,
                           width = 2.5
                         }: IndicatorType) {
  return (
    <div className={s.root}>
      {steps?.map((item, idx) => (
        <div className={s.item} key={item?.key}>
          <div className={s.left}>
            {currentStep > idx ? (
              <CheckCircle1 width={20} height={20} primaryColor={activeColor} />
            ) : (
              <Clock1 width={20} height={20} primaryColor={inactiveColor} />
            )}

            {idx < steps.length - 1 && (
              <div
                style={{
                  width: width,
                  minHeight: height,
                  backgroundColor:
                    currentStep > idx
                      ? `var(--${activeColor})`
                      : `var(--${inactiveColor})`
                }}
                className={s.divider}
              />
            )}
          </div>

          <div className={s.right}>
            <Typography
              label={item?.label}
              variant="body_m"
              color={
                currentStep > idx ? Color.text_default : Color.text_subheading
              }
              style={{ whiteSpace: "break-spaces" }}
            />
            <Typography
              label={item?.description}
              variant="small_r"
              color={
                currentStep > idx ? Color.text_label : Color.text_placeholder
              }
              style={{ whiteSpace: "break-spaces" }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
