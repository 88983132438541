/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
import { ColorType } from "ui/primitives";
import BlankStar from "./BlankStar";
import FullStar from "./FullStar";
import PartialStar from "./PartialStar";
import s from "./StarRating.module.scss";

type StarRatingtype = {
  totalRating: number;
  currentRating: number;
  size?: number;
  partialStarColors?: {
    primaryColor?: ColorType;
    secondaryColor?: ColorType;
  };
  fullStarColor?: ColorType;
  blankStarColor?: ColorType;
  style?: React.CSSProperties;
};

export const StarRating = ({
  totalRating,
  currentRating,
  size = 20,
  partialStarColors = {
    primaryColor: "warning_60",
    secondaryColor: "grey_30",
  },
  fullStarColor = "warning_30",
  blankStarColor = "grey_30",
  style = {},
}: StarRatingtype) => {
  function getRatingOutOfTen(idx) {
    const currentIdx = idx + 1;
    if (currentIdx <= currentRating) {
      return (
        <FullStar primaryColor={fullStarColor} width={size} height={size} />
      );
    } else if (currentIdx === Math.ceil(currentRating)) {
      const val = 1 - (currentIdx - currentRating);
      return (
        <PartialStar
          primaryColor={partialStarColors.primaryColor}
          secondaryColor={partialStarColors.secondaryColor}
          rating={val}
          height={size}
          width={size}
        />
      );
    }
    return (
      <BlankStar primaryColor={blankStarColor} width={size} height={size} />
    );
  }
  return (
    <div className={s.root} style={style}>
      {[...new Array(totalRating)]?.map((item, idx) => (
        <div className={s.item} key={idx}>
          {getRatingOutOfTen(idx)}
        </div>
      ))}
    </div>
  );
};
