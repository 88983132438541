import React from "react";
import {
  Color,
  ColorType,
  FontVariantType,
  RenderType,
  TypeScales,
  TypographyHtmlTags,
} from "ui/primitives";
import classNames from "classnames";
import s from "./Typography.module.scss";

type TypographyProps = {
  variant?: FontVariantType;
  color?: ColorType;
  renderAs?: RenderType;
  className?: string;
  style?: React.CSSProperties;
  label: any;
  onClick?: (args?: any) => void;
};

export const Typography = ({
  variant = TypeScales.body_m,
  color = Color.text_default,
  className,
  renderAs = TypographyHtmlTags.P,
  style,
  label,
  onClick = () => {},
}: TypographyProps) => {
  const colorValue = `var(--${color})`;

  const classValue = classNames(s[variant], {
    [className]: !!className,
  });
  const customStyle = { color: colorValue, ...style };
  return {
    [TypographyHtmlTags.H1]: (
      <h1
        suppressHydrationWarning
        className={classValue}
        style={customStyle}
        onClick={onClick}
      >
        {label}
      </h1>
    ),
    [TypographyHtmlTags.H2]: (
      <h2
        suppressHydrationWarning
        className={classValue}
        style={customStyle}
        onClick={onClick}
      >
        {label}
      </h2>
    ),
    [TypographyHtmlTags.H3]: (
      <h3
        suppressHydrationWarning
        className={classValue}
        style={customStyle}
        onClick={onClick}
      >
        {label}
      </h3>
    ),
    [TypographyHtmlTags.H4]: (
      <h4
        suppressHydrationWarning
        className={classValue}
        style={customStyle}
        onClick={onClick}
      >
        {label}
      </h4>
    ),
    [TypographyHtmlTags.H5]: (
      <h5
        suppressHydrationWarning
        className={classValue}
        style={customStyle}
        onClick={onClick}
      >
        {label}
      </h5>
    ),
    [TypographyHtmlTags.H6]: (
      <h6
        suppressHydrationWarning
        className={classValue}
        style={customStyle}
        onClick={onClick}
      >
        {label}
      </h6>
    ),
    [TypographyHtmlTags.P]: (
      <p
        suppressHydrationWarning
        className={classValue}
        style={customStyle}
        onClick={onClick}
      >
        {label}
      </p>
    ),
    [TypographyHtmlTags.SPAN]: (
      <span
        suppressHydrationWarning
        className={classValue}
        style={customStyle}
        onClick={onClick}
      >
        {label}
      </span>
    ),
  }[renderAs];
};
