import classnames from "classnames";
import React from "react";
import withCheckHOC from "../../HOCs/withCheckbox";
import { Color, ColorsPallete, TypeScales } from "../../primitives";
import Typography from "../Typography";
import s from "./Radio.module.scss";

interface RadioProps extends React.HTMLAttributes<HTMLInputElement> {
  isChecked: boolean;
  disabled: boolean;
  id: string;
  handleChange?: React.ChangeEventHandler<HTMLInputElement>;
  label?: string;
  description?: string;
  strokeColor?: string;
}

const Radio = (props: RadioProps) => {
  const {
    isChecked,
    disabled,
    id,
    label,
    description,
    handleChange,
    strokeColor = ColorsPallete[Color.stroke_divider],
    ...propsToFwd
  } = props;
  return (
    <label htmlFor={id} className={s.label}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        disabled={disabled}
        id={id}
        {...propsToFwd}
      />
      <div className={s.checkmark_wrapper}>
        <div
          className={classnames(s.checkmark, {
            [s.hasChildren]: !!label,
            [s.checked]: isChecked,
          })}
          style={{ borderColor: !isChecked ? strokeColor : null }}
        >
          <svg
            className={classnames({ [s.tickSvg]: isChecked })}
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <circle cx="5" cy="5" r="5" fill="#442698" />
          </svg>
        </div>
        <div className={s.children}>
          {label && (
            <Typography
              color={disabled ? Color.grey_40 : Color.text_default}
              variant={TypeScales.body_r}
              label={label}
            />
          )}
          {description && (
            <Typography
              color={disabled ? Color.grey_40 : Color.grey_60}
              variant={TypeScales.small_r}
              label={description}
            />
          )}
        </div>
      </div>
    </label>
  );
};

export default withCheckHOC(Radio);
