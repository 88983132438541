import React from 'react';
import Transition, { AnimationTypes } from './Transition';
import {isServer} from "core-lib/utils/utils";

export default function TransitionTimeout({
  children,
  animation,
  timer = 5,
  depends,
  disableOnPopState = false,
}: {
  animation: AnimationTypes;
  children: any;
  depends?: any;
  timer?: number;
  disableOnPopState?: boolean;
}) {
  let finalAnimation = animation;

  const [show, setShow] = React.useState<boolean>(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
      clearTimeout(timeout);
    }, timer);
    return () => clearTimeout(timeout);
  }, []);

  React.useEffect(() => {
    let timeout: any = null;
    if (depends > -1) {
      timeout = setTimeout(() => {
        setShow(true);
        clearTimeout(timeout);
      }, timer);

      return () => clearTimeout(timeout);
    }
  }, [depends]);

  // If popstate enabled then children should render without animation
  if (
    disableOnPopState &&
    !isServer() &&
    // @ts-ignore
    window.popStateData
  ) {
    // @ts-ignore
    if (
      // @ts-ignore
      window.popStateData.isNavigatedFromLastPage &&
      // @ts-ignore
      window.popStateData.targetRoute === window.location.pathname
    ) {
      // @ts-ignore
      finalAnimation = null;
    }
  }

  return (
    <Transition show={show} animation={finalAnimation}>
      {/* adding fragment to prevent crash when children is null | undefined */}
      <>{children}</>
    </Transition>
  );
}
