import cn from "classnames";
import { handleBack } from "core-lib/utils/common";
import React, { useEffect, useRef, useState } from "react";

import s from "./BackButton.module.scss";

import {
  removeClassFromList,
  setAndGetBackBtnClass,
} from "core-lib/utils/backButton";
import ArrowLeft from "ui/components/newIcons/ArrowLeft";
import ChevronLeft from "../icons/ChevronLeft";
import Cross from "../icons/Cross";

export function BackButton({
  onBack,
  type = "default",
  children,
  className,
  customStyle,
  noPadding = false,
  domId,
}: {
  onBack?: any;
  type?:
    | "default"
    | "white"
    | "arrow"
    | "hidden"
    | "cross"
    | "chevronLeft"
    | "arrowLeft"
    | "arrowLeftWhite";
  children?: React.ReactNode;
  customStyle?: any;
  className?: string;
  noPadding?: boolean;
  domId?: string;
}) {
  const [didRender, setDidRender] = useState(false);
  const btnRef = useRef();
  const btnClassRef = useRef();

  useEffect(() => {
    setDidRender(true);
  }, []);

  useEffect(() => {
    if (didRender) {
      const cName = setAndGetBackBtnClass();
      // @ts-ignore
      btnClassRef.current = cName;
      // @ts-ignore
      btnRef?.current?.classList.add(cName);
    }
    return () => {
      if (btnClassRef) removeClassFromList(btnClassRef.current);
    };
  }, [didRender]);

  function onBackClick(e: any) {
    if (onBack) {
      onBack(e);
    } else {
      handleBack();
    }
  }

  // TODO: Commented for now but needs to check whether this is working
  // if (!didRender) return null;

  return (
    <button
      id={domId}
      //@ts-ignore
      ref={btnRef}
      type="button"
      className={cn("cmn_touchable_area", {
        [s.back]: type !== "hidden" && !noPadding,
        cmn_hide: type === "hidden",
        [`${className}`]: !!className,
      })}
      style={customStyle}
      onClick={onBackClick}
    >
      {children && children}
      {!children && (
        <>
          {type === "default" && <ChevronLeft primaryColor="text_default" />}
          {type === "white" && <ChevronLeft primaryColor="grey_white" />}
          {type === "arrow" && <ChevronLeft primaryColor="text_default" />}
          {type === "chevronLeft" && <ChevronLeft />}
          {type === "cross" && <Cross />}
          {type === "arrowLeft" && <ArrowLeft />}
          {type === "arrowLeftWhite" && <ArrowLeft primaryColor="grey_white" />}
          {type === "hidden" && <span />}
        </>
      )}
    </button>
  );
}
