import { SessionStorage } from "core-lib/constants/storage";

export function onModalVisibilityChange(isVisible: boolean, identifier) {
  let modalIdentifiers = [];
  const modalIdentifiersLocal = global.sessionStorage.getItem(
    SessionStorage.ActiveModalIdentifiers
  );
  if (modalIdentifiersLocal)
    modalIdentifiers = JSON.parse(modalIdentifiersLocal);

  // Sync identifiers with the active modals in dom
  const filteredModalIdentifiers = [];
  modalIdentifiers.forEach((identifier) => {
    const activeModal = document.getElementById(`modalId-${identifier}`);
    if (activeModal) filteredModalIdentifiers.push(identifier);
  });
  modalIdentifiers = filteredModalIdentifiers;

  if (isVisible) {
    if (!modalIdentifiers.includes(identifier))
      modalIdentifiers.push(identifier);
    global.document.body.style.overflow = "hidden";
  } else {
    const identifierIdx = modalIdentifiers.indexOf(identifier);
    if (identifierIdx !== -1) modalIdentifiers.splice(identifierIdx, 1);
    if (!modalIdentifiers.length) {
      global.document.body.style.overflow = "unset";
    }
  }

  global.sessionStorage.setItem(
    SessionStorage.ActiveModalIdentifiers,
    JSON.stringify(modalIdentifiers)
  );
}
