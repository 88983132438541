import { openDB } from "idb";
import { isServer } from "./utils";

// Queue for cache updates
const cacheUpdateQueue = [];
let isCacheUpdating = false;

// Check if IndexedDB is available
const isIndexedDBAvailable = () => !isServer() && window?.indexedDB;

// Create or connect to the database
const dbPromise = isIndexedDBAvailable()
  ? openDB("api-cache", 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains("cache")) {
          db.createObjectStore("cache");
        }
      },
    })
  : null;

export async function getCachedData(key: string) {
  if (!isIndexedDBAvailable()) return null;

  try {
    const db = await dbPromise;
    const data = await db.get("cache", key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error("Error getting cached data:", error);
  }
}

export async function saveDataToCache(key: string, data: any) {
  if (!isIndexedDBAvailable()) return null;

  try {
    const db = await dbPromise;
    await db.put("cache", JSON.stringify(data), key);
  } catch (error) {
    console.error("Error saving data to cache:", error);
  }
}

export async function deleteDataFromCache(key: string) {
  if (!isIndexedDBAvailable()) return null;

  try {
    const db = await dbPromise;
    await db.delete("cache", key);
  } catch (error) {
    console.error("Error deleting data from cache:", error);
  }
}

// Add data to the cache queue
export async function addToCacheQueue(key: string, data: any) {
  cacheUpdateQueue.push({ key, data });

  // Process the queue in the background
  await processCacheUpdateQueue();
}

async function processCacheUpdateQueue() {
  if (isCacheUpdating || cacheUpdateQueue.length === 0) {
    return null;
  }

  isCacheUpdating = true;

  while (cacheUpdateQueue.length > 0) {
    const { key, data } = cacheUpdateQueue.shift();
    try {
      await saveDataToCache(key, data);
    } catch (error) {
      console.error("Failed to save data to cache:", error);
    }
  }

  isCacheUpdating = false;
}
