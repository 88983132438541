import authReducer from "./reducer";

export function getAuthModule() {
  return {
    id: "auth",
    reducerMap: {
      auth: authReducer,
    },
    sagas: [],
  };
}
