import React from 'react';
import { AnimatedImage, AppBar, Typography } from 'ui';
import s from './ErrorScreen.module.scss';

export const ErrorScreen = () => {
  return (
    <div className={s.root}>
      <AppBar />
      <div className={s.main}>
        <AnimatedImage
          src="https://parkplus-bkt-img.parkplus.io/production/vehicle/public/car_20230829194736577083.png"
          className={s.image}
        />
        <Typography label="Uh-Oh" variant="h1" style={{ paddingTop: '24px' }} />
        <Typography
          label={`We are unable to fetch details at the moment. \nPlease try again later`}
          variant="body_para_r"
          color="text_subheading"
          style={{ paddingTop: '4px', whiteSpace: 'break-spaces' }}
        />
      </div>
    </div>
  );
};

