import cn from "classnames";
import React from "react";
import s from "./Spinner.module.scss";

export type VariantType = "default" | "dark" | "light" | "blue";
export type SpinnerSize =
  | "xxxs"
  | "xxs"
  | "xs"
  | "s"
  | "m"
  | "l"
  | "xl"
  | "xxl";

export function Spinner({
  size = "m",
  variant = "default",
}: {
  size?: SpinnerSize;
  variant?: VariantType;
}) {
  const color = React.useMemo(() => {
    if (variant === "dark") return "#000";
    if (variant === "light") return "#fff";
    if (variant === "blue") return "#0012ff69";
    return "#fff";
  }, [variant]);

  return (
    <svg
      className={cn(s.loader, {
        [s.xxxs]: size === "xxxs",
        [s.xxs]: size === "xxs",
        [s.xs]: size === "xs",
        [s.s]: size === "s",
        [s.m]: size === "m",
        [s.l]: size === "l",
        [s.xl]: size === "xl",
        [s.xxl]: size === "xxl",
      })}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 472.615 472.615"
    >
      <path
        fill={color}
        d="M236.308 0c-13.153 0-23.817 10.663-23.817 23.817v82.961c0 13.154 10.663 23.817 23.817 23.817s23.817-10.662 23.817-23.817V23.817C260.125 10.664 249.461 0 236.308 0zM161.558 127.875l-58.662-58.662c-9.301-9.301-24.381-9.301-33.683 0-9.301 9.301-9.301 24.381 0 33.682l58.663 58.663c9.301 9.302 24.381 9.302 33.682 0 9.301-9.302 9.301-24.382 0-33.683zM106.779 212.491H23.817C10.663 212.491 0 223.154 0 236.308s10.663 23.817 23.817 23.817h82.962c13.154 0 23.817-10.663 23.817-23.817-.001-13.154-10.664-23.817-23.817-23.817zM161.558 311.057c-9.301-9.302-24.381-9.302-33.682 0L69.213 369.72c-9.301 9.301-9.301 24.381 0 33.682 9.302 9.301 24.382 9.301 33.683 0l58.662-58.662c9.301-9.301 9.301-24.381 0-33.683zM236.308 342.02c-13.153 0-23.817 10.663-23.817 23.817v82.961c0 13.154 10.663 23.817 23.817 23.817s23.817-10.663 23.817-23.817v-82.961c0-13.153-10.664-23.817-23.817-23.817zM403.402 369.721l-58.662-58.663c-9.302-9.302-24.381-9.302-33.683 0-9.301 9.302-9.301 24.382 0 33.683l58.663 58.662c9.301 9.301 24.381 9.301 33.682 0s9.301-24.382 0-33.682zM448.799 212.491h-82.962c-13.154 0-23.818 10.662-23.818 23.817 0 13.154 10.663 23.817 23.818 23.817h82.962c13.154 0 23.817-10.663 23.817-23.817-.001-13.154-10.664-23.817-23.817-23.817zM403.402 69.213c-9.301-9.301-24.381-9.301-33.682 0l-58.663 58.662c-9.301 9.301-9.301 24.381 0 33.683 9.302 9.302 24.381 9.302 33.683 0l58.662-58.663c9.301-9.301 9.301-24.381 0-33.682z"
      />
    </svg>
  );
}
