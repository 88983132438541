import { isDataValid } from "core-lib/utils/helpers";
export function isAlphabetsWithSpace(str) {
  let isValid = false;
  if (isDataValid(str)) {
    isValid = /^[a-zA-Z ]*$/i.test(str);
  }
  return isValid;
}

export function isNumeric(str) {
  let isValid = false;
  if (isDataValid(str)) {
    isValid = /^[0-9]*$/i.test(str);
  }
  return isValid;
}


export function isAlphanumeric(str) {
  let isValid = false;
  if (isDataValid(str)) {
    isValid = /^[a-zA-Z0-9]*$/i.test(str);
  }
  return isValid;
}

export function isAlphanumericWithSpace(str) {
  let isValid = false;
  if (isDataValid(str)) {
    isValid = /^[a-zA-Z0-9 ]*$/i.test(str);
  }
  return isValid;
}
export function isURL(str) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(str);
}
