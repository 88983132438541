import { API_STATUS } from "core-lib/constants/network";

export const BookingTabs = {
  0: "BOOKING_CONFIRMED",
  1: "TEST_DRIVE_TAKEN",
  2: "BILL_UPLOADED",
};

export const TABS = ["Booking confirmed", "Test drive taken", "Bill uploaded"];

export const HOW_IT_WORKS = [
  {
    icon: "https://parkplus-bkt-img.parkplus.io/production/vehicle/public/cards_20230828110136792665.png",
    title: " worth of free IndianOil Fuel",
    subtitle: "Enjoy free fuel for next 6 months, upto 10 litres per month",
    amount: "₹6000",
  },
  {
    icon: "https://parkplus-bkt-img.parkplus.io/production/vehicle/public/parking_20230828110204027601.png",
    title: " worth of free car parking",
    subtitle:
      "Enjoy a year of complementary car parking, upto Rs. 500 per month",
    amount: "₹6000",
  },
  {
    icon: "https://parkplus-bkt-img.parkplus.io/production/vehicle/public/fastag_20230828110228044758.png",
    title: " worth of FASTag recharge",
    subtitle: "12 months of toll travel with Rs. 250 monthly FASTag recharges.",
    amount: "₹3000",
  },
  {
    icon: "https://parkplus-bkt-img.parkplus.io/production/vehicle/public/shop_20230828110248750037.png",
    title: " worth of car accessories",
    subtitle: "An exclusively curated hamper of car accessories.",
    amount: "₹5000",
  },
];

export const TermsAndConditions = [
  "1. The car purchase invoice should be genuine.",
  "2. Only cars bought through the Park+ dealer fulfilling the test drive would be eligible for the offer.",
  "3. 10 Litres of petrol would be credited each month.",
];

export const POINTS = [
  "1. Take your test drive",
  "2. Upload your car purchase bill and get your 1st EMI Free",
];

export const initState = {
  name: {
    input: "",
    err: "",
  },
  email: {
    input: "",
    err: "",
  },
  phone: {
    input: "",
    err: "",
  },
  city: {
    input: "",
    err: "",
  },
  pincode: {
    input: "",
    err: "",
  },
  dealer_name: {
    input: "",
    err: "",
  },
};

export const OlaInitState = {
  name: {
    input: "",
    err: "",
  },
  phone: {
    input: "",
    err: "",
  },
  city: {
    input: "",
    err: "",
  },
  pincode: {
    input: "",
    err: "",
  },
};

export const ROUTES = {
  myBookings: "/services/test-drive/my-bookings",
};

export enum ViewType {
  Vertical = "vertical",
  Horizontal = "horizontal",
}

export enum Actions {
  Yes = "yes",
  No = "no",
  Upload = "upload",
}

export const CAR_IMAGE =
  "https://parkplus-bkt-img.parkplus.io/production/fuel-bill/public/Banner013_20230727191745795308.png";

export const Reasons = [
  {
    value: "MISMATCH",
    label: "Schedule/availability mismatches",
    showInput: false,
  },
  {
    value: "NOT_INTERESTED",
    label: "Not interested anymore",
    showInput: false,
  },
  {
    value: "SCHEDULE_LATER",
    label: "Scheduled for later",
    showInput: true,
  },
];

export enum LeadType {
  Oem = "OEM",
  Dealer = "DEALER",
}

export const BUDGET_CAR_FILTERS = [
  {
    title: "5-10 Lakhs",
    filters: {
      price: {
        gte: 500000,
        lte: 1000000,
      },
      page: 1,
    },
  },
  {
    title: "10-15 Lakhs",
    filters: {
      price: {
        gte: 1000000,
        lte: 1500000,
      },
      page: 1,
    },
  },
  {
    title: "15-20 Lakhs",
    filters: {
      price: {
        gte: 1500000,
        lte: 2000000,
      },
      page: 1,
    },
  },
  {
    title: ">20 Lakhs",
    filters: {
      price: {
        gte: 2000000,
      },
      page: 1,
    },
  },
];

export const BODY_TYPE_CARS = [
  {
    title: "Sedan",
    filters: {
      body: ["sedan"],
      page: 1,
    },
  },
  {
    title: "Hatchback",
    filters: {
      body: ["hatchback"],
      page: 1,
    },
  },
  {
    title: "SUV",
    filters: {
      body: ["suv"],
      page: 1,
    },
  },
];

export const initDataState = {
  BudgetCars: {
    0: {
      apiStatus: API_STATUS.default,
      list: [],
    },
    1: {
      apiStatus: API_STATUS.default,
      list: [],
    },
    2: {
      apiStatus: API_STATUS.default,
      list: [],
    },
    3: {
      apiStatus: API_STATUS.default,
      list: [],
    },
  },
  BodyTypeCars: {
    0: {
      apiStatus: API_STATUS.default,
      list: [],
    },
    1: {
      apiStatus: API_STATUS.default,
      list: [],
    },
    2: {
      apiStatus: API_STATUS.default,
      list: [],
    },
  },
};

export const ListingChips = ["Body type", "Budget", "Brand"];

export const BODY_TYPE_FILTERS = [
  {
    label: "Sedan",
    value: "sedan",
  },
  {
    label: "Hatchback",
    value: "hatchback",
  },
  {
    label: "MUV",
    value: "muv",
  },
  {
    label: "SUV",
    value: "suv",
  },
  {
    label: "Minivan",
    value: "minivan",
  },
  {
    label: "Coupe",
    value: "coupe",
  },
];

export const BUDGET_FILTERS = [
  {
    label: "Under 5 lakh",
    value: {
      lte: 500000,
    },
  },
  {
    label: "5 - 10 lakhs",
    value: {
      gte: 500000,
      lte: 1000000,
    },
  },
  {
    label: "10 - 15 lakhs",
    value: {
      gte: 1000000,
      lte: 1500000,
    },
  },
  {
    label: "15 - 20 lakhs",
    value: {
      gte: 1500000,
      lte: 2000000,
    },
  },
  {
    label: "20 - 30 lakhs",
    value: {
      gte: 2000000,
      lte: 3000000,
    },
  },
  {
    label: "Above 30 lakhs",
    value: {
      gte: 3000000,
    },
  },
];

export const PROMOTIONAL_BANNERS = [
  {
    redirectionUrl: "/services/bill-payment/bill-categories",
    image:
      "https://parkplus-bkt-img.parkplus.io/production/vehicle/public/bill_20230929164651800062.png",
  },
  {
    redirectionUrl: "/shop/vouchers",
    image:
      "https://parkplus-bkt-img.parkplus.io/production/vehicle/public/vouchers_20230929164841456807.png",
  },
];

export const MIN_REQUIRED_FIELD = {
  oem: 2,
  dealer: 1,
  oemWithAddress: 2,
  dealerWithAddress: 1,
};

export enum SocietyModels {
  Tiago = "tiago",
  Comet = "comet",
}

export const SocietyUsersModels = {
  [SocietyModels.Tiago]: {
    image:
      "https://parkplus-bkt-img.parkplus.io/production/vehicle/public/tiago_20231030134309525310.png?tr=q-1",
    logo: "https://parkplus-bkt-img.parkplus.io/production/vehicle/public/tata_20231031113443630394.png",
    title: "TATA TIAGO EV",
    specs: [
      "Battery : 24kWh",
      "315 Km single charge",
      "2 Airbags",
      "5.1 M turning radius",
    ],
  },
  [SocietyModels.Comet]: {
    image:
      "https://parkplus-bkt-img.parkplus.io/production/vehicle/public/comet_20231030133928943361.png?tr=q-1",
    logo: "https://parkplus-bkt-img.parkplus.io/production/vehicle/public/mg_20231030134032358349.png",
    title: "MG COMET EV",
    specs: [
      "230 KM Single charge",
      "4.2 M turning radius",
      "4 seater",
      "17.3 kWh battery",
    ],
  },
};

export enum PanoTabs {
  Exterior = "EXTERIOR",
  Interior = "INTERIOR",
}

export const TelePhonePayloadTypes = {
  sameModel: "SAME_MODEL",
  bodyType: "SIMILAR_BODY_TYPE",
  price: "SIMILAR_PRICE",
};

export enum TestDriveVersion {
  v1 = "v1",
  v2 = "v2",
}

export enum CarReviewStep {
  Zero = 0,
  First = 1,
  Second = 2,
  Third = 3,
}

export enum LeadGenerationSource {
  Website = "PARKPLUS_WEBSITE",
}

export const DEALER_TEXT = [
  "Find Park+ authorised dealerships near you",
  "Claim exclusive deals and offers",
  "Beat the queues, book instant test drives",
];

export enum ContentTypes {
  Banner = "BANNER",
  Video = "VIDEO",
}
