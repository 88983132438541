import { CALL_EVENT, OPEN_IN_BROWSER } from "core-lib/constants/nativeBridge";
import { PLATFORM } from "core-lib/data/common";
import { passMessageToNative as passMessageToNativeNew } from "core-lib/utils/nativeBridge";
import { getWebViewPlatform, isParkPlusWebView } from "core-lib/utils/source";
import dayjs from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";

import {isEmptyNullUndefined, isServer} from "./utils";
import { requiredFieldValidation } from "./validation";

dayjs.extend(AdvancedFormat);
export const isDataValid = (data) => {
  let isValid = false;
  if (data !== null && data !== undefined) isValid = true;
  return isValid;
};

export const isNumber = (data) => {
  let isValid = false;
  if (isDataValid(data)) {
    isValid = !isNaN(data);
  }
  return isValid;
};

export function isObject(data) {
  let isValid = false;
  if (isDataValid(data)) {
    if (typeof data === "object") {
      isValid = true;
    }
  }
  return isValid;
}

export const getRandomNum = () => Math.ceil(Math.random() * 100000);

export const convertToFixedDecimal = (
  value = 0,
  points = 0,
  convertToNumber = true
) => {
  const convertedArr = value?.toString()?.split(".");
  let str = convertedArr?.[0];
  if (convertedArr?.length > 1 && points > 0) {
    str += `.${convertedArr[1].slice(0, points + 1)}`;
  }
  if (convertToNumber) {
    return Number(str);
  }
  return Number(str).toString();
};

export const formatDate = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join("-");
};

export const getOsVersionFromNavigator = () => {
  let os = "unknown";

  if (!isServer()) {
    const { userAgent } = global.window.navigator;
    if (/windows phone/i.test(userAgent)) {
      os = PLATFORM.windowsPhone;
    } else if (/android/i.test(userAgent)) {
      os = PLATFORM.android;
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      os = PLATFORM.ios;
    }
  }
  return os;
};

export const checkMobileOS = () => {
  if (isParkPlusWebView()) {
    const webViewPlatform = getWebViewPlatform()
    if (!isEmptyNullUndefined(webViewPlatform)) {
      return webViewPlatform;
    }
  }
  return getOsVersionFromNavigator();
};

export const isWebView = () => {
  if (isParkPlusWebView()) {
    return true;
  }
  if (!isServer()) {
    const os = checkMobileOS();
    const { userAgent } = global.navigator;
    if (os === PLATFORM.ios) {
      return /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(userAgent);
    } else if (os === PLATFORM.android) {
      return  /.+Android.+AppleWebKit\/.+Version\/.+/.test(userAgent);
    }
  }
};

export const checkSource = () => {
  return isWebView() ? checkMobileOS() : "web";
};

export const getPlatform = () => {
  let platform = PLATFORM.pwa;
  if (isParkPlusWebView()) {
    platform = checkMobileOS();
  }
  platform
  return platform;
};

export const passMessageToNative = (message, title = null, content = null) => {
  passMessageToNativeNew(message, title, content);
};

export const getFullName = (fname, lname) => {
  let name = "-";
  if (requiredFieldValidation(fname).isValid) {
    name = fname;
    if (requiredFieldValidation(lname).isValid) {
      name += ` ${lname}`;
    }
  } else if (requiredFieldValidation(lname).isValid) {
    name += `${lname}`;
  }
  return name;
};

export const getVehicleImage = (color, wheel, type = "default") => {
  let image = "/app/img/vehicle";
  if (wheel === 2) {
    image += "/bike";
  } else if (wheel === 4) {
    image += "/car";
  } else {
    image += "/car";
  }

  switch (isDataValid(color) ? color.toLowerCase() : null) {
    case "black":
      image += "-black";
      break;
    case "blue":
      image += "-blue";
      break;
    case "brown":
      image += "-brown";
      break;
    case "red":
      image += "-red";
      break;
    case "grey":
      image += "-grey";
      break;
    case "white":
      image += "-white";
      break;
    default:
      image += "-blue";
  }

  if (type === "plain") {
    image += "-plain";
  }

  image += ".svg";

  return image;
};

export const getProfilePic = (gender) => {
  let image;
  switch (gender) {
    case 1:
      image = "/app/img/icons/new-guest-user-male.svg";
      break;
    case "male":
      image = "/app/img/icons/new-guest-user-male.svg";
      break;
    case 2:
      image = "/app/img/icons/new-guest-user-female.svg";
      break;
    case "female":
      image = "/app/img/icons/new-guest-user-female.svg";
      break;
    default:
      image = "/app/img/icons/guest-user.svg";
  }

  return image;
};

export function getUserProfilePic(profile) {
  let profileImg = "/app/img/icons/guest-user.svg";
  if (isDataValid(profile)) {
    profileImg = getProfilePic(profile.gender);
    if (isDataValid(profile.photo) && profile.photo !== "") {
      if (profile.photo.image) profileImg = profile.photo.image;
    }
  }
  return profileImg;
}

export function removeBadKeysFromObject(obj) {
  if (isObject(obj)) {
    const newObj = { ...obj };
    const keyArr = Object.keys(obj);
    for (let iter = 0; iter < keyArr.length; iter += 1) {
      const item = keyArr[iter];
      if (!isDataValid(newObj[item])) {
        delete newObj[item];
      }
    }
    return newObj;
  }
  return obj;
}

export function phoneCallHandler(phoneNumber) {
  if (isParkPlusWebView()) {
    passMessageToNative(CALL_EVENT, null, phoneNumber);
  } else {
    global.location.href = `tel:${phoneNumber}`;
  }
}

export function linkHandler(url) {
  if (isParkPlusWebView()) {
    passMessageToNative(OPEN_IN_BROWSER, url, null);
  } else {
    global.window.open(url, "_blank").focus();
  }
}

export function debounce(callback, timeout = 300) {
  if (window) {
    // @ts-ignore
    if (window?.debounceTimer) {
      // @ts-ignore
      clearTimeout(window?.debounceTimer);
    }
    // @ts-ignore
    window.debounceTimer = setTimeout(() => {
      callback();
    }, timeout);
  }
}

export const checkIfEmailValid = (email) => {
  let isValid = false;
  if (isDataValid(email)) {
    isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }
  return isValid;
};

export function getGeoLocationPromise(isStart = false) {
  const geoPromise = new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    if (navigator.geolocation) {
      // eslint-disable-next-line no-undef
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            status: "success",
            coords: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            reject({
              status: "denied",
              coords: null,
            });
            if (isStart) {
            }
          }
        },
        {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 10000,
        }
      );
    } else {
      reject({
        status: "denied",
        coords: null,
      });
    }
  });

  return geoPromise;
}
