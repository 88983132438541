import { ColorType } from "./Typings";

export type StepType = {
  key: number | string;
  label?: string;
  description?: string;
};

export type ProgressIndicatorType = {
  noOfSteps?: number;
  currentStep?: number;
  variant: IndicatorVariantsType;
  steps?: StepType[];
  horizontalMargin?: number;
  color?: ColorType;
  textColor?: ColorType;
  backgroundColor?: ColorType;
  size?: number;
  width?: number;
  progress?: number;
  showStep?: boolean;
};

export enum IndicatorVariants {
  linear = "linear",
  linearWithSteps = "linearWithSteps",
  tabbed = "tabbed",
  progressDots = "progressDots",
  linearWithIndex = "linearWithIndex",
  circular = "circular",
  vertical = "vertical",
}

export type IndicatorVariantsType = keyof typeof IndicatorVariants;

export type IndicatorType = {
  noOfSteps: number;
  currentStep: number;
  steps?: StepType[];
  horizontalMargin?: number;
  color?: ColorType;
  backgroundColor?: ColorType;
  size?: number;
  width?: number;
  textColor?: ColorType;
  showStep?: boolean;
};

export type BreadCrumb = {
  name: string;
  url: string;
  type: "internal" | "external";
  id: number;
};
