import classNames from "classnames";
import s from "./Divider.module.scss";

type DividerProps = {
  className?: string;
  variant?:
    | "empty"
    | "padded"
    | "full"
    | "dotted"
    | "with_margin"
    | "divider_type"
    | "dashed";
  style?: React.CSSProperties;
};

export const Divider = ({
  variant = "full",
  className,
  style = {},
}: DividerProps) => (
  <div
    className={classNames({ [s[variant]]: true, [className]: !!className })}
    style={style}
  />
);
