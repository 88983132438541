import React from "react";
import cn from "classnames";
import ReactDOM from "react-dom";
import { isNumeric } from "core-lib/utils/regex";
import useTimer from "core-lib/hooks/useTimer";
import s from "./CountDown.module.scss";
import CircularProgressBar from "../CircularProgressBar";
import MaterialSpinner from "../MaterialSpinner";
import Typography from "../Typography";
import Button from "../Button";

function getReadText(value: number): string {
  let text = "00:00";
  if (isNumeric(value) && value > 0) {
    const mm = Math.floor(value / 60);
    const ss = Math.floor(value % 60);
    text = `${`0${mm}`.slice(-2)}:${`0${ss}`.slice(-2)}`;
  }
  return text;
}

export function CountDown({
                            variant = "default",
                            bottomAction = {
                              show: false,
                              label: "Cancel",
                              onClick: () => {
                              },
                              showAfter: 0,
                              isLoading: false
                            },
                            showTimer = true,
                            duration = 90,
                            title = "We are processing your request",
                            subtitle = "Please wait while we confirm your request",
                            onExpire
                          }: {
  variant?: "default" | "spinner";
  bottomAction?: {
    show: boolean;
    label: string;
    onClick: () => void;
    showAfter: number;
    isLoading: boolean;
  };
  showTimer?: boolean;
  duration: number;
  title?: string;
  subtitle?: string;
  onExpire?: () => void;
}) {
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + duration);
  const { seconds, minutes, finish } = useTimer({ expiryTimestamp, onExpire });
  const [showBottomAction, setShowBottomAction] = React.useState(false);

  React.useEffect(() => () => finish(), []);

  React.useEffect(() => {
    if (bottomAction.show && !showBottomAction) {
      if (duration - (minutes * 60 + seconds) >= bottomAction.showAfter) {
        setShowBottomAction(true);
      }
    }
  }, [seconds, minutes]);

  const appRoot = global.document.getElementsByTagName("BODY")[0];
  return ReactDOM.createPortal(
    showTimer && (
      <div className={cn("cmn_fullscreen_fixed", s.root)}>
        <div className={s.top}>
          {variant === "default" && (
            <CircularProgressBar
              text={getReadText(minutes * 60 + seconds)}
              value={minutes * 60 + seconds}
              min={0}
              max={duration}
              size={138}
              fontSize="32px"
              foregroundColor="var(--interaction_buttons)"
              backgroundColor="var(--background)"
            />
          )}
          {variant === "spinner" && <MaterialSpinner width={100} height={100} />}
          <div style={{ height: 32 }} />
          <Typography label={title} variant="h3" />
          <div style={{ height: 12 }} />
          <Typography
            label={subtitle}
            variant="body_m"
            color="grey_60"
            style={{ whiteSpace: "pre-wrap" }}
          />
        </div>
        {showBottomAction && (
          <div className={s.bottom}>
            <Button
              isDisabled={bottomAction.isLoading}
              isLoading={bottomAction.isLoading}
              size="Small"
              label={bottomAction.label}
              variant="PrimaryNaked"
              onClick={bottomAction.onClick}
            />
          </div>
        )}
      </div>
    ),
    appRoot
  );
}
