import { Environments } from "core-lib/config/Env";

export const getOriginByEnv = () => {
  let origin;
  if (process.env.APP_ENV === Environments.Staging) {
    origin = `https://mobileweb-stg.parkplus.io`;
  } else if (process.env.APP_ENV === Environments.Production) {
    origin = `https://parkplus.io`;
  } else {
    origin = `https://test.parkwheels.co.in:3002`;
  }
  return origin;
};

export const DEVICE_BREAK_POINT = {
  desktop: 1200,
  tablet: 991,
  phone: 767,
  smallPhone: 550,
};
