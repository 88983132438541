import cn from "classnames";
import React, { useEffect, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import { Transition, Typography } from "ui";
import { onModalVisibilityChange } from "ui/components/Modal/Modal.utils";
import Cross from "ui/components/newIcons/Cross";
import Fab from "ui/components/newIcons/Fab";
import { getRandomNum } from "../../utils";
import {
  AnimationTypes,
  TimeoutMapping
} from "../Transitions/Transition/Transition";
import s from "./FabModal.module.scss";

type ModalTypes = {
  fabPosition?: "left" | "center" | "right";
  children: any;
  closeOnClickOutside?: boolean;
  blurredBg?: boolean;
  show?: boolean;
  animation?: AnimationTypes;
  customeMainStyle?: any;
  rootStyle?: any;
  refValue?: number;
  onClose?: () => void;
  onOpen?: () => void;
  title?: string;
  iconStyle?:React.CSSProperties;
  leftIcon?: any;
  titleStyle?: React.CSSProperties;
  shouldCollapse?: boolean;
};

export const FabModal = ({
  refValue = getRandomNum(),
  children,
  closeOnClickOutside = true,
  blurredBg = false,
  show = false,
  animation,
  customeMainStyle = {},
  rootStyle,
  fabPosition = "right",
  onClose = () => {},
  onOpen = () => {},
  title,
  iconStyle={},
  leftIcon=null,
  titleStyle={},
  shouldCollapse = true
}: ModalTypes) => {
  const [showContent, setShowContent] = React.useState(false);
  const compRef = React.useRef(refValue);
  const isShownOnce = React.useRef(false);
  const titleRef = React.useRef(null);
  const isExpanded = useRef(false);

  function collapseSection(element) {
    // get the height of the element's inner content, regardless of its actual size
    let sectionWidth = element.scrollWidth;

    // temporarily disable all css transitions
    let elementTransition = element.style.transition;
    element.style.transition = "";

    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function () {
      element.style.width = sectionWidth + "px";
      element.style.transition = elementTransition;

      // on the next frame (as soon as the previous style change has taken effect),
      // have the element transition to height: 0
      requestAnimationFrame(function () {
        element.style.width = 0 + "px";
      });
    });
  }

  function expandSection(element) {
    let sectionWidth = element.scrollWidth;
    element.style.width = sectionWidth + "px";
  }

  const bodyAnim = useMemo(() => {
    let bodyAnim: AnimationTypes;

    if (animation) {
      bodyAnim = animation;
    } else {
      bodyAnim = {
        type: "fade",
        timeout: animation?.timeout ?? "m",
      };
    }
    return bodyAnim;
  }, [animation]);

  React.useEffect(() => {
    if (show) {
      if (!isShownOnce.current) isShownOnce.current = true;
      if (bodyAnim) {
        const timeout = setTimeout(() => {
          setShowContent(true);
          clearTimeout(timeout);
        }, 50);
      } else setShowContent(true);
    } else if (isShownOnce.current) {
      if (!bodyAnim) {
        const timeout = setTimeout(() => {
          setShowContent(false);
          clearTimeout(timeout);
        }, TimeoutMapping[animation?.timeout ?? "m"]);
      } else {
        setShowContent(false);
      }
    }

    if (isShownOnce.current) onModalVisibilityChange(show, compRef.current);

    return () => {
      onModalVisibilityChange(false, compRef.current);
    };
  }, [show]);

  const toggleDescription = () => {
    let section = titleRef.current;

    if (section) {
      if (!isExpanded.current) {
        expandSection(section);
        isExpanded.current = true;
      } else if(shouldCollapse) {
        collapseSection(section);
        isExpanded.current = false;
      }
    }
  };

  useEffect(() => {
    let timeout = null;
    if (title) {
      toggleDescription();
      timeout = setTimeout(() => {
        toggleDescription();
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const appRoot = global.document.getElementsByTagName("BODY")[0];

  if (show)
    return ReactDOM.createPortal(
      <Transition
        show={show}
        animation={{
          type: "fade",
        }}
      >
        <div
          id={`modalId-${compRef.current}`}
          className={cn(s.root, {
            [s.blurredBg]: blurredBg,
          })}
          onClick={(e) => {
            if (closeOnClickOutside) {
              onClose();
            } else {
              e.stopPropagation();
            }
          }}
          style={rootStyle}
        >
          <Transition show={showContent} animation={bodyAnim}>
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={cn(
                s.main,
                {
                  [s.left]: fabPosition === "left",
                },
                {
                  [s.center]: fabPosition === "center",
                },
                {
                  [s.right]: fabPosition === "right",
                }
              )}
              style={customeMainStyle}
            >
              {children}
            </div>
          </Transition>

          <div
            onClick={() => {
              onClose();
            }}
            className={cn(
              s.cross,
              {
                [s.left]: fabPosition === "left",
              },
              {
                [s.center]: fabPosition === "center",
              },
              {
                [s.right]: fabPosition === "right",
              }
            )}
          >
            <Cross />
          </div>
        </div>
      </Transition>,
      appRoot
    );

  return (
    <div
      onClick={() => {
        onOpen();
      }}
      className={cn(
        s.fab_wrapper,
        {
          [s.left]: fabPosition === "left",
        },
        {
          [s.center]: fabPosition === "center",
        },
        {
          [s.right]: fabPosition === "right",
        }
      )}
      style={iconStyle}
    >
      {
        leftIcon && <>{leftIcon}</>
      }
      {title && ["right", "center"].includes(fabPosition) && (
        <div ref={titleRef} className={s.inner_title} style={titleStyle}>
          <Typography variant="body_big_m" className={s.title} label={title} color="white" />
        </div>
      )}

      <div className={s.fab_icon}>
        <Fab primaryColor="white" />
      </div>

      {title && ["left"].includes(fabPosition) && (
        <div ref={titleRef} className={s.inner_title} style={titleStyle}>
          <Typography variant="body_big_m" className={s.title} label={title} color="white" />
        </div>
      )}
    </div>
  );
};
