import { GENERIC_ERROR_EVENT } from 'core-lib/constants/common';
import React, { useEffect, useState } from 'react';
import useCustomToast from 'ui/hooks/useCustomToast';
import { ErrorObjectType, ErrorUiTypeEnum } from 'ui/primitives';
import ErrorBottomSheet from './ErrorBottomSheet';
import ErrorPopup from './ErrorPopup';

type GenericErrorScreensProps = {
  errObj: ErrorObjectType;
  onClose: () => void;
};

export const GenericErrorScreens: React.FunctionComponent<
  GenericErrorScreensProps
> = ({ errObj, onClose }) => {
  const [showErrorComp, setShowErrorComp] = useState(true);
  const { showErrToast } = useCustomToast();

  const handleClose = () => {
    onClose();
    setShowErrorComp(false);
  };

  useEffect(() => {
    return () => {
      onClose && onClose()
      window.removeEventListener(GENERIC_ERROR_EVENT, () => {});
    };
  }, []);

  if (
    [
      ErrorUiTypeEnum.toast_err,
      ErrorUiTypeEnum.toast_info,
      ErrorUiTypeEnum.toast_success,
      ErrorUiTypeEnum.toast_warn,
    ].includes(errObj?.ui_type as ErrorUiTypeEnum)
  ) {
    showErrToast({ errObj });
    onClose && onClose()
    return null;
  }

  return (
    <div>
      {
        {
          [ErrorUiTypeEnum.bottom_sheet_err]: (
            <ErrorBottomSheet
              show={showErrorComp}
              onClose={handleClose}
              errObj={errObj}
            />
          ),
          [ErrorUiTypeEnum.bottom_sheet_warn]: (
            <ErrorBottomSheet
              show={showErrorComp}
              onClose={handleClose}
              errObj={errObj}
            />
          ),
          [ErrorUiTypeEnum.dialog_err]: (
            <ErrorPopup
              show={showErrorComp}
              onClose={handleClose}
              errObj={errObj}
            />
          ),
          [ErrorUiTypeEnum.dialog_warn]: (
            <ErrorPopup
              show={showErrorComp}
              onClose={handleClose}
              errObj={errObj}
            />
          ),
        }[errObj?.ui_type]
      }
    </div>
  );
};
