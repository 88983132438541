/** @format */

import cn from "classnames";
import OtpInput from "react-otp-input";
import s from "./OTPInput.module.scss";

export const OTPInput = ({
  label,
  onChange,
  hasError = false,
  inputStyle = null,
  errorText = "Invalid",
  readOnly = false,
  numInputs = 4,
  value = "",
  customInputClass = "",
  placeholder = "",
}: {
  label?: string;
  onChange: (value) => void;
  value: string;
  hasError?: boolean;
  errorText?: string;
  readOnly?: boolean;
  numInputs?: number;
  inputStyle?: "dark" | "fillInBlanks";
  customInputClass?: string;
  placeholder?: string;
}) => {
  let inputStyleVariant;
  switch (inputStyle) {
    case "dark":
      inputStyleVariant = s.dark_input;
      break;
    case "fillInBlanks":
      inputStyleVariant = s.fillInBlanks_input;
      break;
    default:
      inputStyleVariant = s.part_input;
      break;
  }
  return (
    <div
      className={cn(s.otp_input, {
        [s.otp_input_err]: hasError,
      })}
    >
      {label !== "" && <div className={s.cmn_input_label}>{label}</div>}
      <OtpInput
        onChange={onChange}
        numInputs={numInputs}
        renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
        inputStyle={inputStyleVariant}
        renderInput={(props) => (
          <input
            {...props}
            disabled={readOnly}
            className={cn(inputStyleVariant, s.part_input_active, {
              [customInputClass]: !!customInputClass,
            })}
          />
        )}
        inputType="number"
        value={value}
        containerStyle={s.part_container}
        placeholder={placeholder}
      />
      {hasError && <div className={s.cmn_input_error}>{errorText}</div>}
    </div>
  );
};
