import dynamic from "next/dynamic";

const Confetti = dynamic(() => import("react-confetti"), {
  ssr: false,
});

function CelebrationOverlay({
  duration,
  runInfinitely,
  width,
  height,
}: {
  duration: number;
  runInfinitely: boolean;
  width?: number;
  height?: number;
}) {
  return (
    <Confetti
      width={width ?? global.innerWidth}
      height={height ?? global.innerHeight}
      tweenDuration={duration}
      recycle={runInfinitely}
    />
  );
}

export default CelebrationOverlay;
