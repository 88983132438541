import { isDataValid } from "./helpers";

export default function errorHandler(data) {
  const toast = {
    show: false,
    type: null,
    content: {
      title: "",
      description: "",
    },
  };

  if (isDataValid(data)) {
    if (data.status >= 200 && data.status < 300) {
      toast.show = true;
      toast.type = "success";
      toast.content.title = "Success";
      toast.content.description = data.data.message;
    } else if (data.status >= 400 && data.status < 500) {
      toast.show = true;
      toast.type = "notification";
      toast.content.title = "Alert!";
      toast.content.description = data.data.message;
    } else if (data.status >= 500) {
      toast.show = true;
      toast.type = "error";
      toast.content.title = "Error!";
      toast.content.description = data.data.message;
    } else {
      toast.show = true;
      toast.type = "notification";
      toast.content.title = "Alert!";
      toast.content.description = data.data.message;
    }
  }

  return toast;
}
