import testDriveReducer from './reducer';

export function getTestDriveModule() {
  return {
    // Unique id of the module
    id: 'testDrive',
    // Maps the Store key to the reducer
    reducerMap: {
      testDrive: testDriveReducer,
    },
    // This module uses redux-saga middleware
    // This property will be be used by the SagaExtension
    // to run sagas for the moduleD
    sagas: [],
  };
}
