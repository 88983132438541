import { CSSTransition } from "react-transition-group";
import stl from "./Transition.module.scss";

export enum TimeoutMapping {
  s = 200,
  m = 300,
  l = 600,
}

export type AnimationTypes = {
  type?:
    | "btt"
    | "bttShort"
    | "ttb"
    | "rtl"
    | "rtlShort"
    | "ltr"
    | "fade"
    | "fadeAfter"
    | "popup";
  timeout?: "s" | "m" | "l";
};

interface TransitionProps {
  show?: boolean;
  children: any;
  animation?: AnimationTypes;
}

export function Transition({
  show = false,
  children,
  animation,
}: TransitionProps) {
  if (!animation) {
    if (!show) return null;
    return children;
  }

  if (!animation) return children;

  return (
    // @ts-ignore
    <CSSTransition
      in={show}
      timeout={TimeoutMapping[animation.timeout ?? "m"]}
      classNames={{
        enter: stl[`${animation.type}-enter`],
        enterActive:
          stl[`${animation.type}-enter-active-${animation.timeout ?? "m"}`],
        enterDone:
          stl[`${animation.type}-enter-done-${animation.timeout ?? "m"}`],
        exit: stl[`${animation.type}-exit`],
        exitActive:
          stl[`${animation.type}-exit-active-${animation.timeout ?? "m"}`],
        exitDone:
          stl[`${animation.type}-exit-done-${animation.timeout ?? "m"}`],
      }}
      unmountOnExit
    >
      {children}
    </CSSTransition>
  );
}
