import toast from 'react-hot-toast';
import Button from 'ui/components/Button';
import { ToastComp } from 'ui/components/GenericErrorScreens/ToastComp/ToastComp';
import Info1 from 'ui/components/newIcons/Info1';
import { Color, ErrorToastProps, ErrorUiTypeEnum } from 'ui/primitives';

export default function useCustomToast() {
  const showErrToast = ({ errObj, cta, isSticky = false }: ErrorToastProps) => {
    const iconColor =
      {
        [ErrorUiTypeEnum.toast_err]: Color.negative_text,
        [ErrorUiTypeEnum.toast_info]: Color.white,
        [ErrorUiTypeEnum.toast_success]: Color.positive_text,
        [ErrorUiTypeEnum.toast_warn]: Color.warning_text,
      }[errObj?.ui_type] ?? Color.white;

    return toast.custom(
      (t) => (
        <ToastComp
          leftIcon={
            !cta && <Info1 height={24} width={24} primaryColor={iconColor} />
          }
          isVisible={t.visible}
          rightIcon={
            cta && (
              <Button
                variant='ButtonGhostLink'
                label={cta?.label}
                textStyle={{ color: 'white' }}
                size='Small'
                onClick={() => {
                  cta?.onClick();
                  toast.remove();
                }}
              />
            )
          }
          label={errObj?.title}
        />
      ),
      {
        duration: isSticky ? Infinity : 3500,
      }
    );
  };
  return { showErrToast };
}
