import React from "react";
import cn from "classnames";
import SortByIcon from "ui/components/newIcons/Sort2";
import CrossIcon from "ui/components/newIcons/Cross";
import Typography from "../Typography";
import s from "./Sortlist.module.scss";

type props = {
  labels: { text: string; value: string }[];
  selectedLabel: string;
  changeSelectedLabel: ({
    text,
    value,
  }: {
    text: string;
    value: string;
  }) => void;
};

export const Sortlist = (props: props) => {
  const { labels, changeSelectedLabel, selectedLabel } = props;
  return (
    <div className={s.sort_options}>
      <div className={s.sort_display}>
        <SortByIcon />
        &nbsp;
        <Typography
          renderAs="SPAN"
          label="Sort by"
          variant="small_sb"
          color="text_subheading"
        />
      </div>

      <div className={s.sort_labels}>
        {labels.map((label) => (
          <span
            className={cn({
              [s.label_item]: true,
              [s.active_label]: selectedLabel === label.value,
            })}
            onClick={() => {
              changeSelectedLabel(label);
            }}
            aria-hidden
          >
            <Typography
              label={label.text}
              variant="small_sb"
              color="text_subheading"
            />
            {selectedLabel === label.value && (
              <span className={s.cross_icon}>
                <CrossIcon />
              </span>
            )}
          </span>
        ))}
      </div>
    </div>
  );
};
