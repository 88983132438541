import { createAction } from 'redux-actions';
import { put, takeEvery, call } from 'redux-saga/effects';

import {
  GET_VEHICLE_BRAND_START,
  GET_VEHICLE_BRAND_REQUEST,
  GET_VEHICLE_BRAND_SUCCESS,
  GET_VEHICLE_BRAND_FAILURE,
  GET_VEHICLE_BRAND_RESET,
  GET_BANK_LIST_START,
  GET_BANK_LIST_REQUEST,
  GET_BANK_LIST_SUCCESS,
  GET_BANK_LIST_FAILURE,
  GET_BANK_LIST_RESET,
  RESET_MISC,
} from './types';
import { getMethod } from 'core-lib/utils/api';
import { apiConfig } from 'core-lib/config/config';

export const getVehicleBrandStart = createAction(GET_VEHICLE_BRAND_START);
export const getVehicleBrandRequest = createAction(GET_VEHICLE_BRAND_REQUEST);
export const getVehicleBrandSuccess = createAction(GET_VEHICLE_BRAND_SUCCESS);
export const getVehicleBrandFailure = createAction(GET_VEHICLE_BRAND_FAILURE);
export const getVehicleBrandReset = createAction(GET_VEHICLE_BRAND_RESET);

export const getFastagBankListStart = createAction(GET_BANK_LIST_START);
export const getFastagBankListRequest = createAction(GET_BANK_LIST_REQUEST);
export const getFastagBankListSuccess = createAction(GET_BANK_LIST_SUCCESS);
export const getFastagBankListFailure = createAction(GET_BANK_LIST_FAILURE);
export const getFastagBankListReset = createAction(GET_BANK_LIST_RESET);

export const resetMisc = createAction(RESET_MISC);

function* getVehicleBrand(reqData) {
  yield put(getVehicleBrandRequest());
  try {
    const url = `${apiConfig(
      'vehicle',
      'v2'
    )}/get-brand-model-list/?wheel_count=${reqData.payload.wheelCount}`;
    const { response, error } = yield call(getMethod, url, reqData.payload);
    if (response) {
      const obj = { ...response.data };
      obj.wheel_count = reqData.payload.wheelCount;
      yield put(getVehicleBrandSuccess(response.data));
    }
    if (error) {
      yield put(getVehicleBrandFailure(error));
    }
  } catch (error) {
    yield put(getVehicleBrandFailure(error));
  }
}

function* getFastagBankList() {
  yield put(getFastagBankListRequest());
  try {
    const url = `${apiConfig('catalog')}/api/v2/operatorList/?vendor_id=8`;
    const { response, error } = yield call(getMethod, url);
    if (response) {
      yield put(getFastagBankListSuccess(response.data));
    }
    if (error) {
      yield put(getFastagBankListFailure(error));
    }
  } catch (error) {
    yield put(getFastagBankListFailure(error));
  }
}

export function* watchMisc() {
  yield takeEvery(GET_VEHICLE_BRAND_START, getVehicleBrand);
  yield takeEvery(GET_BANK_LIST_START, getFastagBankList);
}
