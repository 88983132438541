import AnimatedImage from "ui/components/AnimatedImage";
import BackButton from "ui/components/BackButton";
import Button from "ui/components/Button";
import Modal from "ui/components/Modal";
import Typography from "ui/components/Typography";
import { ErrorFullPagePopupProps } from "ui/primitives";
import s from "./ErrorFullPagePopup.module.scss";

export const ErrorFullPagePopup: React.FunctionComponent<
  ErrorFullPagePopupProps
> = ({ show, onClose, errObj, cta }) => {
  return (
    <Modal
      show={show}
      head={{ show: false, onClose: onClose }}
      rootStyle={{ zIndex: 5 }}
      variant="page"
      animation={{ timeout: "m", type: "fade" }}
    >
      <div className={s.root}>
        <div className={s.back}>
          <BackButton onBack={onClose} type="cross" />
        </div>
        <div className={s.content}>
          <div className={s.icon}>
            <AnimatedImage src="https://parkplus-bkt-img.parkplus.io/production/team/public/FS_20240125195003848660.png" />
          </div>
          <div className={s.text}>
            <Typography label={errObj?.title} variant="h1" renderAs="H1" />
            {errObj?.description && (
              <Typography
                label={errObj?.description}
                variant="body_para_r"
                color="text_subheading"
              />
            )}
          </div>

          <div className={s.btn}>
            {cta ? (
              <Button
                label={cta?.label}
                fullwidth
                onClick={cta?.onClick}
                variant={cta?.variant}
              />
            ) : (
              <Button label="Close" onClick={onClose} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
