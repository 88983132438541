import { createAction } from 'redux-actions';
import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_PROJECT_DETAILS_START,
  GET_PROJECT_DETAILS_REQUEST,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_FAILURE,
  RESET_PROFILE,
} from './types';
import { getMethod } from 'core-lib/utils/api';
import { apiConfig } from 'core-lib/config/config';
import { customToast } from 'core-lib/utils/customToast';

export const getProjectDetailsStart = createAction(GET_PROJECT_DETAILS_START);
export const getProjectDetailsRequest = createAction(
  GET_PROJECT_DETAILS_REQUEST
);
export const getProjectDetailsSuccess = createAction(
  GET_PROJECT_DETAILS_SUCCESS
);
export const getProjectDetailsFailure = createAction(
  GET_PROJECT_DETAILS_FAILURE
);

export const resetProfile = createAction(RESET_PROFILE);

// PROJECT DETAILS
function* projectDetails() {
  yield put(getProjectDetailsRequest());
  try {
    const { response, error, toast } = yield call(
      getMethod,
      `${apiConfig(
        'user',
        'v1'
      )}/user/userUnitDetails/?unique_key=30a78b84-8f30-11ed-beeb-3bbec66cebf5`
    );
    if (response) {
      yield put(getProjectDetailsSuccess(response.data.project_data));
      // yield put(getProjectDetailsSuccess([]));
    }
    if (error) {
      yield put(getProjectDetailsFailure());
      customToast({
        variant: 'criticalPriority',
        label: toast?.content?.title,
      });
    }
    if (!response && !error) {
      yield put(getProjectDetailsSuccess([]));
    }
  } catch (error) {
    yield put(getProjectDetailsFailure());
  }
}

export function* watchProfile() {
  yield takeEvery(GET_PROJECT_DETAILS_START, projectDetails);
}
