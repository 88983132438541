export enum TypeScales {
  "large_title" = "large_title",
  "large_input" = "large_input",
  "h1" = "h1",
  "h2" = "h2",
  "h3" = "h3",
  "h4" = "h4",
  "h5" = "h5",
  "base_big_bold" = "base_big_bold",
  "base_big_m" = "base_big_m",
  "base_big_r" = "base_big_r",
  "body_big_b" = "body_big_b",
  "body_big_m" = "body_big_m",
  "body_big_r" = "body_big_r",
  "body_m" = "body_m",
  "body_b" = "body_b",
  "body_st" = "body_st",
  "body_r" = "body_r",
  "body_r_st" = "body_r_st",
  "body_uppercase" = "body_uppercase",
  "body_para_m" = "body_para_m",
  "body_para_b" = "body_para_b",
  "body_para_st" = "body_para_st",
  "body_para_r" = "body_para_r",
  "body_para_uppercase" = "body_para_uppercase",
  "small_m" = "small_m",
  "small_b" = "small_b",
  "small_st" = "small_st",
  "small_uppercase" = "small_uppercase",
  "small_r" = "small_r",
  "small_para_m" = "small_para_m",
  "small_para_b" = "small_para_b",
  "small_para_st" = "small_para_st",
  "small_para_r" = "small_para_r",
  "small_para_uppercase" = "small_para_uppercase",
  "tiny_m" = "tiny_m",
  "tiny_ul" = "tiny_ul",
  "tiny_uppercase" = "tiny_uppercase",
  "tiny_r" = "tiny_r",
  "Button_Button_Big" = "Button_Button_Big",
  "Button_Button_small" = "Button_Button_small",
  "h2_light" = "h2_light",
  "h3_light" = "h3_light",
  "h4_light" = "h4_light",
  "base_big_sb" = "base_big_sb",
  "body_big_sb" = "body_big_sb",
  "body_sb" = "body_sb",
  "body_ul" = "body_ul",
  "body_para_sb" = "body_para_sb",
  "body_para_ul" = "body_para_ul",
  "small_sb" = "small_sb",
  "small_ul" = "small_ul",
  "small_para_ul" = "small_para_ul",
  "small_para_sb" = "small_para_sb",
  "tiny_st" = "tiny_st",
  "tiny_b" = "tiny_b",
  "tiny_sb" = "tiny_sb",
}

export enum TypographyHtmlTags {
  P = "P",
  H1 = "H1",
  H2 = "H2",
  H3 = "H3",
  H4 = "H4",
  H5 = "H5",
  H6 = "H6",
  SPAN = "SPAN",
  Div = "Div",
}
