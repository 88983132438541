import classNames from "classnames";
import React from "react";

import { Spinner, Typography } from "ui";
import {
  ButtonSizesType,
  ButtonVariants,
  ButtonVariantsType,
  ColorType,
} from "ui/primitives";
import { ButtonSizes } from "ui/primitives/ButtonTypes";

import vibrate from "core-lib/utils/vibration";
import { VariantType } from "../Spinner/Spinner";
import s from "./Button.module.scss";

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  elemId?: string;
  label: string;
  variant?: ButtonVariantsType;
  textColorVal?: ColorType;
  size?: ButtonSizesType;
  leftIcon?: any;
  rightIcon?: any;
  isLoading?: boolean;
  isDisabled?: boolean;
  fullwidth?: boolean;
  minWidth?: string;
  borderRadius?: string;
  style?: React.CSSProperties;
  spinnerVariant?: VariantType;
  customStyle?: any;
  enableVibration?: boolean;
  textStyle?: any;
  type?: "button" | "reset" | "submit";
}

export const Button = (props: ButtonProps) => {
  let {
    elemId,
    label,
    variant = ButtonVariants.Primary,
    size = ButtonSizes.Big,
    leftIcon,
    textColorVal,
    rightIcon,
    isLoading,
    isDisabled,
    onClick,
    style = {},
    fullwidth = false,
    minWidth = "initial",
    borderRadius = "8px",
    spinnerVariant = "light",
    customStyle = {},
    enableVibration = false,
    textStyle = {},
    type = "button",
    ...propsToFwd
  } = props;

  let textColor: ColorType = "grey_white";
  switch (variant) {
    case ButtonVariants.DangerLink:
    case ButtonVariants.DangerNaked:
    case ButtonVariants.DangerOutline:
      textColor = "negative_60";
      break;

    case ButtonVariants.SuccessLink:
    case ButtonVariants.SuccessNaked:
    case ButtonVariants.SuccessOutline:
      textColor = "positive_text";
      break;

    case ButtonVariants.PrimaryLink:
    case ButtonVariants.PrimaryNaked:
    case ButtonVariants.PrimaryOutline:
      textColor = "interaction_buttons";
      spinnerVariant = "dark";
      break;

    case ButtonVariants.BlackLink:
    case ButtonVariants.BlackOutline:
    case ButtonVariants.BlackNaked:
      textColor = "text_default";
      break;

    case ButtonVariants.ButtonPrimary:
    case ButtonVariants.Primary:
      textColor = "background_primary";
      break;

    case ButtonVariants.ButtonSecondary:
    case ButtonVariants.ButtonTertiary:
    case ButtonVariants.ButtonLink:
      textColor = "interaction_button";
      break;

    case ButtonVariants.ButtonGhostDefault:
      textColor = "interaction_button";
      spinnerVariant = "dark";
      break;

    case ButtonVariants.ButtonPositive:
      textColor = "positive_text";
      break;
    case ButtonVariants.ButtonNegative:
      textColor = "negative_text";
      break;
    case ButtonVariants.ButtonGhostLink:
      textColor = "interaction_button";
      spinnerVariant = "dark";
      break;
    default:
      textColor = "grey_white";
  }

  if (textColorVal) {
    textColor = textColorVal;
  }

  if (isDisabled) {
    textColor = "grey_60";
  }

  const handleOnClick = (event: any) => {
    if (enableVibration) {
      vibrate();
    }
    if (onClick) {
      onClick(event);
    }
  };
  return (
    <button
      id={elemId}
      className={classNames(
        s.btn,
        s[variant],
        s[size],
        variant !== ButtonVariants.OctagonalFilled && s.scale
      )}
      style={{
        ...style,
        minWidth: fullwidth ? "100%" : minWidth,
        borderRadius: borderRadius,
        ...customStyle,
      }}
      {...propsToFwd}
      type={type}
      disabled={isDisabled}
      onClick={isLoading || isDisabled ? null : handleOnClick}
    >
      {!isLoading && (
        <>
          {leftIcon && <div className={s.leftIcon}>{leftIcon}</div>}
          {label && (
            <Typography
              label={label}
              variant={
                size === "Big" ? "Button_Button_Big" : "Button_Button_small"
              }
              renderAs="SPAN"
              color={textColor}
              style={textStyle}
            />
          )}
          {rightIcon && <div className={s.rightIcon}>{rightIcon}</div>}
        </>
      )}
      {isLoading && (
        <div className={s.spinner}>
          <Spinner size="xxs" variant={spinnerVariant} />
        </div>
      )}
    </button>
  );
};
