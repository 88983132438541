import cn from "classnames";
import { isServer } from "core-lib/utils/utils";
import React from "react";
import ReactDOM from "react-dom";
import {
  AppBar,
  AppBarPropTypes,
  AppBarTypes,
  BackButton,
  Transition,
} from "ui";
import { getRandomNum } from "../../utils";
import {
  AnimationTypes,
  TimeoutMapping,
} from "../Transitions/Transition/Transition";
import Cross from "../icons/Cross";
import s from "./Modal.module.scss";
import { onModalVisibilityChange } from "./Modal.utils";

export type ModalTypeTypes =
  | "bottomSheet"
  | "fullScreenBottomSheet"
  | "popup"
  | "page"
  | "fullScreenPopup";

export type ModalHeadTypes = {
  show: boolean;
  title?: string;
  subtitle?: string;
  onClose?: () => void;
  appBarData?: AppBarPropTypes;
};

type ModalTypes = {
  head?: ModalHeadTypes;
  variant?: ModalTypeTypes;
  closeOnClickOutside?: boolean;
  blurredBg?: boolean;
  show?: boolean;
  animation?: AnimationTypes;
  noPadding?: boolean;
  customeBodyStyle?: any;
  customeMainStyle?: any;
  rootStyle?: any;
  refValue?: number;
  isWeb?: boolean;
};

export function Modal({
  head = {
    show: true,
  },
  refValue = getRandomNum(),
  variant = "bottomSheet",
  children,
  closeOnClickOutside = true,
  blurredBg = false,
  show = false,
  animation,
  noPadding = false,
  customeBodyStyle = {},
  customeMainStyle = {},
  rootStyle,
  isWeb = false,
}: React.PropsWithChildren<ModalTypes>) {
  if (isServer()) return null;
  const compRef = React.useRef(refValue);
  const isShownOnce = React.useRef(false);
  const [showComp, setShowComp] = React.useState(false);

  const mainAnim: AnimationTypes = React.useMemo(() => {
    let mainAnim: AnimationTypes = animation;
    if (variant !== "page") {
      mainAnim = {
        type: "fade",
      };
    }
    return mainAnim;
  }, [variant]);

  const setType: ModalTypeTypes = React.useMemo(() => {
    let setType: ModalTypeTypes = variant;
    if (!isServer()) {
      if (variant === "bottomSheet" && global.window.innerWidth > 767) {
        setType = "popup";
      }
      if (
        variant === "fullScreenBottomSheet" &&
        global.window.innerWidth > 767
      ) {
        setType = "fullScreenPopup";
      }
    }
    return setType;
  }, [variant]);

  const bodyAnim: AnimationTypes = React.useMemo(() => {
    let bodyAnim: AnimationTypes;
    if (animation) {
      bodyAnim = animation;
    } else {
      switch (setType) {
        case "bottomSheet":
          bodyAnim = {
            type: "btt",
            timeout: animation?.timeout ?? "m",
          };
          break;
        case "fullScreenBottomSheet":
          bodyAnim = {
            type: "btt",
            timeout: animation?.timeout ?? "m",
          };
          break;
        case "popup":
          bodyAnim = {
            type: "popup",
            timeout: animation?.timeout ?? "m",
          };
          break;
        case "fullScreenPopup":
          bodyAnim = {
            type: "popup",
            timeout: animation?.timeout ?? "m",
          };
          break;
        case "page":
          bodyAnim = {
            type: "fade",
            timeout: animation?.timeout ?? "m",
          };
          break;
        default:
      }
    }
    return bodyAnim;
  }, [setType]);

  const [showContent, setShowContent] = React.useState(false);

  React.useEffect(() => {
    setShowComp(show);
    if (show) {
      if (!isShownOnce.current) isShownOnce.current = true;
      if (bodyAnim) {
        const timeout = setTimeout(() => {
          setShowContent(true);
          clearTimeout(timeout);
        }, 50);
      } else setShowContent(true);
    } else if (isShownOnce.current) {
      if (!bodyAnim) {
        const timeout = setTimeout(() => {
          setShowContent(false);
          clearTimeout(timeout);
        }, TimeoutMapping[animation?.timeout ?? "m"]);
      } else {
        setShowContent(false);
      }
    }

    if (isShownOnce.current) onModalVisibilityChange(show, compRef.current);

    return () => {
      onModalVisibilityChange(false, compRef.current);
    };
  }, [show]);

  function close() {
    if (typeof head?.onClose === "function") {
      head?.onClose();
    }
  }

  function _renderAppBar() {
    let appBarProps: AppBarPropTypes = {
      variant: AppBarTypes.Default,
      bgColor: "background_primary",
    };

    appBarProps.withDivider = false;
    appBarProps.backButton = {
      show: false,
    };
    if (variant === "bottomSheet") {
      appBarProps.title = {
        label: head.title,
      };
      appBarProps.subtitle = {
        label: head.subtitle,
      };
      appBarProps.rightActions = [
        {
          type: "back",
          onClick: close,
          icon: <Cross variant="linear" />,
        },
      ];
    }
    if (variant === "fullScreenBottomSheet") {
      appBarProps.rightActions = [
        {
          type: "back",
          onClick: close,
          icon: <Cross />,
        },
      ];
      appBarProps.leftActions = [];
      appBarProps.title = {
        label: head.title,
      };
      appBarProps.subtitle = {
        label: head.subtitle,
      };
    }
    if (variant === "bottomSheet") {
      appBarProps.hasPaddingTop = false;
    }
    if (variant === "page") {
      appBarProps.rightActions = [];
      appBarProps.leftActions = [];
      appBarProps.title = {
        label: head.title,
      };
      appBarProps.subtitle = {
        label: head.subtitle,
      };
      appBarProps.withDivider = true;
      appBarProps.backButton = {
        show: true,
        onClick: close,
      };
    }

    if (head.appBarData) appBarProps = { ...appBarProps, ...head.appBarData };

    return <AppBar {...appBarProps} />;
  }

  const appRoot = global.document.getElementsByTagName("BODY")[0];

  return ReactDOM.createPortal(
    <Transition show={showComp} animation={mainAnim}>
      <div
        id={`modalId-${compRef.current}`}
        className={cn(s.root, {
          [s.bottomSheet]: setType === "bottomSheet",
          [s.fullScreenBottomSheet]: setType === "fullScreenBottomSheet",
          [s.popup]: setType === "popup",
          [s.fullScreenPopup]: setType === "fullScreenPopup",
          [s.page]: setType === "page",
          [s.blurredBg]: blurredBg,
          [s.isWeb]: !isWeb,
        })}
        onClick={(e) => {
          if (closeOnClickOutside) {
            e.stopPropagation();
            close();
          } else {
            e.stopPropagation();
          }
        }}
        style={rootStyle}
      >
        <Transition show={showContent} animation={bodyAnim}>
          <div
            className={cn(s.main)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={customeMainStyle}
          >
            <BackButton type="hidden" onBack={close} />
            {head.show &&
              setType !== "popup" &&
              setType !== "fullScreenPopup" && (
                <div className={s.head}>{_renderAppBar()}</div>
              )}
            <div
              className={cn(s.body, {
                [s.noPadding]: noPadding,
              })}
              style={customeBodyStyle}
            >
              {(setType === "popup" || setType === "fullScreenPopup") &&
                head?.show && (
                  <div className={s.floatingCross}>
                    <BackButton noPadding onBack={() => close()}>
                      <Cross width={32} height={32} />
                    </BackButton>
                  </div>
                )}
              {children}
            </div>
          </div>
        </Transition>
      </div>
    </Transition>,
    appRoot
  );
}
