import { getDeviceDetails } from "core-lib/utils/device";
import { Cookies, SessionStorage } from "core-lib/constants/storage";
import { checkMobileOS, checkSource } from "core-lib/utils/helpers";
import { getNativeVersions, isParkPlusWebView } from "core-lib/utils/source";
import { PLATFORM } from "core-lib/data/common";
import { isServer, isStringValid } from "./utils";
import { v4 as uuid } from "uuid";
import { getCookieValue } from "./cookie";

export function getDefaultHeaders({
  browser = false,
  os = false,
  packageName = false,
  appName = false,
  nativeAppVersion = false,
  deviceId = false,
  deviceOs = false,
  requestSource = false,
  coords = false,
  effectivePlatform = false,
  appsFlyerId = false,
  screenDimension = false,
  sessionId = false,
  utmSource = false,
}: {
  browser?: boolean;
  os?: boolean;
  packageName?: boolean;
  appName?: boolean;
  nativeAppVersion?: boolean;
  deviceId?: boolean;
  deviceOs?: boolean;
  requestSource?: boolean;
  coords?: boolean;
  screenDimension?: boolean;
  appsFlyerId?: boolean;
  effectivePlatform?: boolean; // This is to maintain uniqueness between web & webview_android
  sessionId?: boolean; // same session id sent in events, added for community
  utmSource?:boolean;
}) {
  const headers = {};
  if (!isServer()) {
    if (screenDimension) {
      headers["screen-width"] = window.innerWidth;
      headers["screen-height"] = window.innerHeight;
    }

    if (browser) {
      const { browser: BrowserInfo } = getDeviceDetails();
      if (BrowserInfo?.name) headers["browser-name"] = BrowserInfo.name;
      if (BrowserInfo?.version)
        headers["browser-version"] = BrowserInfo.version;
    }
    if (os) {
      const { os: osInfo } = getDeviceDetails();
      if (osInfo?.name) headers["os-name"] = osInfo.name;
      if (osInfo?.version) headers["os-version"] = osInfo.version;
    }
    if (packageName) headers["package-name"] = "web.pwa";
    if (appName) headers["app-name"] = "Park+ PWA";
    if (deviceId) {
      if (getCookieValue(Cookies.DeviceId)) {
        headers["device-id"] = getCookieValue(Cookies.DeviceId);
      }
    }
    if (appsFlyerId) {
      if (getCookieValue(Cookies.AppsflyerId)) {
        headers["appsflyer-id"] = getCookieValue(Cookies.AppsflyerId);
      }
    }
    if (deviceOs) headers["device-os"] = checkMobileOS();
    if (requestSource) headers["request-source"] = checkSource();
    if (coords) {
      const lat = getCookieValue(Cookies.Lat);
      const lng = getCookieValue(Cookies.Lng);
      if (lat && lng) {
        headers["latitude"] = lat;
        headers["longitude"] = lng;
      }
    }
    if (effectivePlatform) {
      let ePlatform = "web";
      if (isParkPlusWebView()) {
        const os = checkMobileOS();
        if (os === PLATFORM.android) {
          ePlatform = "webview_android";
        } else if (os === PLATFORM.ios) {
          ePlatform = "webview_ios";
        }
      }
      headers["platform"] = ePlatform;
    }

    if (nativeAppVersion && isParkPlusWebView()) {
      const nativeAppVersion = getNativeVersions().appVersion;
      if (nativeAppVersion) headers["version-name"] = nativeAppVersion;
    }
    if(utmSource){
      const utm_source= sessionStorage.getItem(SessionStorage.utmSource) || "organic";
        headers["utm-source"] = utm_source;
    }
  }

  if (sessionId) {
    headers["session-id"] =
      global?.sessionStorage &&
      isStringValid(
        global.sessionStorage.getItem(SessionStorage.TrackingSessionId)
      )
        ? global.sessionStorage.getItem(SessionStorage.TrackingSessionId)
        : uuid();
  }
  return headers;
}
