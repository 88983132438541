import { useEffect, useRef, useState } from "react";
import s from "./Swipeable.module.scss";

enum SwipeDireaction {
  default = "default",
  right = "right",
  left = "left",
}

type SwipeableType = {
  children: any;
  onSwipeEnd?: ({ direction, difference }) => void;
  shouldExit?: boolean;
  exitThreshold?: number;
  hasExited?: boolean;
  onExit?: () => void;
  slowMotion?: boolean;
  onSwiping?: ({ difference }) => void;
  intialPos?: number;
  allowedSwipeDirection?: keyof typeof SwipeDireaction;
  rightToLeftSwipe?: boolean;
};

export const Swipeable = ({
  children,
  shouldExit = true,
  exitThreshold = 0.25,
  hasExited = false,
  slowMotion = false,
  onExit,
  onSwipeEnd = () => {},
  onSwiping = () => {},
  rightToLeftSwipe= false,
  intialPos,
  allowedSwipeDirection = SwipeDireaction.default,
}: SwipeableType) => {
  const [startX, setStartX] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
  const [translateX, setTranslateX] = useState(0);
  const swipeContainerRef = useRef(null);

  const handleTouchStart = (event) => {
    const touch = event.touches[0];
    setStartX(touch.clientX);
    setCurrentX(touch.clientX);
    setIsSwiping(true);
  };

  const handleTouchMove = (event) => {
    if (!isSwiping) return;
    setCurrentX(event.touches[0].clientX);
    const difference = event.touches[0].clientX - startX;
    if (difference < 0 && allowedSwipeDirection === SwipeDireaction.right)
      return;
    if (difference > 0 && allowedSwipeDirection === SwipeDireaction.left)
      return;
    setTranslateX(difference);
    onSwiping({ difference: difference });
  };

  useEffect(() => {
    if (hasExited) {
      if (slowMotion) {
        swipeContainerRef.current.classList.add(s.slow_right_exit);
      } else {
        swipeContainerRef.current.classList.add(s.right_exit);
      }
    }
  }, [hasExited]);

  useEffect(() => {
    setTranslateX(intialPos);
  }, [intialPos]);

  const handleTouchEnd = () => {
    if (!isSwiping) return;
    const difference = currentX - startX;
    setTranslateX(0); // Reset the translation
    if (difference > 0 && allowedSwipeDirection != SwipeDireaction.left) {
      // Swiped right
      if (difference >= swipeContainerRef.current.offsetWidth * exitThreshold) {
        // Remove the component from the DOM on right swipe
        if (shouldExit) {
          if (slowMotion) {
            swipeContainerRef.current.classList.add(s.slow_right_exit);
          } else {
            swipeContainerRef.current.classList.add(s.right_exit);
          }
          if (onExit) {
            onExit();
          }
        }
      }
      onSwipeEnd({ difference: difference, direction: "right" });
    } 
    else if (
      difference < 0 &&
      allowedSwipeDirection != SwipeDireaction.right
    ) {
      if (
        difference <=
        -swipeContainerRef.current.offsetWidth * exitThreshold
      ) {
        if (shouldExit) {
          // Remove the component from the DOM on left swipe
          if (slowMotion) {
            swipeContainerRef.current.classList.add(s.slow_left_exit);
          } else {
            swipeContainerRef.current.classList.add(s.slow_left_exit);
          }
          if (onExit) {
            onExit();
          }
        }
      }
      onSwipeEnd({ difference: difference, direction: "left" });
    }
    else if(difference < 0 && rightToLeftSwipe ){
      onSwipeEnd({ difference: difference, direction: "left" });
    }
    setIsSwiping(false);
  };

  return (
    <div
      ref={swipeContainerRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{
        // touchAction: 'none', // Prevent scrolling during swipe
        transform: `translateX(${translateX}px)`, // Apply horizontal translation
        transition: isSwiping ? "none" : "transform 0.3s ease", // Smooth transition when not swiping
      }}
      className={s.root}
    >
      {children}
    </div>
  );
};
