import { createAction } from 'redux-actions';
import {
  AUTH_USER,
  LOGOUT_USER,
  LOGIN_POPUP_UPDATE, UPDATE_ADDRESS, UPDATE_FUEL
} from "./types";

export const authUser = createAction(AUTH_USER);
export const updateFuel = createAction(UPDATE_FUEL);
export const updateAddress = createAction(UPDATE_ADDRESS);
export const loginPopupUpdate = createAction(LOGIN_POPUP_UPDATE);
export const logoutUser = createAction(LOGOUT_USER);