import { PrimarySource } from "core-lib/constants/Source";
import {
  getInitialRoute,
  getNativeVersions,
  getPrimarySource,
  getRefererSource, getUtmData, isParkPlusWebView
} from "core-lib/utils/source";
import { getDeviceDetails } from "../device";
import { getPlatform } from "core-lib/utils/helpers";

export function getDefaultAttributes() {

  const { pathname,fullPathname } = getInitialRoute();
  const attr: any = {
    primary_source:getPrimarySource(),
    referer_source:getRefererSource(),
    initial_path:pathname,
    initial_full_path:fullPathname,
    platform:getPlatform(),
    ...getUtmData()
  };
  if (isParkPlusWebView()) {
    const { osVersion, appVersion } = getNativeVersions();
    attr.app_version = appVersion;
    attr.os_version = osVersion;
  } else {
    const { os, browser } = getDeviceDetails();
    attr.os_version = os.version;
    attr.os_name = os.name;
    attr.browser_version = browser.version;
    attr.browser_name = browser.name;
  }
  return attr;
}
