/** @format */

import React from "react";
import cn from "classnames";
import TickLinear from "ui/components/icons/Tick";
import s from "./successflash.module.scss";
import { Typography } from "ui";
import StatusBar from "../StatusBar";
import { getThemeColor } from "ui/primitives";
import formatCurrency from "core-lib/utils/currency";

type SuccessFlashProps = {
  title?: string;
  subtitle?: string;
  onClose?: () => void;
  closeText?: string | null;
};

const Img = {
  tick: "https://parkplus-bkt-img.parkplus.io/production/fuel/public/Banner013_20230714125019844866.gif",
};

export function SuccessFlash({
  title = null,
  subtitle = null,
  onClose,
  closeText = null,
}: SuccessFlashProps) {
  return (
    <div className={cn(s.root, "cmn_fullscreen_fixed")}>
      <StatusBar
        statusBarColor={getThemeColor("interaction_buttons")}
        theme="light"
      />
      <img src={Img.tick} alt="tick" />
      <div className={s.text_div}>
        <Typography
          variant="h2"
          color="grey_white"
          style={{ marginTop: "12px", textAlign: "center" }}
          label={title ?? "Hurrrayyy!"}
        />
        {closeText && (
          <div
            className={s.close_text}
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            aria-hidden
          >
            <Typography variant="body_para_r" color="white" label={closeText} />
          </div>
        )}
      </div>
    </div>
  );
}
