import React from "react";
import { Color, getThemeColor } from "ui/primitives";
import { IconTypes } from "ui/primitives/IconTypes";

function ChevronRight({
  primaryColor = Color.grey_80,
  width = 24,
  variant = "linear",
  height = 24,
}: IconTypes) {
  const primaryColorValue = `var(--${primaryColor})`;
  return {
    linear: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill={primaryColorValue}
          d="M9.53 5.47a.75.75 0 00-1.06 1.06l1.06-1.06zM15 12l.53.53a.75.75 0 000-1.06L15 12zm-6.53 5.47a.75.75 0 101.06 1.06l-1.06-1.06zm0-10.94l6 6 1.06-1.06-6-6-1.06 1.06zm6 4.94l-6 6 1.06 1.06 6-6-1.06-1.06z"
        />
      </svg>
    ),
    bulk: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          fill={primaryColorValue}
          d="M10.557 7.753l-4.42-4.286c-.276-.268-.804-.106-.804.246v8.573c0 .353.528.515.803.247l4.42-4.286a.332.332 0 000-.494z"
        />
      </svg>
    ),
  }[variant];
}

export default ChevronRight;
