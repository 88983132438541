import ip from 'icepick';
import {
  GET_PROJECT_DETAILS_REQUEST,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_FAILURE,
  RESET_PROFILE,
} from './types';

const initialState = ip.freeze({
  getProjectDetails: {
    apiStatus: null,
    apiError: null,
    data: null,
  },
  getFavourites: null,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROJECT_DETAILS_REQUEST: {
      state = ip.setIn(state, ['getProjectDetails', 'apiStatus'], 'started');
      state = ip.setIn(state, ['getProjectDetails', 'apiError'], null);
      return state;
    }

    case GET_PROJECT_DETAILS_SUCCESS: {
      state = ip.setIn(state, ['getProjectDetails', 'apiStatus'], 'success');
      state = ip.setIn(state, ['getProjectDetails', 'apiError'], null);
      state = ip.setIn(state, ['getProjectDetails', 'data'], action.payload);
      return state;
    }

    case GET_PROJECT_DETAILS_FAILURE: {
      state = ip.setIn(state, ['getProjectDetails', 'apiStatus'], 'failure');
      state = ip.setIn(
        state,
        ['getProjectDetails', 'apiError'],
        action.payload
      );
      return state;
    }

    case RESET_PROFILE: {
      state = ip.setIn(state, ['getFavourites'], initialState.getFavourites);
      state = ip.setIn(
        state,
        ['getProjectDetails'],
        initialState.getProjectDetails
      );
      return state;
    }

    default:
      return state;
  }
}
