import { NATIVE_LOGIN } from "core-lib/constants/nativeBridge";
import { PWA_HOSTNAME } from "core-lib/data/common";
import { COOKIE } from "core-lib/types/Helper";
import { setAuthHeaders, setClientHeaders } from "core-lib/utils/apiV2";
import { onReset, redirectToNativeLogin } from "core-lib/utils/common";
import { setCookieValue } from "core-lib/utils/cookie";
import { passMessageToNative } from "core-lib/utils/nativeBridge";
import { isParkPlusWebView } from "core-lib/utils/source";
import {
  storeLocalProfile,
  storeUserLocations,
} from "core-lib/utils/tokenStorage";
import { isNativeLoginAvailable } from "core-lib/utils/utils";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { authUser, logoutUser } from "../store/action";

interface AuthType {
  profile?: any;
  accessToken?: string;
  refreshToken?: string;
  userLocations?: any;
}
export function useAuth() {
  const dispatch = useDispatch();

  const router = useRouter();
  const { asPath, pathname } = router;
  const setAuthData = ({
    profile,
    accessToken,
    refreshToken,
    userLocations,
  }: AuthType) => {
    const reqData: any = {};
    setTimeout(() => {
      setClientHeaders();
    }, 0);
    if (accessToken !== undefined && accessToken !== null) {
      reqData.accessToken = accessToken;
      setAuthHeaders(accessToken);
      setCookieValue(COOKIE.accessToken, accessToken);
    }
    if (refreshToken !== undefined && refreshToken !== null) {
      reqData.refreshToken = refreshToken;
      setCookieValue(COOKIE.refreshToken, refreshToken);
    }
    if (profile !== undefined && profile !== null) {
      reqData.profile = profile;
      storeLocalProfile(JSON.stringify(profile));
    }
    if (userLocations !== undefined && userLocations !== null) {
      reqData.userLocations = profile;
      storeUserLocations(JSON.stringify(userLocations));
    }
    dispatch(authUser(reqData));
  };

  const redirectToLogin = () => {
    let path = encodeURIComponent(asPath || pathname);

    if (global?.window?.location?.host === PWA_HOSTNAME) {
      if (router.basePath) {
        path = router.basePath + path;
      }
      router.replace(`${PWA_HOSTNAME}/signin?redirect=${path}`);
    } else {
      router.replace(`/signin?redirect=${path}`);
    }
  };

  const handleLogout = (redirectToSignIn: boolean) => {
    dispatch(logoutUser());
    if (redirectToSignIn) {
      if (isNativeLoginAvailable()) {
        passMessageToNative(NATIVE_LOGIN, null, {});
      } else if (isParkPlusWebView()) {
        redirectToNativeLogin();
      } else {
        onReset();
        redirectToLogin();
      }
    } else {
      onReset();
    }
  };

  return {
    setAuthData,
    redirectToLogin,
    handleLogout,
  };
}
