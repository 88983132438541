import React from "react";
import { IconTypes } from "ui/primitives/IconTypes";
import { getThemeColor, Color } from "ui/primitives";

export default ({
                  width = 24,
                  height = 24,
                  primaryColor = Color.text_default,
                  variant
                }: IconTypes) => {
  const primaryColorValue = `var(--${primaryColor})`;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5885 5.24714C12.6815 4.91904 13.2639 4.91708 13.3591 5.24454C13.794 6.73986 14.6003 8.95013 15.8196 10.1612C17.039 11.3724 19.2546 12.1637 20.7529 12.5885C21.081 12.6815 21.0829 13.2639 20.7555 13.3591C19.2601 13.794 17.0499 14.6003 15.8388 15.8196C14.6276 17.039 13.8363 19.2546 13.4115 20.7529C13.3185 21.081 12.7361 21.0829 12.6409 20.7555C12.206 19.2601 11.3997 17.0499 10.1804 15.8388C8.96103 14.6276 6.74537 13.8363 5.24714 13.4115C4.91904 13.3185 4.91708 12.7361 5.24454 12.6409C6.73986 12.206 8.95013 11.3997 10.1612 10.1804C11.3724 8.96103 12.1637 6.74537 12.5885 5.24714Z"
        fill={primaryColorValue}
      />
      <path
        d="M5.89712 4.06178C5.92038 3.97976 6.06597 3.97927 6.08978 4.06113C6.19849 4.43497 6.40008 4.98753 6.70491 5.29031C7.00974 5.59309 7.56366 5.79094 7.93822 5.89712C8.02024 5.92038 8.02073 6.06597 7.93887 6.08978C7.56503 6.19849 7.01247 6.40008 6.70969 6.70491C6.40691 7.00974 6.20906 7.56366 6.10288 7.93822C6.07962 8.02024 5.93403 8.02073 5.91022 7.93887C5.80151 7.56503 5.59992 7.01247 5.29509 6.70969C4.99026 6.40691 4.43634 6.20906 4.06178 6.10288C3.97976 6.07962 3.97927 5.93403 4.06113 5.91022C4.43497 5.80151 4.98753 5.59992 5.29031 5.29509C5.59309 4.99026 5.79094 4.43634 5.89712 4.06178Z"
        fill={primaryColorValue}
      />
      <path
        d="M18.8457 1.09268C18.8806 0.969641 19.099 0.968903 19.1347 1.0917C19.2977 1.65245 19.6001 2.4813 20.0574 2.93547C20.5146 3.38964 21.3455 3.68641 21.9073 3.84569C22.0304 3.88057 22.0311 4.09896 21.9083 4.13467C21.3476 4.29774 20.5187 4.60012 20.0645 5.05736C19.6104 5.51461 19.3136 6.34549 19.1543 6.90732C19.1194 7.03036 18.901 7.0311 18.8653 6.9083C18.7023 6.34755 18.3999 5.5187 17.9426 5.06453C17.4854 4.61036 16.6545 4.31359 16.0927 4.15431C15.9696 4.11943 15.9689 3.90104 16.0917 3.86533C16.6524 3.70226 17.4813 3.39988 17.9355 2.94263C18.3896 2.48539 18.6864 1.65451 18.8457 1.09268Z"
        fill={primaryColorValue}
      />
    </svg>
  );
};
