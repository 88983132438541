import Button from "ui/components/Button";
import Modal from "ui/components/Modal";
import Typography from "ui/components/Typography";
import Info1 from "ui/components/newIcons/Info1";
import { Color, ErrorBottomSheetProps, ErrorUiTypeEnum } from "ui/primitives";
import s from "./ErrorBottomSheet.module.scss";

export const ErrorBottomSheet: React.FunctionComponent<
  ErrorBottomSheetProps
> = ({ show, onClose, errObj, cta = [] }) => {
  const getIconColor = () => {
    switch (errObj?.ui_type) {
      case ErrorUiTypeEnum.bottom_sheet_err:
        return Color.negative_text;
      case ErrorUiTypeEnum.bottom_sheet_warn:
        return Color.warning_text;
      default:
        return Color.warning_text;
    }
  };

  return (
    <Modal
      show={show}
      head={{ show: true, onClose: onClose }}
      rootStyle={{ zIndex: 5 }}
      variant="bottomSheet"
      customeBodyStyle={{ padding: "0px" }}
      animation={{ timeout: "m", type: "btt" }}
    >
      <div className={s.root}>
        <div className={s.content}>
          <div className={s.icon}>
            <Info1 height={48} width={48} primaryColor={getIconColor()} />
          </div>
          <div className={s.text}>
            <Typography label={errObj?.title} variant="h3" renderAs="H1" />
            {errObj?.description && (
              <Typography
                label={errObj?.description}
                variant="body_para_r"
                color="text_subheading"
              />
            )}
          </div>
        </div>
        <div className={s.btn}>
          {errObj?.cta?.length > 0 ? (
            errObj.cta.map((item, idx) => (
              <Button
                key={`${idx}^^@`}
                label={item?.label}
                onClick={() => {
                  item?.onClick();
                  onClose();
                }}
                variant={item?.variant}
                fullwidth
              />
            ))
          ) : (
            <Button label="Close" fullwidth onClick={onClose} />
          )}
        </div>
      </div>
    </Modal>
  );
};
