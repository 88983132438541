import { useAuth } from "auth/hooks/useAuth";
import { getRefreshToken } from "auth/services/getRefreshToken";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import dayjs from "dayjs";
import React from "react";
import toast from "react-hot-toast";
import { Host, HOST_ENV_MAP } from "../../config/Env";
import {
  GET_SECURE_KEY,
  SET_SHARED_PREFERENCE,
} from "../../constants/nativeBridge";
import { STRAPI_ENDPOINT } from "../../data/common";
import { COOKIE, DictType } from "../../types/Helper";
import { getCookieValue } from "../../utils/cookie";
import { trackEvent } from "../../utils/events";
import { passMessageToNative } from "../../utils/helpers";
import { wait, waitForSecureKeyUpdate } from "../../utils/nativeBridge";
import { getLocalProfile } from "../../utils/tokenStorage";
import { generateRequestId, isSecureHeaderAvailable } from "../../utils/utils";

let refreshingFunc = undefined;
export function NetworkInterceptor() {
  const { handleLogout, setAuthData } = useAuth();

  const getSecureHeader = async ({ token, url }) => {
    const req_key = generateRequestId();
    const callBackScript = ` 
           window.sessionStorage.setItem("${req_key}", JSON.stringify({
              hash : "{{hashValue}}",
              epoch : "{{epochTime}}",
            }))
          setTimeout(()=>{
             window.dispatchEvent(new Event('SecureKeyStorage'));
         },0)
        `;
    passMessageToNative(GET_SECURE_KEY, null, {
      callback_script: callBackScript,
      Authorization: token,
      path: url,
    });
    const updatedValue: any = await waitForSecureKeyUpdate(req_key);
    const value = JSON.parse(updatedValue);
    return { deviceId: value?.hash ?? "", epoch: value?.epoch ?? "" };
  };
  async function reqInterceptor(request: AxiosRequestConfig) {
    const req: DictType = { ...request };
    req.metadata = { startTime: dayjs() };
    let auth =
      req?.headers?.Authorization ?? req?.headers?.common?.Authorization;
    const magento = HOST_ENV_MAP[Host.magento];
    if (req.url.includes(magento)) {
      if (
        (!!req?.headers?.Authorization ||
          !!req?.headers?.common?.Authorization) &&
        auth?.split(" ")?.[0] !== "Bearer"
      ) {
        auth = `Bearer ${auth}`;

        if (req.headers.Authorization) {
          req.headers.Authorization = auth;
        } else {
          req.headers.common.Authorization = auth;
        }
      }
      const profile = getLocalProfile();
      if (profile?.id) {
        const url = new URL(req.url);
        if (!url.search.includes("abc")) {
          url.searchParams.append("abc", profile.id);
          req.url = url.toString();
        }
      }
    }

    if (isSecureHeaderAvailable()) {
      const { deviceId, epoch }: any = await getSecureHeader({
        url: req.url,
        token: auth,
      });
      req.headers["new-device-id"] = deviceId;
      req.headers["epoch-time"] = epoch;
      return req;
    } else {
      return req;
    }
  }
  function resInterceptor(response: AxiosResponse) {
    return response;
  }

  function trackErrorEvent(error: any) {
    trackEvent({
      name: "api_error",
      attributes: {
        api_url: error?.config?.url,
        api_body: JSON.stringify(error?.config?.data),
        error_code: error?.response?.status,
        error_message: error?.response?.data?.message,
      },
    });
  }
  async function errInterceptor(error: any) {
    if (!error.response) {
      trackErrorEvent(error);
      return Promise.reject(error);
    }

    // Handle specific URLs or conditions
    if (shouldBypassErrorHandling(error.config.url)) {
      trackErrorEvent(error);
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      return await handleAuthenticationError(error);
    }
    // Handle other types of errors if needed
    trackErrorEvent(error);
    return Promise.reject(error);
  }
  function shouldBypassErrorHandling(url: string) {
    return [
      STRAPI_ENDPOINT,
      "localhost:1337",
      "/fastag-recharge/tag/vrn-detail",
      "user/partner/otp-less/",
    ].some((v) => url.indexOf(v) > -1);
  }
  async function handleAuthenticationError(error: any) {
    const refreshToken = getCookieValue(COOKIE.refreshToken);
    const url = error.config.url;
    const canLogout = ["/logout", "/refreshToken"].some(
      (v) => url.indexOf(v) > -1
    );
    if (canLogout) {
      trackErrorEvent(error);
      toast.error(error?.message ?? "something went wrong");
      handleLogout(true);
      return Promise.reject(error);
    } else {
      if (!refreshingFunc) refreshingFunc = getRefreshToken(refreshToken);
      try {
        const token = await refreshingFunc;
        const reqData = { accessToken: token };
        passMessageToNative(SET_SHARED_PREFERENCE, null, {
          key: "auth_token",
          value: `${token}`,
        });
        await wait(1000);
        setAuthData(reqData);
        error.config.headers.Authorization = token;
        if (isSecureHeaderAvailable()) {
          const { deviceId, epoch }: any = await getSecureHeader({
            url: error.config.url,
            token: token,
          });
          error.config.headers["new-device-id"] = deviceId;
          error.config.headers["epoch-time"] = epoch;
        }
        return await axios.request(error.config);
      } catch (error) {
        trackErrorEvent(error);
        toast.error(error?.message ?? "something went wrong");
        handleLogout(true);
        return Promise.reject(error);
      } finally {
        refreshingFunc = undefined;
      }
    }
  }
  function setInterceptors() {
    const request = axios.interceptors.request.use(reqInterceptor);
    const response = axios.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    return { request, response };
  }
  function ejectInterceptors({ request, response }) {
    axios.interceptors.request.eject(request);
    axios.interceptors.response.eject(response);
  }

  React.useEffect(() => {
    const { request, response } = setInterceptors();
    return () => ejectInterceptors({ request, response });
  }, []);

  return null;
}
