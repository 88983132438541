import s from "./MaterialSpinner.module.scss";

export type VariantType = "default" | "dark" | "light" | "blue";

export function MaterialSpinner({
  size = "m",
  width = 74,
  height = 74,
  stokeWidth = 7,
}: {
  size?: "s" | "m" | "l";
  width?: number;
  height?: number;
  stokeWidth?: number;
}) {
  let finalWidth = 74;
  let finalHeight = 74;
  if (width && height) {
    finalWidth = width;
    finalHeight = height;
  }
  return (
    <div style={{ width: finalWidth, height: finalHeight }}>
      <div className={s.loadingSpinner}>
        <svg className={s.loadingSpinnerCircle} viewBox="25 25 50 50">
          <circle
            className={s.stroke}
            cx="50"
            cy="50"
            r="21"
            fill="none"
            strokeWidth={`${stokeWidth}`}
            strokeMiterlimit="10"
          />
          <circle
            className={s.stroke2}
            cx="50"
            cy="50"
            r="21"
            fill="none"
            strokeWidth={`${stokeWidth}`}
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    </div>
  );
}
