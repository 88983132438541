import { ApiDataFormat } from "../types/Network";

export const API_STATUS = {
  success: "success",
  failure: "failure",
  in_progress: "started",
  default: null,
};

export const ERROR_MESSAGE = {
  genericFailure: "Oops! Something went wrong!",
  apiError: "Something went wrong, Please try again later",
  requestNotFulfilled: "Couldn't fulfill your request. please try again",
  pageLoadFail: "An issue occurred while loading the page. please try again.",
  genericSuccess: "Success!",
};

export const API_DATA_FORMAT: ApiDataFormat = {
  apiStatus: API_STATUS.default,
  data: null,
  apiError: null,
  errCount: 0,
  retryCount: 0,
};
