import React from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";

import Head from "next/head";
import { requiredFieldValidation } from "core-lib/utils/validation";

export const ImageSlider = (props) => {
  const { data, customStyle } = props;
  const setting = {
    dots: true,
    arrows: false,
  };

  return (
    <div>
      <Head>
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
      </Head>
      <Slider {...setting}>
        {data.map((item) => (
          <div
            className="slider-item"
            style={{ ...customStyle }}
            key={item.imgUrl}
          >
            <div
              className="slider-img"
              style={{ backgroundImage: `url(${item.imgUrl})` }}
            >
              {/* <img src={item.imgUrl} /> */}
            </div>
            {requiredFieldValidation(item.title).isValid && (
              <p className="slider-title">{item.title}</p>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

ImageSlider.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  customStyle: PropTypes.objectOf(PropTypes.any),
};

ImageSlider.defaultProps = {
  customStyle: null,
};
