export const IOS_APP_LINK =
  'https://apps.apple.com/in/app/parkplus-smart-parking-app/id1244749178';

export const ANDROID_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.ovunque.parkwheels';

export const FACEBOOK_LINK = 'https://www.facebook.com/prkpls/';

export const TWITTER_LINK = 'https://twitter.com/parkplus_io';

export const INSTAGRAM_LINK = 'https://www.instagram.com/parkplusio/';

export const LINKEDIN_LINK = 'https://www.linkedin.com/company/parkplus/';

export const YOUTUBE_LINK = 'https://www.youtube.com/@parkplusapp';
export const APP_LINK_OTHERS_BANNER = 'https://l.prk.bz/xmuqYdF5ilb';
export const APP_LINK_FOOTER = 'https://l.prk.bz/3YdXY305ilb';
