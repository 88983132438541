import cn from "classnames";
import * as React from "react";
import Typography from "../Typography";
import s from "./Stepper.module.scss";

import vibrate from 'core-lib/utils/vibration';

interface StepperProps {
  count: number;
  min?: number;
  max?: number;
  step?: number;
  onChange(args: number): void;
}

const Stepper: React.FunctionComponent<StepperProps> = ({
  count = 0,
  min = 0,
  max = 10,
  step = 1,
  onChange,
}) => (
  <div className={s.root}>
    <button
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        if (count - step < min) {
          return;
        }
        vibrate();
        onChange(count - step);
      }}
      className={cn(s.btn, {
        [s.disabled]: count - step < min,
      })}
    >
      -
    </button>
  <div className={s.text}>
      <Typography
        renderAs="SPAN"
        color="text_default"
        variant="body_para_m"
        label={count}
      />
    </div>
    <button
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        if (count + step > max) {
          return;
        }
        vibrate();
        onChange(count + step);
      }}
      className={cn(s.btn, {
        [s.disabled]: count + step > max,
      })}
    >
      +
    </button>
  </div>
);

export default Stepper;
