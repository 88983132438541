export enum PrimarySource {
  ParkPlusPWA = "parkplus_pwa",
  ParkPlusWebView = "parkplus_webview",
  ParkPlusCleanerWebView = "parkplus_cleaner_webview",
  MGWebView = "mg_webview",
  BMS = "bookmyshow",
  ParkPlusUCDWebView = "parkplus_ucd_webview",
  ParkPlusPndWebView = "parkplus_pnd_webview",
  ParkPlusTOAWebView = "parkplus_toa_webview",
  ParkPlusPMSWebView = "parkplus_pms_webview",
  ParkPlusMOAWebView = "parkplus_moa_webview",
  ParkPlusPosiflexWebView = "parkplus_posiflex_webview",
}

export enum RefererSource {
  Organic = "organic",
  Interlinking = "interlinking",
  PushNotification = "notification",
  Campaign = "campaign",
  Direct = "direct",
  BMS = "bookmyshow",
}
