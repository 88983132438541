import React from "react";
import Stars1 from "ui/components/newIcons/Stars1";
import { Color, TypeScales } from "ui/primitives";
import { RatingType } from "../../primitives/RatingTypes";
import Typography from "../Typography";
import s from "./Rating.module.scss";

const Rating: React.FunctionComponent<RatingType> = ({
                                                       title,
                                                       averageRating,
                                                       totalRating,
                                                       details,
                                                       topRatingColor,
                                                       midRatingColor,
                                                       lowRatingColor,
                                                       bgColor
                                                     }) => {
  const getColor = (rating) => {
    if (rating === 1) {
      return `var(--${lowRatingColor})` || `var(--${Color.negative_50})`;
    } else if (rating === 2) {
      return `var(--${midRatingColor})` || `var(--${Color.orange_50})`;
    } else {
      return `var(--${topRatingColor})` || `var(--${Color.positive_50})`;
    }
  };
  return (
    <section
      className={s.root}
      style={{
        backgroundColor: `var(--${bgColor})` || `var(--${Color.grey_10})`
      }}
    >
      <div className={s.title}>
        <Typography
          label={title}
          variant={TypeScales.h3}
          color={Color.text_default}
        />
      </div>
      <div className={s.left}>
        <div className={s.rating}>
          <Stars1 width={32} height={32} />
          <Typography
            label={averageRating}
            variant={TypeScales.h1}
            color={Color.text_default}
          />
        </div>
        <div className={s.desc}>
          <Typography
            label={`Based on ${totalRating} customer ratings`}
            variant={TypeScales.small_r}
            color={Color.text_subheading}
          />
        </div>
      </div>
      <div className={s.right}>
        {details?.map((el, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={s.rating_bars} key={idx}>
            <Typography
              label={el.rating}
              variant="body_big_r"
              color="text_subheading"
              renderAs="SPAN"
            />
            <div className={s.bar}>
              <span
                style={{
                  width: `${el.percent}%`,
                  backgroundColor: getColor(el.rating)
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className={s.right} />
    </section>
  );
};

export default Rating;
