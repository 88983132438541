/** @format */

import React from "react";
import cn from "classnames";
import s from "./FadeInSection.module.scss";
import { useComponentInViewport } from "core-lib/hooks/useComponentInViewport";

export const FadeInSection = ({ children }: { children: any }) => {
  const domRef = React.useRef();

  const isInViewPort = useComponentInViewport(domRef);

  return (
    <div
      className={cn(s.root, {
        [s.is_visible]: isInViewPort,
      })}
      ref={domRef}
    >
      {children}
    </div>
  );
};
