/** @format */

import cn from "classnames";
import React, { ChangeEvent, useEffect, useState } from "react";

import { getRandomNum } from "core-lib/utils/helpers";
import { Input, Modal, Typography } from "../../index";
import ChevronDown from "../icons/ChevronDown";
import Search from "../icons/Search";
import Alert from "../newIcons/Alert";
import s from "./DropDownWithSearch.module.scss";

type DropDownProps = {
  label?: string;
  options: any[]; //must have name and value, can have other keys
  value: string | number;
  placeholder?: string;
  randomNum?: number;
  width?: string;
  onSelect: (option) => void;
  name: string;
  hasError?: boolean;
  errorText?: string;
  customInputStyle?: React.CSSProperties | null;
  labelStyle?: React.CSSProperties | null;
  disabled?: boolean;
  selectFirstOption?: boolean;
};

export const DropDownWithSearch: React.FC<DropDownProps> = ({
  label,
  options,
  value,
  placeholder = "Select",
  randomNum = getRandomNum(),
  width,
  onSelect,
  name,
  hasError = false,
  errorText = "Invalid",
  customInputStyle,
  labelStyle,
  disabled,
  selectFirstOption = true,
}) => {
  const [searchText, setSearchText] = useState("");
  const [optionsList, setOptionsList] = useState(options);
  const [showDropDown, setShowDropDown] = useState(false);

  useEffect(() => {
    const filteredOptions = options?.filter(
      (el) =>
        String(el.name).toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    );
    setOptionsList(filteredOptions);
  }, [searchText]);

  useEffect(() => {
    if (showDropDown) {
      setSearchText("");
      setOptionsList(options);
    }
  }, [showDropDown]);

  return (
    <div className={s.root} style={{ width }}>
      {label !== "" && (
        <label
          className={s.label}
          htmlFor={randomNum.toString()}
          style={labelStyle}
        >
          {label}
        </label>
      )}

      <div
        className={s.input}
        style={customInputStyle}
        onClick={() => setShowDropDown(!showDropDown)}
      >
        <Input
          id={randomNum.toString()}
          name={name}
          value={value}
          disabled={disabled}
          className={s.input}
          rightIcon={<ChevronDown />}
        />
      </div>

      <Modal
        show={showDropDown}
        head={{
          show: false,
          onClose: () => {
            setShowDropDown(false);
          },
        }}
        variant="popup"
        rootStyle={{ zIndex: 10 }}
        animation={{ type: "btt", timeout: "m" }}
        closeOnClickOutside={true}
        customeBodyStyle={{ height: "300px" }}
        isWeb
      >
        <div>
          <div className={s.searchWrapper}>
            <Input
              type="search"
              placeholder="Search"
              name="search-text"
              value={searchText}
              autoFocus
              onChange={(e) => setSearchText(e.currentTarget.value)}
              rightIcon={<Search />}
            />
          </div>
          <div className={s.list}>
            {optionsList.map((option) => (
              <div
                onClick={() => {
                  onSelect(option);
                  setShowDropDown(false);
                }}
                className={s.optionName}
              >
                {option.name}
              </div>
            ))}
          </div>
        </div>
      </Modal>

      {hasError && errorText && (
        <div className={s.error_msg}>
          <Alert
            variant="linear"
            height={16}
            width={16}
            primaryColor="negative_60"
          />
          <Typography
            style={{ marginLeft: "4px" }}
            label={errorText}
            variant="small_para_m"
            color="negative_60"
          />
        </div>
      )}
    </div>
  );
};
