import { createAction } from "redux-actions";
import {
  UPDATE_APP_MODE,
  UPDATE_LANGUAGE,
  UPDATE_REDUX_RESTORE_STATE,
  UPDATE_THEME,
} from "./type";

export const updateAppMode = createAction(UPDATE_APP_MODE);
export const updateReduxRestoreState = createAction(UPDATE_REDUX_RESTORE_STATE);
export const updateTheme = createAction(UPDATE_THEME);
export const updateLanguage = createAction(UPDATE_LANGUAGE);
