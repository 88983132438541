import { getAuthModule } from 'auth/store/authModule';
import { getLayoutModule } from 'core-lib/reduxStore/layout/layoutModule';
import { getMiscModule } from 'core-lib/reduxStore/misc/miscModule';
import { getProfileModule } from 'core-lib/reduxStore/profile/profileModule';
import { getSettingModule } from 'core-lib/reduxStore/setting/settingModule';
import { getTestDriveModule } from 'testDrive/store/testDriveModule';

export enum Modules {
  Auth,
  Misc,
  Layout,
  Profile,
  Setting,
  TestDrive,
}

const reduxModuleMap = {
  [Modules.Layout]: getLayoutModule(),
  [Modules.Misc]: getMiscModule(),
  [Modules.Setting]: getSettingModule(),
  [Modules.Profile]: getProfileModule(),
  [Modules.Auth]: getAuthModule(),
  [Modules.TestDrive]: getTestDriveModule(),
};

export const DEFAULT_REDUX_MODULES = [
  Modules.Auth,
  Modules.Layout,
  Modules.Misc,
  Modules.Setting,
  Modules.Profile,
  Modules.TestDrive,
];

export const loadReduxModules = (modules: any[]) => {
  const reduxModules = [];
  modules.forEach((m) => {
    reduxModules.push(reduxModuleMap[m]);
  });
  return reduxModules;
};
