export enum ButtonVariants {
  Primary = "Primary",
  PrimaryOutline = "PrimaryOutline",
  PrimaryNaked = "PrimaryNaked",
  PrimaryLink = "PrimaryLink",

  Success = "Success",
  SuccessOutline = "SuccessOutline",
  SuccessNaked = "SuccessNaked",
  SuccessLink = "SuccessLink",

  Danger = "Danger",
  DangerOutline = "DangerOutline",
  DangerNaked = "DangerNaked",
  DangerLink = "DangerLink",

  Black = "Black",
  BlackOutline = "BlackOutline",
  BlackNaked = "BlackNaked",
  BlackLink = "BlackLink",
  OctagonalFilled = "OctagonalFilled",
  ButtonPrimary = "ButtonPrimary",
  ButtonSecondary = "ButtonSecondary",
  ButtonPositive = "ButtonPositive",
  ButtonNegative = "ButtonNegative",
  ButtonTertiary = "ButtonTertiary",
  ButtonLink = "ButtonLink",
  ButtonGhostDefault = "ButtonGhostDefault",
  ButtonGhostLink = "ButtonGhostLink",
}

export enum ButtonSizes {
  Small = "Small",
  Big = "Big",
}
