import { isNumeric } from "core-lib/utils/regex";
import dayjs from "dayjs";
import { isDataValid } from "./helpers";

export const getTodayEpoch = () =>
  dayjs(dayjs().format("YYYY-MM-DD")).valueOf();

const checkIfEmailValid = (email) => {
  let isValid = false;
  if (isDataValid(email)) {
    isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }
  return isValid;
};

export const emailValidation = (email) => {
  const obj = {
    isValid: false,
    errMsg: "",
  };
  if (isDataValid(email)) {
    if (email.length !== 0) {
      const isValid = checkIfEmailValid(email.trim());
      obj.isValid = isValid;
      if (!isValid) obj.errMsg = "Invalid email address";
    } else {
      obj.errMsg = "Required";
    }
  } else {
    obj.errMsg = "Invalid";
  }

  return obj;
};

export const requiredNumberValidation = (data) => {
  const obj = {
    isValid: false,
    errMsg: "",
  };

  if (isDataValid(data)) {
    if (data !== "") {
      const isValid = isNumeric(data);
      obj.isValid = isValid;
      if (!isValid) obj.errMsg = "Invalid number";
    } else {
      obj.errMsg = "Required";
    }
  } else {
    obj.errMsg = "Invalid";
  }

  return obj;
};

export const indianPhoneNumberValidation = (phoneNumber) => {
  const obj = {
    isValid: false,
    errMsg: "",
  };

  obj.isValid = /^[6-9]\d{9}$/.test(JSON.stringify(Number(phoneNumber)));

  if (String(phoneNumber).length !== 10) {
    obj.errMsg = "Phone number should be 10 digits";
  } else if (!obj.isValid) {
    obj.errMsg = "Invalid phone number";
  }
  return obj;
};

export const phoneNumberValidation = (phoneNumber) => {
  const obj = {
    isValid: false,
    errMsg: "",
  };

  const length = 10;
  if (requiredNumberValidation(phoneNumber).isValid) {
    if (phoneNumber.length === length) {
      obj.isValid = true;
    } else {
      obj.errMsg = "Phone number should be 10 digits";
    }
  } else {
    obj.errMsg = requiredNumberValidation(phoneNumber).isValid
      ? ""
      : "Invalid phone number";
  }
  return obj;
};

export const pincodeValidation = (pincode) => {
  const obj = {
    isValid: false,
    errMsg: "",
  };

  const length = 6;
  if (requiredNumberValidation(pincode).isValid) {
    if (pincode.length === length) {
      if (!invalidPincodeList.includes(pincode) && pincode[0] !== "0") {
        obj.isValid = true;
      } else {
        obj.errMsg = "Invalid pincode";
      }
    } else {
      obj.errMsg = "Pincode should be 6 digits";
    }
  } else {
    obj.errMsg = requiredNumberValidation(pincode).isValid
      ? ""
      : "Required field";
  }
  return obj;
};

export const validateOnlyAlphabets = (str) => {
  const obj = {
    isValid: false,
    errMsg: "",
  };
  obj.isValid = /^[a-zA-Z ]+$/i.test(str);
  if (!obj.isValid) {
    obj.errMsg = "Only Alphabets allowed";
  }
  return obj;
};

export const validateString = (str) => {
  const obj = {
    isValid: false,
    errMsg: "",
  };
  if (isDataValid(str)) {
    if (str.replace(/\s/g, "").length) {
      const regx = /^[a-z\d\-_\s]+$/i;
      if (!str.match(regx)) {
        obj.errMsg = "Invalid input";
      } else {
        obj.isValid = true;
      }
    } else {
      obj.errMsg = "Invalid input";
    }
  }
  return obj;
};

export const trimSpaces = (str) => {
  let newStr = str;
  // @ts-ignore
  if (String.prototype.trimStart) newStr = newStr.trimStart();
  // @ts-ignore
  if (String.prototype.trimEnd) newStr = newStr.trimEnd();
  return newStr;
};

export const vehicleNumberValidation = (vehicleNumber, maxLen = 10) => {
  const obj = {
    isValid: false,
    errMsg: "",
  };

  const VRNregex = [
    /^[a-zA-Z]{2}[0-9]{1,2}[a-zA-Z0-9]{1,2}[a-zA-Z]{0,1}[0-9]{3,4}$/i,
    /^[0-9]{2}[A-Z]{1,2}[0-9]{1,2}$/i,
    /[0-9]{1,2}[A-Z]{1,2}[0-9]{4}[A-Z]{1,2}/i,
    /^[A-Z,a-z]{3}[0-9]{4}$/i,
  ];

  const isValid = (num) => VRNregex.some((vn) => vn.test(num));

  if (vehicleNumber.length === 0) {
    obj.errMsg = "Required Field";
    obj.isValid = false;
  } else if (isValid(vehicleNumber)) {
    obj.errMsg = "";
    obj.isValid = true;
  } else {
    obj.errMsg = "Invalid Vehicle Number";
    obj.isValid = false;
  }

  return obj;
};

export const requiredFieldValidation = (data) => {
  const obj = {
    isValid: false,
    errMsg: "",
  };
  if (isDataValid(data)) {
    if (data !== "") {
      obj.isValid = true;
    } else {
      obj.errMsg = "Required field";
    }
  } else {
    obj.errMsg = "Required field";
  }

  return obj;
};

export const shortVehicleNumberValidation = (vehicleNumber) => {
  const obj = {
    isValid: false,
    errMsg: "",
  };

  const length = 4;
  if (requiredNumberValidation(vehicleNumber).isValid) {
    if (vehicleNumber.length === length) {
      obj.isValid = true;
    } else {
      obj.errMsg = "Vehicle number should be 4 digit number";
    }
  } else {
    obj.errMsg = requiredNumberValidation(vehicleNumber).errMsg;
  }
  return obj;
};

export const currentDateValidation = (date) => {
  const obj = {
    isValid: false,
    errMsg: "",
  };

  if (isDataValid(date)) {
    if (date !== "") {
      const ms = dayjs(date).valueOf();
      const currentMs = getTodayEpoch().toString();
      if (parseInt(currentMs, 10) <= parseInt(JSON.stringify(ms), 10)) {
        obj.isValid = true;
      } else {
        obj.errMsg = "Selected date cannot be in past";
      }
    } else {
      obj.errMsg = "Required field";
    }
  } else {
    obj.errMsg = "Required field";
  }

  return obj;
};

export const checkFullName = (str) => {
  const obj = {
    isValid: false,
    errMsg: "",
  };

  str = str.replace(/(^\s*)|(\s*$)/gi, "");
  str = str.replace(/[ ]{2,}/gi, " ");
  str = str.replace(/\n /, "\n");
  const strLen = str.split(" ").length;

  if (str?.length >= 2) {
    obj.isValid = true;
  } else {
    obj.errMsg = "Name must have atleast 2 letters";
  }

  return obj;
};

export function aadharValidation(val, customText = "") {
  const regex = /^[2-9]{1}[0-9]{11}$/;
  if (!val) {
    return { isValid: false, errMsg: "Missing aadhar number" };
  } else if (regex.test(val)) {
    return { isValid: true, errMsg: customText || "" };
  }
  return { isValid: false, errMsg: customText || "Invalid" };
}

export function panValidation(val, customText = "") {
  const regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  if (!val) {
    return { isValid: false, errMsg: "Missing pan number" };
  } else if (regex.test(val)) {
    return { isValid: true, errMsg: customText || "" };
  }
  return { isValid: false, errMsg: customText || "Invalid" };
}

export const invalidPincodeList = [
  "000000",
  "111111",
  "222222",
  "333333",
  "444444",
  "555555",
  "666666",
  "777777",
  "888888",
  "999999",
];
