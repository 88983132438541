/* eslint-disable @next/next/no-img-element */
import React, { FunctionComponent, useState } from "react";
import classnames from "classnames";
import s from "./ShimmerImage.module.scss";
import { AnimatedImage } from "ui";

interface OwnProps {
  src: string;
  alt?: string;
  className?: string;
  customStyle?: any;
  loading?: "lazy" | "eager";
}

type Props = OwnProps;

export const ShimmerImage: FunctionComponent<Props> = ({
  src,
  alt,
  className,
  customStyle = {},
  loading = "lazy",
}) => {
  // state
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className={classnames(s.root, className)}>
      <div
        className={classnames(className, {
          [s.shimmer]: !isVisible,
          cmn_shimmer: !isVisible,
          [s.hidden]: isVisible,
        })}
      />
      <AnimatedImage
        src={src}
        onLoad={() => {
          setIsVisible(true);
        }}
        alt={alt}
        loading={loading}
        customStyle={customStyle}
      />
    </div>
  );
};
