import { LastLocationResponse, UserLocationData } from "./Location";

export type AddressKeys =
  | "latitude"
  | "longitude"
  | "state"
  | "locality"
  | "city"
  | "pincode"
  | "country";

export type Address = {
  latitude: number | string;
  longitude: number | string;
  subLocality?: string;
  state?: string;
  locality: string;
  city?: string;
  pincode?: number | string;
  country?: string;
  infraCity?: string;
  zoneName?: string;
  isoCountryCode?: string;
  administrativeArea?: string;
  subAdministrativeArea?: string;
  thoroughfare?: string;
  subThoroughfare?: string;
  name?: string;
  stateId?: number;
};

export type AutoCompleteSuggestionType = {
  primaryText: string;
  secondaryText: string;
  placeId: string;
};

export enum AddressStatusType {
  Success = "done",
  Pending = "pending",
  Denied = "denied",
  Failed = "failed",
  Default = "default",
}

export type AddressStateType = {
  status: AddressStatusType;
  data?: Address;
  lastLocationData?: LastLocationResponse;
  defaultLocation?: Address;
  userLoc?: UserLocationData;
};

interface metaData {
  name: string;
  email: string;
  phone: string;
}
export interface AddressOld {
  id: number;
  landmark: string;
  locality: string;
  city: string;
  state: string;
  pincode: string;
  addressType: string;
  addressTag: string;
  isActive: boolean;
  address: string;
  metaData?: metaData;
  latitude: string | number;
  longitude: string | number;
}
