export enum Color {
  "white" = "white",
  "grey_0" = "grey_0",
  "grey_10" = "grey_10",
  "grey_20" = "grey_20",
  "grey_30" = "grey_30",
  "grey_40" = "grey_40",
  "grey_50" = "grey_50",
  "grey_60" = "grey_60",
  "grey_70" = "grey_70",
  "grey_80" = "grey_80",
  "grey_90" = "grey_90",
  "grey_100" = "grey_100",
  "black" = "black",
  "purple_0" = "purple_0",
  "purple_5" = "purple_5",
  "purple_10" = "purple_10",
  "purple_20" = "purple_20",
  "purple_30" = "purple_30",
  "purple_40" = "purple_40",
  "purple_50" = "purple_50",
  "purple_60" = "purple_60",
  "purple_70" = "purple_70",
  "purple_80" = "purple_80",
  "purple_90" = "purple_90",
  "purple_100" = "purple_100",
  "purple_110" = "purple_110",
  "interaction_0" = "interaction_0",
  "interaction_5" = "interaction_5",
  "interaction_10" = "interaction_10",
  "interaction_20" = "interaction_20",
  "interaction_30" = "interaction_30",
  "interaction_40" = "interaction_40",
  "interaction_50" = "interaction_50",
  "interaction_60" = "interaction_60",
  "interaction_70" = "interaction_70",
  "interaction_80" = "interaction_80",
  "interaction_button" = "interaction_button",
  "interaction_100" = "interaction_100",
  "blue_0" = "blue_0",
  "blue_10" = "blue_10",
  "blue_20" = "blue_20",
  "blue_30" = "blue_30",
  "blue_40" = "blue_40",
  "blue_50" = "blue_50",
  "blue_60" = "blue_60",
  "blue_70" = "blue_70",
  "blue_80" = "blue_80",
  "blue_90" = "blue_90",
  "blue_100" = "blue_100",
  "warning_0" = "warning_0",
  "warning_10" = "warning_10",
  "warning_20" = "warning_20",
  "warning_30" = "warning_30",
  "warning_40" = "warning_40",
  "warning_50" = "warning_50",
  "warning_60" = "warning_60",
  "warning_70" = "warning_70",
  "warning_80" = "warning_80",
  "warning_90" = "warning_90",
  "warning_text" = "warning_text",
  "warning_100" = "warning_100",
  "orange_0" = "orange_0",
  "orange_10" = "orange_10",
  "orange_20" = "orange_20",
  "orange_30" = "orange_30",
  "orange_40" = "orange_40",
  "orange_50" = "orange_50",
  "orange_60" = "orange_60",
  "orange_70" = "orange_70",
  "orange_80" = "orange_80",
  "orange_90" = "orange_90",
  "orange_100" = "orange_100",
  "positive_0" = "positive_0",
  "positive_10" = "positive_10",
  "positive_20" = "positive_20",
  "positive_30" = "positive_30",
  "positive_40" = "positive_40",
  "positive_50" = "positive_50",
  "positive_60" = "positive_60",
  "positive_70" = "positive_70",
  "positive_80" = "positive_80",
  "positive_text" = "positive_text",
  "positive_90" = "positive_90",
  "positive_100" = "positive_100",
  "negative_0" = "negative_0",
  "negative_10" = "negative_10",
  "negative_20" = "negative_20",
  "negative_30" = "negative_30",
  "negative_40" = "negative_40",
  "negative_50" = "negative_50",
  "negative_60" = "negative_60",
  "negative_70" = "negative_70",
  "negative_text" = "negative_text",
  "negative_90" = "negative_90",
  "negative_100" = "negative_100",
  "emerald_0" = "emerald_0",
  "emerald_10" = "emerald_10",
  "emerald_20" = "emerald_20",
  "emerald_30" = "emerald_30",
  "emerald_40" = "emerald_40",
  "emerald_50" = "emerald_50",
  "emerald_60" = "emerald_60",
  "emerald_70" = "emerald_70",
  "emerald_80" = "emerald_80",
  "emerald_90" = "emerald_90",
  "emerald_100" = "emerald_100",
  "rosewood_0" = "rosewood_0",
  "rosewood_10" = "rosewood_10",
  "rosewood_20" = "rosewood_20",
  "rosewood_30" = "rosewood_30",
  "rosewood_40" = "rosewood_40",
  "rosewood_50" = "rosewood_50",
  "rosewood_60" = "rosewood_60",
  "rosewood_70" = "rosewood_70",
  "rosewood_80" = "rosewood_80",
  "rosewood_90" = "rosewood_90",
  "rosewood_100" = "rosewood_100",
  "background" = "background",
  "background_tertiary" = "background_tertiary",
  "background_primary" = "background_primary",
  "stroke_divider" = "stroke_divider",
  "border_secondary" = "border_secondary",
  "text_placeholder" = "text_placeholder",
  "text_caption" = "text_caption",
  "text_label" = "text_label",
  "text_subheading" = "text_subheading",
  "text_default" = "text_default",
  "transparent" = "transparent",

  "icon_default" = "icon_default",
  "positive_background_light" = "positive_background_light",
  "positive_background_dark" = "positive_background_dark",
  "negative_background_light" = "negative_background_light",
  "negative_background_dark" = "negative_background_dark",
  "warning_background_light" = "warning_background_light",
  "warning_background_dark" = "warning_background_dark",
  "disabled_text" = "disabled_text",
  "disabled_background" = "disabled_background",

  "grey-stroke-divider" = "grey-stroke-divider",
  "grey_background" = "grey_background",
  "interaction_90" = "interaction_90",
  "grey_black" = "grey_black",
  "grey_white" = "grey_white",
  "interaction_buttons" = "interaction_buttons",
  "green_1" = "green_1",
  "green_2" = "green_2",
  "neutral_light_300" = "neutral_light_300",
}

const LightThemeColorsPallete = {
  white: "#FFFFFF",
  grey_0: "#F4F7FA",
  grey_10: "#FAFAFA",
  grey_20: "#F5F5F5",
  grey_30: "#EEEEEE",
  grey_40: "#DEDEDE",
  grey_50: "#C4C4C4",
  grey_60: "#939393",
  grey_70: "#757575",
  grey_80: "#616161",
  grey_90: "#424242",
  grey_100: "#212121",
  black: "#0E0E0E",

  purple_0: "#f8f6ff",
  purple_5: "#EEE9FE",
  purple_10: "#D3C9FB",
  purple_20: "#B5A5FA",
  purple_30: "#A696FE",
  purple_40: "#8167FF",
  purple_50: "#745DF4",
  purple_60: "#6545E5",
  purple_70: "#5E33D9",
  purple_80: "#5430BB",
  purple_90: "#442698",
  purple_100: "#331F6C",
  purple_110: "#3A2578",

  interaction_0: "#f8f6ff",
  interaction_5: "#EEE9FE",
  interaction_10: "#D3C9FB",
  interaction_20: "#B5A5FA",
  interaction_30: "#A696FE",
  interaction_40: "#8167FF",
  interaction_50: "#745DF4",
  interaction_60: "#6545E5",
  interaction_70: "#5E33D9",
  interaction_80: "#5430BB",
  interaction_button: "#5430BB",
  interaction_100: "#331F6C",

  blue_0: "#EFF7FF",
  blue_10: "#E1EFFF",
  blue_20: "#BBDAFF",
  blue_30: "#96C6FF",
  blue_40: "#71B2FF",
  blue_50: "#2A8DFF",
  blue_60: "#0076FF",
  blue_70: "#0057FF",
  blue_80: "#0044E6",
  blue_90: "#0937A5",
  blue_100: "#002888",

  warning_0: "#FFFBEB",
  warning_10: "#FFF7DB",
  warning_20: "#FFECB3",
  warning_30: "#FFE082",
  warning_40: "#FFD44F",
  warning_50: "#FFC928",
  warning_60: "#FFB201",
  warning_70: "#FF9F01",
  warning_80: "#FF8006",
  warning_90: "#EC6500",
  warning_100: "#B84F00",

  orange_0: "#FFFBEB",
  orange_10: "#FFF7DB",
  orange_20: "#FFECB3",
  orange_30: "#FFE082",
  orange_40: "#FFD44F",
  orange_50: "#FFC928",
  orange_60: "#FFB201",
  orange_70: "#FF9F01",
  orange_80: "#FF8006",
  orange_90: "#EC6500",
  orange_100: "#B84F00",

  positive_0: "#F0FDF1",
  positive_10: "#E5F6E6",
  positive_20: "#C2E8C2",
  positive_30: "#99D99A",
  positive_40: "#6CCB70",
  positive_50: "#42BD4C",
  positive_60: "#05AF25",
  positive_70: "#02A31E",
  positive_80: "#008200",
  positive_90: "#006300",
  positive_100: "#004200",

  negative_0: "#FFF5F7",
  negative_10: "#FEEBEF",
  negative_20: "#FFBFC8",
  negative_30: "#EE9A9E",
  negative_40: "#E47278",
  negative_50: "#EF5157",
  negative_60: "#F53F3F",
  negative_70: "#E6353D",
  negative_80: "#d32b37",
  negative_90: "#B51E2A",
  negative_100: "#A41521",

  emerald_0: "#E8FCFA",
  emerald_10: "#CDFDFA",
  emerald_20: "#9EE9E4",
  emerald_30: "#7AD9D3",
  emerald_40: "#5DBFB9",
  emerald_50: "#41A69E",
  emerald_60: "#2F8E88",
  emerald_70: "#1F7A74",
  emerald_80: "#13645F",
  emerald_90: "#0A4F4A",
  emerald_100: "#033D38",

  rosewood_0: "#FFF4FC",
  rosewood_10: "#FCE9F7",
  rosewood_20: "#F8C9DD",
  rosewood_30: "#F1AECA",
  rosewood_40: "#EF9EC0",
  rosewood_50: "#E98DB3",
  rosewood_60: "#D76695",
  rosewood_70: "#C4457A",
  rosewood_80: "#A6345D",
  rosewood_90: "#781639",
  rosewood_100: "#580623",

  transparent: "transparent",

  grey_white: "#FFFFFF",
  grey_black: "#0E0E0E",
  interaction_90: "#442698",
  interaction_buttons: "#5430BB",
  icon_background: "#F4F7FA",
  jet_black: "#000000",
  green_1: "#7FC052",
  green_2: "#05F77F",

  text_default: "#0e0e0e",
  text_subheading: "#616161",
  text_caption: "#757575",
  text_label: "#939393",
  text_placeholder: "#c4c4c4",
  grey_stroke_divider: "#eeeeee",
  "grey-stroke-divider": "#eeeeee",
  stroke_divider: "#eeeeee",
  border_secondary: "#f5f5f5",
  positive_text: "#008200",
  negative_text: "#d32b37",
  warning_text: "#ff8006",
  background: "#f4f7fa",
  background_tertiary: "#eeeeee",
  background_primary: "#ffffff",
  icon_default: "#424242",
  positive_background_light: "#e5f6e6",
  positive_background_dark: "#006300",
  negative_background_light: "#feebef",
  negative_background_dark: "#b51e2a",
  warning_background_light: "#fff7db",
  warning_background_dark: "#ec6500",
  disabled_text: "#c5c5c5",
  disabled_background: "#f5f5f5",
  neutral_light_300: "#9DA2A7",
};

const DarkThemeColorsPallete = {
  white: "#FFFFFF",
  grey_100: "#F4F7FA",
  grey_90: "#FAFAFA",
  grey_80: "#F5F5F5",
  grey_70: "#EEEEEE",
  grey_60: "#DEDEDE",
  grey_50: "#C4C4C4",
  grey_40: "#939393",
  grey_30: "#757575",
  grey_20: "#616161",
  grey_10: "#424242",
  grey_0: "#212121",
  black: "#0E0E0E",

  purple_110: "#3A2578",
  purple_100: "#f8f6ff",
  purple_90: "#EEE9FE",
  purple_80: "#D3C9FB",
  purple_70: "#B5A5FA",
  purple_60: "#A696FE",
  purple_50: "#8167FF",
  purple_40: "#745DF4",
  purple_30: "#6545E5",
  purple_20: "#5E33D9",
  purple_10: "#5430BB",
  purple_5: "#442698",
  purple_0: "#331F6C",

  interaction_100: "#f8f6ff",
  interaction_90: "#EEE9FE",
  interaction_80: "#D3C9FB",
  interaction_70: "#ffffff",
  interaction_60: "#A696FE",
  interaction_50: "#8167FF",
  interaction_40: "#ffffff",
  interaction_30: "#6545E5",
  interaction_20: "#5E33D9",
  interaction_10: "#5430BB",
  interaction_button: "#A696FE",
  interaction_5: "#442698",
  interaction_0: "#331F6C",

  blue_100: "#EFF7FF",
  blue_90: "#E1EFFF",
  blue_80: "#BBDAFF",
  blue_70: "#96C6FF",
  blue_60: "#71B2FF",
  blue_50: "#2A8DFF",
  blue_40: "#0076FF",
  blue_30: "#0057FF",
  blue_20: "#0044E6",
  blue_10: "#0937A5",
  blue_0: "#002888",

  warning_100: "#FFFBEB",
  warning_90: "#FFF7DB",
  warning_80: "#FFECB3",
  warning_70: "#FFE082",
  warning_60: "#FFD44F",
  warning_50: "#FFC928",
  warning_40: "#FFB201",
  warning_30: "#FF9F01",
  warning_20: "#FF8006",
  warning_10: "#EC6500",
  warning_0: "#B84F00",

  orange_100: "#FFFBEB",
  orange_90: "#FFF7DB",
  orange_80: "#FFECB3",
  orange_70: "#FFE082",
  orange_60: "#FFD44F",
  orange_50: "#FFC928",
  orange_40: "#FFB201",
  orange_30: "#FF9F01",
  orange_20: "#FF8006",
  orange_10: "#EC6500",
  orange_0: "#B84F00",

  positive_100: "#F0FDF1",
  positive_90: "#E5F6E6",
  positive_80: "#C2E8C2",
  positive_70: "#99D99A",
  positive_60: "#6CCB70",
  positive_50: "#42BD4C",
  positive_40: "#05AF25",
  positive_30: "#02A31E",
  positive_20: "#008200",
  positive_10: "#006300",
  positive_0: "#004200",

  negative_100: "#FFF5F7",
  negative_90: "#FEEBEF",
  negative_80: "#FFBFC8",
  negative_70: "#EE9A9E",
  negative_60: "#E47278",
  negative_50: "#EF5157",
  negative_40: "#F53F3F",
  negative_30: "#E6353D",
  negative_20: "#d32b37",
  negative_10: "#B51E2A",
  negative_0: "#A41521",

  emerald_100: "#E8FCFA",
  emerald_90: "#CDFDFA",
  emerald_80: "#9EE9E4",
  emerald_70: "#7AD9D3",
  emerald_60: "#5DBFB9",
  emerald_50: "#41A69E",
  emerald_40: "#2F8E88",
  emerald_30: "#1F7A74",
  emerald_20: "#13645F",
  emerald_10: "#0A4F4A",
  emerald_0: "#033D38",

  rosewood_100: "#FFF4FC",
  rosewood_90: "#FCE9F7",
  rosewood_80: "#F8C9DD",
  rosewood_70: "#F1AECA",
  rosewood_60: "#EF9EC0",
  rosewood_50: "#E98DB3",
  rosewood_40: "#D76695",
  rosewood_30: "#C4457A",
  rosewood_20: "#A6345D",
  rosewood_10: "#781639",
  rosewood_0: "#580623",

  transparent: "transparent",

  grey_white: "#FFFFFF",
  grey_black: "#0E0E0E",
  interaction_buttons: "#A696FE",
  icon_background: "#F4F7FA",
  jet_black: "#000000",
  green_1: "#7FC052",
  green_2: "#05F77F",

  text_default: "#ffffff",
  text_subheading: "#dedede",
  text_caption: "#c5c5c5",
  text_label: "#eeeeee",
  text_placeholder: "#939393",
  grey_stroke_divider: "#424242",
  "grey-stroke-divider": "#424242",
  stroke_divider: "#424242",
  border_secondary: "#424242",
  positive_text: "#42bd4c",
  negative_text: "#d32b37",
  warning_text: "#ffc928",
  background: "#424242",
  background_tertiary: "#757575",
  background_primary: "#0e0e0e",
  icon_default: "#c5c5c5",
  positive_background_light: "#006300",
  positive_background_dark: "#05af25",
  negative_background_light: "#feebef",
  negative_background_dark: "#b51e2a",
  warning_background_light: "#ec6500",
  warning_background_dark: "#ffb201",
  disabled_text: "#c5c5c5",
  disabled_background: "#616161",
  neutral_light_300: "#9DA2A7",
};

enum Themes {
  Light = "light",
  Dark = "dark",
}

export const ColorsPallete = LightThemeColorsPallete;

export const getThemeColor = (color: keyof typeof Color) => {
  const isDark =
    global?.window?.localStorage.getItem("theme") === Themes.Dark &&
    // @ts-ignore
    global?.window?.isThemeEnabled;

  return isDark
    ? DarkThemeColorsPallete[color]
    : LightThemeColorsPallete[color];
};
