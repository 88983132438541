import { useState } from "react";
import cn from "classnames";
import { Color, TypeScales } from "ui/primitives";
import { FaqType } from "ui/primitives/FaqTypes";
import Markdown from "markdown-to-jsx";
import ChevronDown from "../icons/ChevronDown";
import { Divider, Typography } from "ui";
import s from "./Faq.module.scss";

export const Faq = ({
  heading = "FAQ's",
  showHeading = true,
  faqs,
  headingSize = TypeScales.h3,
  questionVariant = TypeScales.body_para_r,
  questionColor = Color.text_default,
  headingColor = Color.text_default,
  answerColor = Color.text_subheading,
  dividerClassName = "",
  defaultActiveFaqs = [],
}: FaqType) => {
  const [activeFaqIds, setActiveFaqIds] = useState(defaultActiveFaqs);

  function handleActiveFaq(id) {
    if (activeFaqIds.includes(id)) {
      const result = activeFaqIds.filter((item) => item !== id);
      setActiveFaqIds(result);
    } else setActiveFaqIds([...activeFaqIds, id]);
  }

  return (
    <div className={s.root}>
      {showHeading && (
        <div className={s.top}>
          <Typography
            renderAs="H3"
            variant={headingSize}
            label={heading}
            color={headingColor}
          />
        </div>
      )}

      <div className={s.bottom}>
        {faqs &&
          faqs?.map((item, idx) => (
            <div className={s.item} key={item?.question}>
              <div
                className={cn(s.question)}
                onClick={() => handleActiveFaq(idx)}
              >
                <Typography
                  renderAs="P"
                  variant={questionVariant}
                  label={item?.question}
                  color={questionColor}
                />
                <span
                  className={cn(s.icon, {
                    [s.active]: activeFaqIds.includes(idx),
                  })}
                >
                  <ChevronDown primaryColor={questionColor} />
                </span>
              </div>
              <div
                className={cn(s.answer, {
                  [s.expand]: activeFaqIds.includes(idx),
                })}
              >
                <Markdown
                className={s.answerText}
                style={{
                  color: `var(--${answerColor})`,
                  whiteSpace: "break-spaces",
                }}
                >
                  {item?.answer}
                </Markdown>
              </div>
              <Divider className={dividerClassName} />
            </div>
          ))}
      </div>
    </div>
  );
};
