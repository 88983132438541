import React from "react";
import { Color, ColorType } from "ui/primitives";

interface CircularProgressProps {
  size: number;
  width: number;
  progress: number;
  color: ColorType;
  backgroundColor: ColorType;
}

export const Circular = ({
                           progress = 0,
                           color = Color.interaction_button,
                           size = 100,
                           width = 30,
                           backgroundColor = Color.emerald_30
                         }: CircularProgressProps) => {
  const radius = size / 2 - width / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;
  const colorValue = `var(--${color})`;
  const backgroundValue = `var(--${backgroundColor})`;

  return (
    <svg width={size} height={size}>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke={backgroundValue}
        strokeWidth={width}
        fill="none"
      />
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke={colorValue}
        strokeWidth={width}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={offset}
        fill="none"
      />
    </svg>
  );
};
