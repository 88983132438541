/** @format */

import classNames from "classnames";
import React, { ChangeEvent } from "react";

import { getRandomNum } from "core-lib/utils/helpers";
import { Typography } from "../../index";
import ChevronDown from "../icons/ChevronDown";
import Alert from "../newIcons/Alert";
import s from "./DropDown.module.scss";

export enum DROPDOWN_STYLE_TYPE {
  default = "default",
  inline = "inline",
  v2 = "v2",
}

type Option = {
  name: string | number;
  value: string | number;
  attribute1?: any;
};

type DropDownProps = {
  label?: string;
  options: Option[];
  value: string | number;
  placeholder?: string;
  randomNum?: number;
  width?: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  name: string;
  hasError?: boolean;
  errorText?: string;
  type?: DROPDOWN_STYLE_TYPE;
  customInputStyle?: React.CSSProperties | null;
  labelStyle?: React.CSSProperties | null;
  disabled?: boolean;
  selectFirstOption? : boolean;
};

const DropDown: React.FC<DropDownProps> = ({
  label,
  options,
  value,
  placeholder = "Select",
  randomNum = getRandomNum(),
  width,
  onChange,
  name,
  hasError = false,
  errorText = "Invalid",
  type = DROPDOWN_STYLE_TYPE.default,
  customInputStyle,
  labelStyle,
  disabled,
  selectFirstOption = true
}) => {
  return (
    <div
      className={classNames(s.root, {
        [s.inline_drop_down]: type === DROPDOWN_STYLE_TYPE.inline,
        [s.v2_drop_down]: type === DROPDOWN_STYLE_TYPE.v2,
      })}
      style={{ width }}
    >
      {label !== "" && (
        <label
          className={s.label}
          htmlFor={randomNum.toString()}
          style={labelStyle}
        >
          {label}
        </label>
      )}
      <div className={s.input} style={customInputStyle}>
        <select
          id={randomNum.toString()}
          onChange={onChange}
          name={name}
          value={value}
          disabled={disabled}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {!selectFirstOption&& <option value="" selected disabled hidden>Choose here</option>}
          {options.map((item) => (
            <option
              key={item.value.toString()}
              data-name={item.name.toString()}
              data-attribute1={item.attribute1?.toString()}
              value={item.value.toString()}
            >
              {item.name.toString()}
            </option>
          ))}
        </select>
        <span className={s.arrow}>
          <ChevronDown />
        </span>
      </div>
      {hasError && errorText && (
        <div className={s.error_msg}>
          <Alert
            variant="linear"
            height={16}
            width={16}
            primaryColor="negative_60"
          />
          <Typography
            style={{ marginLeft: "4px" }}
            label={errorText}
            variant="small_para_m"
            color="negative_60"
          />
        </div>
      )}
    </div>
  );
};

export default DropDown;
