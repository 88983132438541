import ip from 'icepick';
import {
  GET_VEHICLE_BRAND_REQUEST,
  GET_VEHICLE_BRAND_SUCCESS,
  GET_VEHICLE_BRAND_FAILURE,
  GET_VEHICLE_BRAND_RESET,
  GET_BANK_LIST_FAILURE,
  GET_BANK_LIST_REQUEST,
  GET_BANK_LIST_SUCCESS,
  GET_BANK_LIST_RESET,
  RESET_MISC,
} from './types';

const initialState = ip.freeze({
  getVehicleBrand: {
    apiStatus: null,
    apiError: null,
    data: null,
  },
  getFastagBankList: {
    apiStatus: null,
    apiError: null,
    data: null,
  },
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE_BRAND_REQUEST: {
      state = ip.setIn(state, ['getVehicleBrand', 'apiStatus'], 'started');
      state = ip.setIn(state, ['getVehicleBrand', 'apiError'], null);
      return state;
    }

    case GET_VEHICLE_BRAND_SUCCESS: {
      state = ip.setIn(state, ['getVehicleBrand', 'apiStatus'], 'success');
      state = ip.setIn(state, ['getVehicleBrand', 'apiError'], null);
      state = ip.setIn(state, ['getVehicleBrand', 'data'], action.payload);
      return state;
    }

    case GET_VEHICLE_BRAND_FAILURE: {
      state = ip.setIn(state, ['getVehicleBrand', 'apiStatus'], 'failure');
      state = ip.setIn(state, ['getVehicleBrand', 'apiError'], action.payload);
      return state;
    }

    case GET_VEHICLE_BRAND_RESET: {
      state = ip.setIn(
        state,
        ['getVehicleBrand'],
        initialState.getVehicleBrand
      );
      return state;
    }

    case GET_BANK_LIST_REQUEST: {
      state = ip.setIn(state, ['getFastagBankList', 'apiStatus'], 'started');
      state = ip.setIn(state, ['getFastagBankList', 'apiError'], null);
      return state;
    }

    case GET_BANK_LIST_SUCCESS: {
      state = ip.setIn(state, ['getFastagBankList', 'apiStatus'], 'success');
      state = ip.setIn(state, ['getFastagBankList', 'apiError'], null);
      state = ip.setIn(state, ['getFastagBankList', 'data'], action.payload);
      return state;
    }
    case GET_BANK_LIST_FAILURE: {
      state = ip.setIn(state, ['getFastagBankList', 'apiStatus'], 'failure');
      state = ip.setIn(
        state,
        ['getFastagBankList', 'apiError'],
        action.payload
      );
      return state;
    }

    case GET_BANK_LIST_RESET: {
      state = ip.setIn(
        state,
        ['getFastagBankList'],
        initialState.getFastagBankList
      );
      return state;
    }

    case RESET_MISC: {
      state = ip.setIn(
        state,
        ['getVehicleBrand'],
        initialState.getVehicleBrand
      );
      state = ip.setIn(
        state,
        ['getFastagBankList'],
        initialState.getFastagBankList
      );
      return state;
    }

    default:
      return state;
  }
}
