import { deleteCookie, getCookie, setCookie } from "cookies-next";
import { isServer, isStringValid } from "./utils";

export function getCookieFromResponseHeader(key: string, res) {
  const responseCookies = res?.getHeader("set-cookie");
  let cookieValue = "";

  if (responseCookies?.length) {
    cookieValue = responseCookies.filter((cookie) => cookie.includes(key))?.[0];
    if (isStringValid(cookieValue)) {
      cookieValue = cookieValue.split(`${key}=`)?.[1];
      if (isStringValid(key)) cookieValue = cookieValue.split("; Path")?.[0];
    }
  }
  return cookieValue;
}

export function deleteCookieFromResponseHeader(key, res) {
  const responseCookies = res?.getHeader("set-cookie");

  if (responseCookies?.length) {
    responseCookies.filter((cookie) => !cookie.includes(key));
    res.setHeader("set-cookie", responseCookies);
  }
}

export function setCookieValue(key: string, value: string, options = null) {
  return isServer() ? setCookie(key, value, options) : setCookie(key, value);
}
export function deleteCookieValue(key: string, options = null) {
  return isServer() ? deleteCookie(key, options) : deleteCookie(key);
}
export function getCookieValue(key: string, options = null) {
  return isServer() && options ? getCookie(key, options) : getCookie(key);
}
