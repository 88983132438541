import React from "react";
import { Color, getThemeColor, IconTypes } from "ui/primitives";

export const BlankStar = ({
  height = 20,
  width = 20,
  primaryColor = Color.grey_30,
}: IconTypes) => {
  const primaryColorValue = `var(--${primaryColor})`;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.148 1.266C18.788 -0.422 21.21 -0.422 21.852 1.266L25.992 12.734C26.1365 13.1076 26.3908 13.4286 26.7214 13.6546C27.0521 13.8806 27.4435 14.0011 27.844 14H38.018C39.898 14 40.718 16.34 39.24 17.486L32 24C31.6757 24.2493 31.4387 24.5949 31.323 24.9873C31.2073 25.3797 31.2189 25.7986 31.356 26.184L34 37.39C34.644 39.19 32.56 40.736 30.984 39.628L21.15 33.388C20.8133 33.1513 20.4117 33.0243 20 33.0243C19.5884 33.0243 19.1868 33.1513 18.85 33.388L9.01603 39.628C7.44203 40.736 5.35603 39.188 6.00003 37.39L8.64403 26.184C8.78119 25.7986 8.79275 25.3797 8.67705 24.9873C8.56135 24.5949 8.32434 24.2493 8.00003 24L0.760031 17.486C-0.719969 16.34 0.104031 14 1.98003 14H12.154C12.5546 14.0013 12.9462 13.881 13.2769 13.6549C13.6076 13.4289 13.8618 13.1077 14.006 12.734L18.146 1.266H18.148Z"
        fill={primaryColorValue}
      />
    </svg>
  );
};
