import { useEffect } from 'react';
import { useAuth } from "auth/hooks/useAuth";
import { getLocalProfile } from "../utils/tokenStorage";
import { COOKIE } from "../types/Helper";
import { getCookieValue } from "../utils/cookie";

export default function InitStore({ onDone }: { onDone: () => void }) {
  const {setAuthData} = useAuth();

  useEffect(() => {
    const accessToken = getCookieValue(COOKIE.accessToken);
    const refreshToken = getCookieValue(COOKIE.refreshToken);
    const profile = getLocalProfile();
    setAuthData({ profile,accessToken,refreshToken })
    if (onDone) onDone();
  }, []);

  return null;
}
