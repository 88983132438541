import cn from 'classnames';
import Typography from 'ui/components/Typography';
import s from './ToastComp.module.scss';
import { GENERIC_ERROR_EVENT } from 'core-lib/constants/common';
import { useEffect } from 'react';

interface ToastCompProps {
  leftIcon?: any;
  rightIcon?: any;
  label: string;
  isVisible: boolean;
}

export const ToastComp: React.FunctionComponent<ToastCompProps> = ({
  leftIcon,
  rightIcon,
  label,
  isVisible,
}) => {
  useEffect(() => {
    return () => {
      window.removeEventListener(GENERIC_ERROR_EVENT, () => {});
    };
  }, []);
  return (
    <div
      className={cn('cmn_toast_container', s.container, {
        [s.bottom]: !isVisible,
      })}
    >
      <div className={s.leftIcon}>{leftIcon}</div>
      <div className={s.msg}>
        <Typography
          variant='small_m'
          color='white'
          label={label}
          // style={{
          //   whiteSpace: 'nowrap',
          //   overflow: 'hidden',
          //   textOverflow: 'ellipsis',
          // }}
        />
      </div>
      <div className={s.rightIcon}>{rightIcon}</div>
    </div>
  );
};
