import cn from 'classnames';
import React from 'react';
import Slider from 'react-slick';
import { AnimatedImage, BackButton, Modal, Pagination } from 'ui';
import CrossCircle from 'ui/components/icons/CrossCircle';
import s from './ImagePopup.module.scss';

interface ImagePopupProps {
  show: boolean;
  onClose: () => void;
  data: string[];
}

export const ImagePopup = ({ show, onClose, data }: ImagePopupProps) => {
  const [activePageExt, setActivePageExt] = React.useState(0);

  const sliderRefExt = React.useRef<any>();

  function handleClickExt(idx) {
    sliderRefExt?.current?.slickGoTo(idx);
  }

  return (
    <Modal
      show={show}
      head={{
        show: false,
        onClose: () => onClose(),
      }}
      variant='page'
      rootStyle={{ zIndex: 10 }}
      animation={{ type: 'btt', timeout: 'm' }}
    >
      <div className={s.main}>
        <div className={s.cross}>
          <BackButton
            onBack={() => {
              onClose();
              setActivePageExt(0);
            }}
          >
            <CrossCircle
              width={32}
              height={32}
              variant='bulk'
              primaryColor='grey_20'
            />
          </BackButton>
        </div>
        {data?.length > 0 && (
          <div className={s.root}>
            <div className={s.slider}>
              <Slider
                dots={false}
                infinite={false}
                arrows={false}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                afterChange={(current) => {
                  setActivePageExt(current);
                }}
                ref={sliderRefExt}
              >
                {data?.map((item, idx) => (
                  <div key={`${idx}$image`}>
                    <AnimatedImage src={item} />
                  </div>
                ))}
              </Slider>
            </div>
            {data?.length > 1 ? (
              <div className={s.footer}>
                <div className={s.pagination}>
                  <Pagination
                    activePage={activePageExt + 1}
                    totalPages={data?.length}
                    onPrevClick={() => {
                      handleClickExt(activePageExt - 1);
                    }}
                    onNextClick={() => {
                      handleClickExt(activePageExt + 1);
                    }}
                    theme='dark'
                  />
                </div>
                <div className={s.scroller}>
                  <div>
                    {data?.map((item, idx) => (
                      <button
                        className={cn(s.scroll_img, {
                          [s.active_btn]: idx === activePageExt,
                        })}
                        type='button'
                        onClick={() => handleClickExt(idx)}
                        id={`img-${idx}`}
                        key={`${idx},$`}
                      >
                        <div>
                          <AnimatedImage src={item} />
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
