import React from "react";
import { IconTypes } from "ui/primitives/IconTypes";
import { Color, getThemeColor } from "../../primitives";

function TickLinear({
                      variant = "linear",
                      primaryColor = Color.grey_70,
                      width = 24,
                      height = 24
                    }: IconTypes) {
  const primaryColorValue = `var(--${primaryColor})`;
  return {
    linear: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill={primaryColorValue}
          d="M4.575 13.427a.75.75 0 00-1.15.964l1.15-.964zM8.267 19l-.575.482a.75.75 0 001.15 0L8.266 19zM20.575 5.482a.75.75 0 10-1.15-.964l1.15.964zM3.425 14.39l4.267 5.09 1.15-.963-4.267-5.09-1.15.963zm5.416 5.09l11.734-14-1.15-.963-11.733 14 1.15.964z"
        />
      </svg>
    ),
    bulk: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          cx="12"
          cy="12"
          r="9.25"
          stroke={primaryColorValue}
          strokeWidth="1.5"
        />
        <path
          fill={primaryColorValue}
          d="M8.578 11.523a.75.75 0 10-1.156.955l1.156-.955zm1.9 3.478l-.578.477a.75.75 0 001.13.03l-.552-.507zm6.074-5.493a.75.75 0 10-1.104-1.016l1.104 1.016zm-9.13 2.97l2.478 3 1.157-.955-2.479-3-1.156.955zm3.608 3.03l5.522-6-1.104-1.016-5.521 6 1.103 1.016z"
        />
      </svg>
    ),
    custom: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 68 67"
      >
        <path
          fill={primaryColorValue}
          d="M34 .167C15.632.167.665 15.133.665 33.5c0 18.367 14.967 33.333 33.333 33.333 18.367 0 33.334-14.966 33.334-33.333C67.333 15.133 52.366.167 33.999.167zm15.933 25.666l-18.9 18.9a2.497 2.497 0 01-3.534 0L18.066 35.3a2.515 2.515 0 010-3.533 2.515 2.515 0 013.533 0l7.667 7.666L46.399 22.3a2.515 2.515 0 013.534 0c.966.967.966 2.533 0 3.533z"
        />
      </svg>
    )
  }[variant];
}

export default TickLinear;
