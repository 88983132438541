export const BOOKING_SUCCESS_POPUP_FEEDBACK_UPDATE =
  "BOOKING_SUCCESS_POPUP_FEEDBACK_UPDATE";
export const CREATE_VISITOR_POPUP_UPDATE = "CREATE_VISITOR_POPUP_UPDATE";
export const UPDATE_VISITOR_POPUP_UPDATE = "UPDATE_VISITOR_POPUP_UPDATE";
export const UPDATE_MAP_LOADING_STATUS = "UPDATE_MAP_LOADING_STATUS";
export const ADD_FLAT_POPUP_UPDATE = "ADD_FLAT_POPUP_UPDATE";
export const ADD_FAMILY_MEMBER_POPUP_UPDATE = "ADD_FAMILY_MEMBER_POPUP_UPDATE";
export const IMAGE_VIEWER_UPDATE = "IMAGE_VIEWER_UPDATE";
export const VEHICLE_DETAIL_POPUP_UPDATE = "VEHICLE_DETAIL_POPUP_UPDATE";
export const FEEDBACK_POPUP_UPDATE = "FEEDBACK_POPUP_UPDATE";
export const UPDATE_LOCATION_REQUEST = "LOCATION_UPDATE_LOCATION_REQUEST";
export const UPDATE_LOCATION_RESPONSE = "LOCATION_UPDATE_LOCATION_RESPONSE";
export const UPDATE_LAST_LOCATION_RESPONSE =
  "LAST_LOCATION_UPDATE_LOCATION_RESPONSE";

export const RESET_LAYOUT = "RESET_LAYOUT";
