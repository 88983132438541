import { PrimarySource, RefererSource } from "core-lib/constants/Source";
import { Cookies, SessionStorage } from "core-lib/constants/storage";
import { getCookieValue } from "./cookie";
import { isServer } from "./utils";

export function getPrimarySource(): string {
  let source: string = PrimarySource.ParkPlusPWA;
  if (!isServer()) {
    source = getCookieValue(Cookies.PrimarySource) || PrimarySource.ParkPlusPWA;
  }
  return source;
}

export function getRefererSource(): string {
  let source: string = RefererSource.Organic;
  if (!isServer()) {
    source = getCookieValue(Cookies.RefererSource) || RefererSource.Organic;
  }
  return source;
}

export function getInitialRoute(): { pathname: string; fullPathname: string } {
  let pathname = null;
  let fullPathname = null;

  if (!isServer()) {
    pathname =
      global.window.sessionStorage.getItem(SessionStorage.InitialPagePath) ||
      null;
    fullPathname =
      global.window.sessionStorage.getItem(
        SessionStorage.InitialPageFullPath
      ) || null;
  }

  return { pathname, fullPathname };
}

export function getWebViewPlatform(): string {
  let platform = null;
  if (!isServer()) {
    platform = getCookieValue(Cookies.WebviewPlatform) || null;
  }
  return platform;
}

export function getNativeVersions(options = null): {
  osVersion: string;
  appVersion: string;
} {
  const osVersion = getCookieValue(Cookies.OsVersion, options) || "";
  const appVersion = getCookieValue(Cookies.VersionName, options) || "";

  return { osVersion, appVersion };
}

export function getDeviceId(options = null): String {
  return getCookieValue(Cookies.DeviceId, options) || "";
}

export function getNativeCoords(options = null): { lat: string; lng: string } {
  const lat = getCookieValue(Cookies.Lat, options);
  const lng = getCookieValue(Cookies.Lng, options);
  if (lat && lng) {
    return { lat, lng };
  }
  return null;
}

export const isParkPlusWebView = () =>
  getPrimarySource() === PrimarySource.ParkPlusWebView;
export const isUCDWebView = () =>
  getPrimarySource() === PrimarySource.ParkPlusUCDWebView;
export const isPndWebView = () =>
  getPrimarySource() === PrimarySource.ParkPlusPndWebView;
export const isPmsWebView = () =>
  getPrimarySource() === PrimarySource.ParkPlusPMSWebView;
export const isMoaWebView = () =>
  getPrimarySource() === PrimarySource.ParkPlusMOAWebView;

export const isTOAWebView = () =>
  getPrimarySource() === PrimarySource.ParkPlusTOAWebView;
export const isParkPlusPWA = () =>
  getPrimarySource() === PrimarySource.ParkPlusPWA;
export const isMGWebView = () => getPrimarySource() === PrimarySource.MGWebView;

export const getUtmData = () => {
  const utmData: any = {};
  if (!isServer()) {
    const utmSource = sessionStorage.getItem(SessionStorage.utmSource);
    const utmCampaign = sessionStorage.getItem(SessionStorage.utmCampaign);
    const utmContent = sessionStorage.getItem(SessionStorage.utmContent);
    const utmTerm = sessionStorage.getItem(SessionStorage.utmTerm);
    if (utmSource) {
      utmData.utm_source = utmSource;
    }
    if (utmCampaign) {
      utmData.utm_campaign = utmCampaign;
    }
    if (utmTerm) {
      utmData.utm_term = utmTerm;
    }
    if (utmContent) {
      utmData.utm_content = utmContent;
    }
  }
  return utmData;
};
