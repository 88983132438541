import React, { useEffect, useState } from "react";
import { Color, ColorsPallete, ColorType } from "ui/primitives";
import Typography from "../Typography";
import s from "./RangeSlider.module.scss";

export function RangeSlider({
  value,
  min,
  minLabel,
  maxLabel,
  showLabel = false,
  customLabel = "",
  max,
  step,
  onChange,
  labelColor = Color.text_caption,
  sliderColor = Color.positive_40,
  bgColor = Color.grey_20,
  isDisabled = false,
  showMinMax = true,
  ...propsToFwd
}: {
  value: number;
  onChange: (args: any) => void;
  min: number;
  minLabel: string;
  maxLabel: string;
  showLabel?: boolean;
  customLabel?: string;
  step: number;
  max: number;
  labelColor?: ColorType;
  isDisabled?: boolean;
  sliderColor?: ColorType;
  bgColor?: ColorType;
  showMinMax?: boolean;
}) {
  const [sliderVal, setSliderVal] = useState(value);
  const sliderRef = React.useRef();

  const [marginRight, setMarginRight] = React.useState("-32px");

  function updateStyle(sliderVal) {
    if (sliderRef && sliderRef.current) {
      // @ts-ignore
      sliderRef.current.style.width = `${
        //width
        sliderVal > max ? 100 : ((sliderVal - min) / (max - min)) * 100
      }%`;
    }

    //here we are setting the style value "right" for our selected value
    if (((sliderVal - min) / (max - min)) * 100 <= 33) {
      //if "width" is less than and equal to 33, set right='32px'
      setMarginRight("-32px");
    }
    //if 'width' is greater than 33 and less than 66, than set right ='24px'
    if (
      ((sliderVal - min) / (max - min)) * 100 > 33 &&
      ((sliderVal - min) / (max - min)) * 100 <= 66
    ) {
      setMarginRight("-24px");
    }
    //if 'width' is greater than 66 and smaller than 100 set right='18px'
    if (
      ((sliderVal - min) / (max - min)) * 100 > 66 &&
      ((sliderVal - min) / (max - min)) * 100 <= 100
    ) {
      setMarginRight("-18px");
    }
  }

  const changeCallback = (e) => {
    setSliderVal(e.target.value);
    onChange(Number(e.target.value));
    updateStyle(e.target.value);
  };

  useEffect(() => {
    updateStyle(value);
  }, []);

  useEffect(() => {
    setSliderVal(value);
    updateStyle(value);
  }, [value]);

  return (
    <div className={s.container}>
      {showMinMax && (
        <div className={s.minLabelText}>
          <Typography
            variant="body_para_r"
            color={labelColor}
            label={minLabel}
          />
        </div>
      )}
      <div className={s.root}>
        <input
          type="range"
          min={min}
          max={max}
          className={s.slider}
          style={{ background: `${ColorsPallete[bgColor]}` }}
          value={sliderVal}
          onChange={changeCallback}
          step={step}
          disabled={isDisabled}
          {...propsToFwd}
        />
        <div
          ref={sliderRef}
          style={{ background: `var(--${sliderColor})` }}
          className={s.filled}
        >
          {showLabel && (
            <span className={s.thumbText} style={{ right: `${marginRight}` }}>
              <Typography
                variant="body_para_r"
                color={labelColor}
                label={customLabel ? customLabel : `${value || 0}`}
              />
            </span>
          )}
        </div>
      </div>
      {showMinMax && (
        <div className={s.maxLabelText}>
          <Typography
            variant="body_para_r"
            color={labelColor}
            label={maxLabel}
          />
        </div>
      )}
    </div>
  );
}
