import Typography from "ui/components/Typography";
import s from "./Loader.module.scss";

interface LoaderProps {
  variant?: "loading" | "success";
  loadingTitle?: string;
  loadingSubtitle?: string;
  successTitle?: string;
  successSubtitle?: string;
  customStyle?: React.CSSProperties;
  loaderStyle?: React.CSSProperties;
}

const Loader = ({
  variant = "loading",
  loadingTitle,
  loadingSubtitle,
  successTitle,
  successSubtitle,
  customStyle,
  loaderStyle = {},
}: LoaderProps) => {
  return {
    loading: (
      <div className={s.main} style={customStyle}>
        <div className={s.loader} style={loaderStyle}>
          <img src="/app/img/ui/loader.gif" alt="" />
        </div>
        {loadingTitle && (
          <div className={s.title}>
            <Typography
              label={loadingTitle}
              variant="body_big_b"
              color="text_label"
            />
          </div>
        )}
        {loadingSubtitle && (
          <div className={s.subTitle}>
            <Typography
              label={loadingSubtitle}
              variant="body_para_m"
              color="text_placeholder"
            />
          </div>
        )}
      </div>
    ),
    success: (
      <div className={s.main}>
        <div className={s.loader}>
          <img src="/app/img/ui/successTick.gif" alt="" />
        </div>
        {successTitle && (
          <div className={s.title}>
            <Typography
              label={successTitle}
              variant="body_big_b"
              color="text_label"
            />
          </div>
        )}
        {successSubtitle && (
          <div className={s.subTitle}>
            <Typography
              label={successSubtitle}
              variant="body_para_m"
              color="text_placeholder"
            />
          </div>
        )}
      </div>
    ),
  }[variant];
};

export default Loader;
