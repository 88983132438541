// GET VEHICLE BRANDS
export const GET_VEHICLE_BRAND_START = 'misc/GET_VEHICLE_BRAND_START';
export const GET_VEHICLE_BRAND_REQUEST = 'misc/GET_VEHICLE_BRAND_REQUEST';
export const GET_VEHICLE_BRAND_SUCCESS = 'misc/GET_VEHICLE_BRAND_SUCCESS';
export const GET_VEHICLE_BRAND_FAILURE = 'misc/GET_VEHICLE_BRAND_FAILURE';
export const GET_VEHICLE_BRAND_RESET = 'misc/GET_VEHICLE_BRAND_RESET';

// Get bank list
export const GET_BANK_LIST_START = 'misc/GET_BANK_LIST_START';
export const GET_BANK_LIST_REQUEST = 'misc/GET_BANK_LIST_REQUEST';
export const GET_BANK_LIST_SUCCESS = 'misc/GET_BANK_LIST_SUCCESS';
export const GET_BANK_LIST_FAILURE = 'misc/GET_BANK_LIST_FAILURE';
export const GET_BANK_LIST_RESET = 'misc/GET_BANK_LIST_RESET';

export const RESET_MISC = 'misc/RESET';
