import React from "react";
import cn from "classnames";
import { InlinePageLoader, Spinner } from "ui";
import s from "./OverlayLoader.module.scss";

type OverlayLoaderV2Props = {
  show?: boolean;
  styleProp?: any;
  loader?: React.JSX.Element;
};

export const OverlayLoader = ({
  show = true,
  loader = <InlinePageLoader />,
  styleProp = {},
}: OverlayLoaderV2Props) => {
  if (show) {
    return (
      <div
        style={styleProp}
        className={cn("cmn_fullscreen_fixed", s.overlay_loader)}
      >
        {loader}
      </div>
    );
  }
  return null;
};
