import { trackEventFunction } from "core-lib/utils/eventTracking";
import { isObject } from "core-lib/utils/helpers";
import { EventTargets } from "../../constants/events";
import { isServer } from "../utils";

export type AttrDict = {
  [key: string]: string | number | Object;
};

/**
 * Function to track user events
 *
 * @param name Name of event
 * @param defaultAttr optional parameter; object containing default attributes like username, phone number, etc
 * @param attributes optional parameter; object containing attributes of events
 * @param eventTarget optional parameter; array of targets, where event should be sent; empty array for all targets; default value [Moengage, Clickhouse, Ems]
 * */
export function trackEvent({
  name,
  defaultAttr = null,
  attributes = null,
  eventTarget = [
    EventTargets.Moengage,
    EventTargets.Clickhouse,
    EventTargets.Ems,
  ],
}: {
  name: string;
  defaultAttr?: AttrDict;
  attributes?: AttrDict;
  eventTarget?: EventTargets[];
}) {
  let attrObj: any = {};
  if (isObject(defaultAttr)) attrObj = { ...attrObj, ...defaultAttr };
  if (isObject(attributes)) {
    const attrKeys = Object.keys(attributes);
    let iter = 0;
    const attrLen = attrKeys.length;
    for (iter; iter < attrLen; iter += 1) {
      const name = attrKeys[iter];
      attrObj[name] = attributes[name];
    }
  }
  if (global?.sessionStorage) {
    trackEventFunction(name, attrObj, eventTarget);
  }
}

export const trackCustomGTMEvent = ({
  eventName,
  attributes = {},
}: {
  eventName: string;
  attributes?: { [key: string]: any };
  defaultAttr?: { [key: string]: any };
}) => {
  if (!isServer()) {
    // @ts-ignore
    if (window?.dataLayer) {
      // @ts-ignore
      window?.dataLayer.push({
        event: eventName,
        ...attributes,
      });
    }
  }
};
