import Star from "../newIcons/Star";
import Star1 from "../newIcons/Star1";
import { Color } from "../../primitives";
import { useEffect, useState } from "react";
import s from "./RatingFeedback.module.scss";

type RatingFeedbackType = {
  rating: number;
  onRatingClick: (val) => void;
  size?: number;
  //while giving size consider adding 5px more as the path component that composes svg is 5px shorter in this case
};

export const RatingFeedback = ({
  rating,
  onRatingClick,
  size = 25,
}: RatingFeedbackType) => {
  const [userRating, setUserRating] = useState(rating);
  useEffect(() => {
    setUserRating(rating);
  }, [rating]);
  function handleClick(idx) {
    setUserRating(idx + 1);
    onRatingClick(idx);
  }
  return (
    <div className={s.root}>
      {Array(5)
        .fill(false)
        .map((val, idx) => (
          <button
            onClick={() => handleClick(idx)}
            key={idx}
            className={"cmn_touchable_area"}
          >
            {idx < rating ? (
              <Star1
                primaryColor={Color.warning_60}
                width={size}
                height={size}
              />
            ) : (
              <Star primaryColor={Color.grey_50} width={size} height={size} />
            )}
          </button>
        ))}
    </div>
  );
};
