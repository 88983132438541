/** @format */

import { IndicatorType } from "ui/primitives/ProgressIndicatorTypes";
import s from "./Linear.module.scss";

function calcWidth(noOfSteps, currentStep) {
  if (noOfSteps === 0 || currentStep > noOfSteps) {
    return "100%";
  } else if (currentStep <= 0) {
    return "0%";
  } else return `${(currentStep / noOfSteps) * 100}%`;
}

export const Linear = ({
                         noOfSteps,
                         currentStep,
                         color,
                         backgroundColor,
                         size
                       }: IndicatorType) => (
  <div
    className={s.progress}
    style={{
      background: `var(--${backgroundColor})`,
      height: size || "4px"
    }}
  >
    <div
      className={s.progress_done}
      style={{
        width: calcWidth(noOfSteps, currentStep),
        opacity: 1,
        background: `var(--${color})`
      }}
    />
  </div>
);
