/** @format */

import { OPEN_FEATURE } from "core-lib/constants/nativeBridge";
import { PLATFORM } from "core-lib/data/common";
import { passMessageToNative } from "core-lib/utils/helpers";
import { isValidMobileVersion } from "core-lib/utils/nativeBridge";
import router from "next/router";

export function formatPriceAndNumber(price: number, notation: any, style: any) {
  const formatSetting: any = { minimumFractionDigits: 0 };
  if (notation) {
    formatSetting.notation = notation;
  }
  if (style) {
    formatSetting.style = style;
    if (style === "currency") {
      formatSetting.currency = "INR";
    }
  }
  const formatter = new Intl.NumberFormat("en-IN", formatSetting);
  return formatter.format(price);
}

export const getRandomNum = () => Math.ceil(Math.random() * 100000);

export const VoucherScreenId = {
  VouchersHomeScreen: 101,
  MyVouchersScreen: 102,
  BuyVoucherScreen: 103,
  VoucherCategoryScreen: 109,
};

export const handleVoucherRedirection = ({
  redirectUrl,
  screenId,
  query = {},
}) => {
  if (
    isValidMobileVersion({ platform: PLATFORM.android, version: "6.2.10" }) ||
    isValidMobileVersion({ platform: PLATFORM.ios, version: "3.3.0" })
  ) {
    passMessageToNative(
      OPEN_FEATURE,
      null,
      JSON.stringify({
        screen_id: screenId,
        screen_data: query,
        callback_script: ``,
      })
    );
  } else {
    router.push(redirectUrl);
  }
};
