import React from "react";
import { genericErrorHandler } from "./genericErrorHandler";
import { ErrorObjectType } from "ui/primitives";

interface customToastTypes {
  variant:
    | "positivePriority"
    | "criticalPriority"
    | "warningPriority"
    | "neutralPriority"
    | "positiveNotPriority"
    | "criticalNotPriority"
    | "warningNotPriority"
    | "neutralNotPriority";
  leftIcon?: any | null;
  rightIcon?: any | null;
  duration?: number;
  label: string;
}

export function customToast({
  variant = "positivePriority" ||
    "criticalPriority" ||
    "warningPriority" ||
    "neutralPriority" ||
    "positiveNotPriority" ||
    "criticalNotPriority" ||
    "warningNotPriority" ||
    "neutralNotPriority",
  leftIcon = null,
  rightIcon = null,
  duration = 1000,
  label,
}: customToastTypes) {
  let errorObj: ErrorObjectType = { ui_type: 'toast_info', title: label };
  switch (variant) {
    case "positivePriority":
    case "positiveNotPriority":
      errorObj.ui_type = 'toast_success';
      break;
    case "criticalPriority":
    case "criticalNotPriority":
      errorObj.ui_type = 'toast_err';
      break;
    case "warningPriority":
    case "warningNotPriority":
      errorObj.ui_type = 'toast_warn';
      break;
    case "neutralPriority":
    case "neutralNotPriority":
      errorObj.ui_type = 'toast_info';
      break;
  }
  genericErrorHandler({data: {err_obj: errorObj}});
}

export function successToast(label: string, critical = true) {
  customToast({
    variant: critical ? "positivePriority" : "positiveNotPriority",
    label,
  });
}

export function failedToast(label: string, critical = true) {
  customToast({
    variant: critical ? "criticalPriority" : "criticalNotPriority",
    label,
  });
}

export function warningToast(label: string, critical = true) {
  customToast({
    variant: critical ? "warningPriority" : "warningNotPriority",
    label,
  });
}

export function neutralToast(label: string, critical = true) {
  customToast({
    variant: critical ? "neutralPriority" : "neutralNotPriority",
    label,
  });
}
