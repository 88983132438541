import classNames from "classnames";
import s from "./Header.module.scss";
import { AppBar, AppBarPropTypes } from "../AppBar";
import {
  ContentHeader,
  ContentHeaderPropTypes,
} from "../ContentHeader/ContentHeader";
import { TabBar, TabBarProps } from "../TabBar/TabBar";

type HeaderPropTypes = {
  appBar?: AppBarPropTypes;
  contentHeader?: ContentHeaderPropTypes;
  tabBar?: TabBarProps;
};

export const Header = ({ appBar, contentHeader, tabBar }: HeaderPropTypes) => (
  <div className={classNames(s.root)}>
    {appBar && <AppBar {...appBar} />}
    {contentHeader && <ContentHeader {...contentHeader} />}
    {tabBar && <TabBar {...tabBar} />}
  </div>
);
