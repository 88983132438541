import { createStore, IModuleStore } from 'redux-dynamic-modules-react-18';
import { getSagaExtension } from 'redux-dynamic-modules-saga';
import { DEFAULT_REDUX_MODULES, loadReduxModules } from './modules';

export const store: IModuleStore<any> = createStore(
  {
    initialState: {},
    enhancers: [],
    extensions: [getSagaExtension()],
  },
  ...loadReduxModules(DEFAULT_REDUX_MODULES)
);

export type RootState = ReturnType<typeof store.getState>;
