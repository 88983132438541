import ReactDOM from "react-dom";
import cn from "classnames";
import { Button, Typography } from "ui";
import { ButtonVariants } from "ui/primitives/ButtonTypes";

import { Color, TypeScales } from "../../primitives";
import s from "./NoInternet.module.scss";

const NotInternetComponent = ({
  onRefreshClick,
}: {
  onRefreshClick?: () => void;
}) => (
  <div className={cn(s.body)}>
    <div className={s.container}>
      <img
        className={s.icon}
        src="/img/ds-no-internet/no-internet.gif"
        alt="internet-icon"
      />
      <div className={s.heading}>
        <Typography
          label="Whoops!"
          color={Color.text_default}
          variant={TypeScales.h3}
        />
      </div>
      <div className={s.subHeading}>
        <Typography
          label="Slow or no internet connection"
          color={Color.text_subheading}
          variant={TypeScales.body_sb}
        />
      </div>
      <div className={s.button}>
        <Button
          onClick={onRefreshClick}
          variant={ButtonVariants.PrimaryOutline}
          label="Refresh"
          fullwidth
        />
      </div>
    </div>
  </div>
);

interface NoInternetProps {
  onRefreshClick?: () => void;
  overlay?: boolean;
}

export function NoInternet({
  onRefreshClick,
  overlay = false,
}: NoInternetProps) {
  if (!overlay) return <NotInternetComponent onRefreshClick={onRefreshClick} />;

  const appRoot = global.document.getElementsByTagName("BODY")[0];

  return ReactDOM.createPortal(
    <div className="cmn_fullscreen_fixed">
      <NotInternetComponent onRefreshClick={onRefreshClick} />
    </div>,
    appRoot
  );
}
