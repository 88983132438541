/** @format */

import { getRandomNum } from "core-lib/utils/helpers";
import { isServer } from "core-lib/utils/utils";
import { FunctionComponent, useEffect } from "react";

type props = {
  jsonFile?: any;
  path?: string;
  id?: string;
  loop?: boolean;
  autoplay?: boolean;
  speed?: number;
  onLoad?: () => void;
  delayAnimation?: number;
};

export const LottiePlayer: FunctionComponent<props> = ({
  jsonFile,
  path,
  loop = true,
  autoplay = true,
  speed = 1,
  id,
  onLoad,
  delayAnimation = 0,
}) => {
  if (isServer()) return null;

  const uniqueId = id || `animation-logo-${getRandomNum()}`;

  const runPlayer = async () => {
    const lottie = await (await import("lottie-web")).default;
    let lottiePlayer = null;
    if (path) {
      lottiePlayer = lottie.loadAnimation({
        container: document.querySelector(`#${uniqueId}`),
        path,
        loop,
        autoplay,
      });
    } else if (jsonFile) {
      lottiePlayer = lottie.loadAnimation({
        container: document.querySelector(`#${uniqueId}`),
        animationData: jsonFile,
        loop,
        autoplay,
      });
    }
    if (speed !== 1) {
      lottiePlayer.setSpeed(speed);
    }

    if (onLoad) {
      onLoad();
    }

    return lottiePlayer;
  };

  useEffect(() => {
    if (delayAnimation) {
      setTimeout(async () => {
        const lottiePlayer = await runPlayer();
        lottiePlayer.play();
      }, delayAnimation);
    } else runPlayer();
  }, [delayAnimation]);

  return <div id={uniqueId} />;
};
