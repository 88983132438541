import { Color, getThemeColor } from "ui/primitives";
import cn from "classnames";
import Typography from "../Typography";
import s from "./Snackbar.module.scss";

interface SnackbarTypes {
  variant: any;
  leftIcon?: any;
  rightIcon?: any;
  label: string;
}

function Snackbar({ leftIcon, rightIcon, label }: SnackbarTypes) {
  return (
    <div className={cn("cmn_toast_container", s.container)}>
      <div className={s.leftIcon}>{leftIcon}</div>
      <div className={s.msg}>
        <Typography
          variant="small_m"
          color={Color.background_primary}
          label={label}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        />
      </div>
      <div className={s.rightIcon}>{rightIcon}</div>
    </div>
  );
}

export default Snackbar;
