import axios from "axios";
import { ERROR_MESSAGE } from "core-lib/constants/network";

import errorHandler from "./error_handler";
import { isDataValid } from "./helpers";

export function getMethod(
  location,
  headers: any = { "Cache-Control": "no-cache" }
) {
  return axios
    .get(`${location}`, { headers })
    .then((response) => {
      return {
        response: response.data,
        toast: errorHandler(response),
        status: response.status,
      }
    })
    .catch((error) => {
      return {
        error: error?.response?.data ?? ERROR_MESSAGE.genericFailure,
        toast: errorHandler(error.response),
      };
    });
}
export function originalGetMethod(
  location,
  headers = { "Cache-Control": "no-cache" }
) {
  return axios
    .get(`${location}`, { headers })
    .then((response) =>
      // console.log('response', response);
      ({ response })
    )
    .catch((error) => {
      // console.log('error.response', error.response);
      return { error };
    });
}

export function postMethod(location, body?, headers = null) {
  let headerData = null;
  let errorMessage = "";
  if (isDataValid(headers)) {
    headerData = { headers };
  }
  return axios
    .post(`${location}`, body, headerData)
    .then((response) => ({
      response: response.data,
      status: response.status,
      toast: errorHandler(response),
    }))
    .catch((error) => {
      // console.log('error.response', error.response);
      if (
        typeof error?.response?.data === "string" &&
        error?.response?.data === ""
      ) {
        errorMessage = ERROR_MESSAGE.genericFailure;
      } else {
        errorMessage = error?.response?.data;
      }
      return {
        error: errorMessage ?? ERROR_MESSAGE.genericFailure,
        toast: errorHandler(error.response),
      };
    });
}

export function originalPostMethod(location, body, headers = null) {
  let headerData = null;
  if (isDataValid(headers)) {
    headerData = { headers };
  }
  return axios
    .post(`${location}`, body, headerData)
    .then((response) =>
      // console.log('response', response);
      ({ response })
    )
    .catch((error) =>
      // handleUnAuthorized(error);
      // console.log('error.response', error.response);
      ({ error })
    );
}

export function putMethod(location, body) {
  return axios
    .put(`${location}`, body)
    .then((response) => ({
      response: response.data,
      toast: errorHandler(response),
    }))
    .catch((error) => {
      return {
        error: error?.response?.data ?? ERROR_MESSAGE.genericFailure,
        toast: errorHandler(error.response),
      };
    });
}

export function patchMethod(location, body) {
  return axios
    .patch(`${location}`, body)
    .then((response) => ({
      response: response.data,
      toast: errorHandler(response),
    }))
    .catch((error) => {
      return {
        error: error?.response?.data ?? ERROR_MESSAGE.genericFailure,
        toast: errorHandler(error.response),
      };
    });
}