import InitStore from 'core-lib/components/InitStore';
import NetworkInterceptor from 'core-lib/components/NetworkInterceptor';
import { updateReduxRestoreState } from 'core-lib/reduxStore/setting';
import { isServer, sendToGoogleAnalytics } from 'core-lib/utils/utils';
import { NextWebVitalsMetric } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Toaster } from 'react-hot-toast';
import { Provider, useDispatch } from 'react-redux';
import DownloadAppBottomBanner from 'src/common/components/DownloadAppBottomBanner';
import { store } from 'src/common/reduxStore/store';
import 'src/components/image_viewer/image_viewer.scss';
import Organization from 'src/utils/schema/organizationSchema';
import 'ui/styles/app.scss';
import 'ui/styles/colors.scss';
import 'ui/styles/dayPicker.scss';
import 'ui/styles/modal.scss';
import 'ui/styles/slider.scss';
import 'ui/styles/theme.scss';

const ActivityRecorder = dynamic(
  () => import('core-lib/components/ActivityRecorder'),
  {
    ssr: false,
  }
);
const GenericUIWrapper = dynamic(
  () => import('src/common/components/GenericUI'),
  {
    ssr: false,
  }
);
const NativeAuthHandler = dynamic(
  () => import('core-lib/components/NativeAuthHandler'),
  {
    ssr: false,
  }
);

const NativeBackHandler = dynamic(
  () => import('core-lib/components/NativeBackHandler'),
  {
    ssr: false,
  }
);

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (!isServer()) {
    if (process.env.ENV === 'production') {
      sendToGoogleAnalytics(metric, 'WEBSITE');
    }
  }
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const contains = !!router.query?.amp;
  const { bannerContent } = pageProps;

  const bannerData = {
    title: '',
    url: '',
  };
  if (!pageProps?.stickyBanner) {
    pageProps.stickyBanner = bannerData;
  }
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no,viewport-fit=cover"
        />
      </Head>
      <Provider store={store}>
        <DownloadAppBottomBanner bannerContent={bannerContent} />
        <Component {...pageProps} />
        <GenericUIWrapper />
        <InitStoreWrapper />
        <NetworkInterceptor />
        <ActivityRecorder />
        <NativeAuthHandler />
        <NativeBackHandler />
      </Provider>
      <Organization />
      <Toaster
        position="bottom-center"
        toastOptions={{
          style: {
            padding: '5px 8px',
            fontSize: '15px',
            color: '#5568a0',
            zIndex: 99999,
          },
          success: {
            duration: 3500,
            className: 'cmn_toast_container',
            icon: null,
          },
          error: {
            duration: 3500,
            className: 'cmn_toast_container',
            icon: null,
          },
        }}
      />
      {process.env.ENV === 'production' && !contains && (
        <>
          {/* <Script
            src="https://www.googletagmanager.com/gtag/js?id=G-5MKXNVV7F6"
            strategy="worker"
          ></Script> */}
        </>
      )}
    </>
  );
}

const InitStoreWrapper = () => {
  // declarations
  const dispatch = useDispatch();

  return (
    <InitStore
      onDone={() => {
        dispatch(updateReduxRestoreState(true));
      }}
    />
  );
};

MyApp.propTypes = {
  Component: PropTypes.node.isRequired,
  pathname: PropTypes.string.isRequired,
  pageProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MyApp;
