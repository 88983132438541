import Link from "next/link";

import { getOriginByEnv } from "ui/config";

import ChevronRight from "../icons/ChevronRight";
import s from "./BreadCrumbs.module.scss";

enum Theme {
  Light = "light",
  Dark = "dark",
}
export function BreadCrumbs({
  data,
  theme = "light",
}: {
  data: any;
  theme?: "light" | "dark";
}) {
  return (
    <div className={s.root}>
      {data?.map((item, index) => (
        <div key={item.id} className={s.link_item}>
          {item.type === "internal" ? (
            <>
              <Link legacyBehavior href={item.url}>
                <a
                  href={item.url}
                  style={{ color: theme === Theme.Light ? "black" : "white" }}
                >
                  {item.name}
                </a>
              </Link>
              {index < data.length - 1 && (
                <ChevronRight
                  width={20}
                  height={20}
                  primaryColor={`${
                    theme === Theme.Light ? "grey_black" : "grey_80"
                  }`}
                />
              )}
            </>
          ) : (
            <>
              <a href={`${getOriginByEnv()}${item.url}`}>{item.name}</a>
              {index < data.length - 1 && (
                <ChevronRight width={20} height={20} />
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
}
