import cn from "classnames";
import { isServer } from "core-lib/utils/utils";
import { useEffect, useState } from "react";
import { Typography } from "ui";
import { ColorType, FontVariantType } from "ui/primitives";
import { getRandomNum } from "ui/utils";
import s from "./Digit.module.scss";

type DigitProps = {
  value: number;
  duration?: number;
  childClassname?: string;
  textClassname?: string;
  textColor?: ColorType;
  textVariant?: FontVariantType;
};

export const Digit = ({
  value,
  duration = 2000,
  childClassname,
  textClassname,
  textColor,
  textVariant,
}: DigitProps) => {
  // state
  const [variantHeight, setVariantHeight] = useState(0);

  const DIGIT_ID = `digitId-${getRandomNum()}`;

  useEffect(() => {
    if (!isServer()) {
      setVariantHeight(window.document.getElementById(DIGIT_ID).offsetHeight);
    }
  }, [variantHeight]);

  return (
    <div
      className={cn(s.digit, {
        [childClassname]: !!childClassname,
      })}
      style={{ height: variantHeight }}
    >
      <div
        className={s.digitContent}
        style={{
          top: `-${variantHeight * value}px`,
          transitionDuration: `${duration}ms`,
        }}
      >
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
          <div
            id={DIGIT_ID}
            style={variantHeight ? { height: `${variantHeight}px` } : {}}
          >
            <Typography
              label={i}
              variant={textVariant}
              color={textColor}
              key={i}
              className={cn(s.digitValue, {
                [textClassname]: !!textClassname,
              })}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
