import layoutReducer from './reducer';

export function getLayoutModule() {
  return {
    // Unique id of the module
    id: 'layout',
    // Maps the Store key to the reducer
    reducerMap: {
      layout: layoutReducer,
    },
    // This module uses redux-saga middleware
    // This property will be be used by the SagaExtension
    // to run sagas for the moduleD
    sagas: [],
  };
}
