import { LocalStorage } from "core-lib/constants/storage";
import { removeAuthHeaders } from "./apiV2";
import { isServer } from "core-lib/utils/utils";
import { COOKIE } from "../types/Helper";
import { deleteCookieValue } from "./cookie";

export function clearAuthData() {
  deleteCookieValue(COOKIE.accessToken);
  deleteCookieValue(COOKIE.refreshToken);
  global.localStorage.removeItem(LocalStorage.Profile);

  removeAuthHeaders();
}

export function clearSessionStorage() {
  if (!isServer()) global.sessionStorage.clear();
}

export function clearLocalStorage() {
  if (!isServer()) global.localStorage.clear();
}
export function storeLocalProfile(profile: string): void {
  if (profile && !isServer())
    global.localStorage.setItem(LocalStorage.Profile, profile);
}

export function storeUserLocations(userLocations: string): void {
  if (userLocations && !isServer())
    global.localStorage.setItem(LocalStorage.UserLocations, userLocations);
}

export function getLocalProfile() {
  if (!isServer()) {
    const profile = global.localStorage.getItem(LocalStorage.Profile);
    if (profile) return JSON.parse(profile);
  }
  return undefined;
}
