import * as React from "react";
import cn from "classnames";
import Cross from "ui/components/newIcons/Cross";
import TransitionTimeout from "ui/components/Transitions/TransitionTimeout";
import { ColorType, Typography } from "ui";
import s from "./Tooltip.module.scss";

const ArrowPosition = {
  topLeft: "topLeft",
  topCenter: "topCenter",
  topRight: "topRight",
  bottomLeft: "bottomLeft",
  bottomCenter: "bottomCenter",
  bottomRight: "bottomRight",
  left: "left",
  right: "right"
};

interface TooltipProps {
  variant: "dark" | "light";
  label: string;
  heading?: string;
  arrowPosition?: keyof typeof ArrowPosition;
  closeBtn?: {
    show: boolean;
    onClose: () => void;
  };
  button?: {
    show: boolean;
    label: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  };
  bgColor?: string;
}

const Tooltip: React.FunctionComponent<TooltipProps> = ({
                                                          variant,
                                                          label,
                                                          heading,
                                                          arrowPosition = null,
                                                          closeBtn = {
                                                            show: false, onClose: () => {
                                                            }
                                                          },
                                                          button = {
                                                            show: false, label: "Ok", onClick: () => {
                                                            }
                                                          },
                                                          bgColor = null
                                                        }) => {


  const backgroundStyle =  React.useMemo(() => {
    if(bgColor) return bgColor;
      return variant === "dark" ? "var(--text_default)" : "var(--background_primary)";
    }, [variant, bgColor])

  return (
    <TransitionTimeout animation={{ type: "popup", timeout: "s" }}>
      <div
        className={s.tooltip}
        style={{
          backgroundColor: backgroundStyle
        }}
      >
        <div>
          {heading && (
            <Typography
              variant="body_para_b"
              color={variant === "dark" ? "background_primary" : "text_default"}
              label={heading}
            />
          )}
          <Typography
            variant="small_para_r"
            color={variant === "dark" ? "background_primary" : "text_default"}
            label={label}
          />
        </div>

        {closeBtn && closeBtn.show && (
          <div
            className={cn(s.cross, "cmn_touchable_area")}
            onClick={(e) => {
              e.stopPropagation();
              closeBtn.onClose();
            }}
          >
            {variant === "dark" ? (
              <Cross width={16} height={16} primaryColor="background_primary" />
            ) : (
              <Cross width={16} height={16} />
            )}
          </div>
        )}
        {button && button.show && (
          <button
            type="button"
            onClick={button.onClick}
            className={cn(s.btn)}
          >
            <Typography label={button.label} variant="small_m" color="grey_40" />
          </button>
        )}

        {
          {
            [ArrowPosition.topLeft]: (
              <div
                className={cn(s.common, s.topLeft)}
                style={{
                  borderBottomColor:
                    backgroundStyle
                }}
              />
            ),
            [ArrowPosition.topCenter]: (
              <div
                className={cn(s.common, s.topCenter)}
                style={{
                  borderBottomColor:
                    backgroundStyle
                }}
              />
            ),
            [ArrowPosition.topRight]: (
              <div
                className={cn(s.common, s.topRight)}
                style={{
                  borderBottomColor:
                    backgroundStyle
                }}
              />
            ),
            [ArrowPosition.right]: (
              <div
                className={cn(s.common, s.right)}
                style={{
                  borderLeftColor:
                    backgroundStyle
                }}
              />
            ),
            [ArrowPosition.bottomRight]: (
              <div
                className={cn(s.common, s.bottomRight)}
                style={{
                  borderTopColor:
                    backgroundStyle
                }}
              />
            ),
            [ArrowPosition.bottomCenter]: (
              <div
                className={cn(s.common, s.bottomCenter)}
                style={{
                  borderTopColor:
                    backgroundStyle
                }}
              />
            ),
            [ArrowPosition.bottomLeft]: (
              <div
                className={cn(s.common, s.bottomLeft)}
                style={{
                  borderTopColor:
                    backgroundStyle
                }}
              />
            ),
            [ArrowPosition.left]: (
              <div
                className={cn(s.common, s.left)}
                style={{
                  borderRightColor:
                    backgroundStyle
                }}
              />
            )
          }[arrowPosition]
        }
      </div>
    </TransitionTimeout>
  );
};

export default Tooltip;
