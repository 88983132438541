import React from "react";
import { IconTypes } from "../../primitives/IconTypes";
import { Color } from "../../primitives";

function CrossCircle({
                       variant = "linear",
                       primaryColor = Color.grey_70,
                       secondaryColor = Color.grey_90,
                       width = 24,
                       height = 24
                     }: IconTypes) {
  const primaryColorValue = `var(--${primaryColor})`;
  const secondaryColorValue = `var(--${secondaryColor})`;
  return {
    linear: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke={primaryColorValue}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10zM9.17 14.83l5.66-5.66M14.83 14.83L9.17 9.17"
        />
      </svg>
    ),
    bulk: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill={primaryColorValue}
          d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
          opacity="0.4"
        />
        <path
          fill={secondaryColorValue}
          d="M13.06 12l2.3-2.3c.29-.29.29-.77 0-1.06a.754.754 0 00-1.06 0l-2.3 2.3-2.3-2.3a.754.754 0 00-1.06 0c-.29.29-.29.77 0 1.06l2.3 2.3-2.3 2.3c-.29.29-.29.77 0 1.06.15.15.34.22.53.22s.38-.07.53-.22l2.3-2.3 2.3 2.3c.15.15.34.22.53.22s.38-.07.53-.22c.29-.29.29-.77 0-1.06l-2.3-2.3z"
        />
      </svg>
    ),
    twoTone: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke={primaryColorValue}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10z"
        />
        <g
          stroke={primaryColorValue}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          opacity="0.4"
        >
          <path d="M9.17 14.83l5.66-5.66M14.83 14.83L9.17 9.17" />
        </g>
      </svg>
    )
  }[variant];
}

export default CrossCircle;
