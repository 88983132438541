import Button from "ui/components/Button";
import Modal from "ui/components/Modal";
import Typography from "ui/components/Typography";
import Info1 from "ui/components/newIcons/Info1";
import { Color, ErrorPopupProps, ErrorUiTypeEnum } from "ui/primitives";
import s from "./ErrorPopup.module.scss";

export const ErrorPopup: React.FunctionComponent<ErrorPopupProps> = ({
  show,
  onClose,
  errObj,
  cta = [],
}) => {
  const getIconColor = () => {
    switch (errObj?.ui_type) {
      case ErrorUiTypeEnum.dialog_err:
        return Color.negative_text;
      case ErrorUiTypeEnum.dialog_warn:
        return Color.warning_text;
      default:
        return Color.warning_text;
    }
  };

  return (
    <Modal
      show={show}
      head={{ show: true, onClose: onClose }}
      rootStyle={{ zIndex: 5 }}
      variant="popup"
      animation={{ timeout: "m", type: "popup" }}
    >
      <div className={s.root}>
        <div className={s.content}>
          <div className={s.icon}>
            <Info1 height={48} width={48} primaryColor={getIconColor()} />
          </div>
          <div className={s.text}>
            <Typography label={errObj?.title} variant="h1" renderAs="H1" />
            {errObj?.description && (
              <Typography
                label={errObj?.description}
                variant="body_para_r"
                color="text_subheading"
              />
            )}
          </div>

          <div className={s.btn}>
            {cta?.length > 0 ? (
              cta.map((item, idx) => (
                <Button
                  key={`${idx}^^@`}
                  label={item?.label}
                  onClick={item?.onClick}
                  variant={item?.variant}
                />
              ))
            ) : (
              <Button label="Close" onClick={onClose} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
