import React from "react";
import { Color, getThemeColor, IconTypes } from "ui/primitives";

export const FullStar = ({
                           height = 20,
                           width = 20,
                           primaryColor = Color.warning_30
                         }: IconTypes) => {
  const primaryColorValue = `var(--${primaryColor})`;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.148 2.266C19.788 0.578 22.21 0.578 22.852 2.266L26.992 13.734C27.1365 14.1076 27.3908 14.4286 27.7214 14.6546C28.0521 14.8806 28.4435 15.0011 28.844 15H39.018C40.898 15 41.718 17.34 40.24 18.486L33 25C32.6757 25.2493 32.4387 25.5949 32.323 25.9873C32.2073 26.3797 32.2189 26.7986 32.356 27.184L35 38.39C35.644 40.19 33.56 41.736 31.984 40.628L22.15 34.388C21.8133 34.1513 21.4117 34.0243 21 34.0243C20.5884 34.0243 20.1868 34.1513 19.85 34.388L10.016 40.628C8.44203 41.736 6.35603 40.188 7.00003 38.39L9.64403 27.184C9.78119 26.7986 9.79275 26.3797 9.67705 25.9873C9.56135 25.5949 9.32434 25.2493 9.00003 25L1.76003 18.486C0.280031 17.34 1.10403 15 2.98003 15H13.154C13.5546 15.0013 13.9462 14.881 14.2769 14.6549C14.6076 14.4289 14.8618 14.1077 15.006 13.734L19.146 2.266H19.148Z"
        fill={primaryColorValue}
        stroke={primaryColorValue}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
