/** @format */

import Compressor from "compressorjs";
import {
  CLOSE_SCREEN_EVENT,
  NativeScreenId,
  OPEN_FEATURE,
  SET_SHARED_PREFERENCE,
  SHARE_EVENT,
  SHARE_WITHOUT_SCEREENSHOT,
} from "core-lib/constants/nativeBridge";
import { ERROR_MESSAGE } from "core-lib/constants/network";
import { LocalStorage, SessionStorage } from "core-lib/constants/storage";
import dayjs from "dayjs";
import { getOrigin } from "../config/config";
import { customToast } from "./customToast";
import { passMessageToNative } from "./nativeBridge";
import {
  isMoaWebView,
  isParkPlusPWA,
  isParkPlusWebView,
  isPmsWebView,
  isPndWebView,
  isUCDWebView,
} from "./source";
import { clearAuthData, clearSessionStorage } from "./tokenStorage";
import { isServer, isStringValid } from "./utils";

export function onReset() {
  if (isParkPlusWebView()) {
    global.localStorage.removeItem(LocalStorage.Profile);
    return null;
  } else {
    clearAuthData();
    clearSessionStorage();
    if (isParkPlusPWA() && global.Moengage) {
      global.Moengage.destroy_session();
    }
  }
}

export function resetWebView() {
  passMessageToNative(CLOSE_SCREEN_EVENT);
  onReset();
}

export function redirectToNativeLogin() {
  passMessageToNative(SET_SHARED_PREFERENCE, null, {
    key: "auth_token",
    value: "",
  });
  passMessageToNative(
    OPEN_FEATURE,
    null,
    JSON.stringify({
      screen_id: NativeScreenId.Login,
    })
  );
  resetWebView();
}

export function getQueryObj(url = null): { [key: string]: any } {
  if (!isServer()) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlSearchParams.entries());
  } else if (isStringValid(url)) {
    const urlSearchParams = new URLSearchParams(`?${url.split("?")[1]}`);
    return Object.fromEntries(urlSearchParams.entries());
  }
  return {};
}

export const getCommaSeparatedValues = (value: number) => {
  const valueString = String(value);
  let lastThree = valueString.substring(valueString.length - 3);
  const otherNumbers = valueString.substring(0, valueString.length - 3);
  if (otherNumbers !== "") lastThree = `,${lastThree}`;
  return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
};

export const getHoursAndMinutesTimeDifference = (time: string) => {
  if (time) {
    const current = dayjs(new Date());
    const provided = dayjs(time);
    const diff = current.diff(provided, "m");
    const hours = Math.floor(diff / 60);
    const minutes = diff % 60;
    if (hours > 0) {
      return `${getCommaSeparatedValues(hours)} h ${getCommaSeparatedValues(
        minutes
      )} m`;
    }
    return `${minutes} m`;
  }
  return null;
};

export const getCompressedImage = async (
  file: File,
  quality,
  convertSize = 500
) => {
  try {
    const imageCompression = new Promise((resolve, reject) => {
      const compressor = new Compressor(file, {
        quality: quality,
        convertTypes: [
          "image/png",
          "image/jpeg",
          "image/bmp",
          "image/gif",
          "image/ief",
          "image/svg+xml",
          "image/tiff",
          "image/pipeg",
        ],
        convertSize: convertSize,
        success(file: File | Blob) {
          resolve(file);
        },
      });
    });
    const compressedBlob: any = await imageCompression;
    const { name, type }: any = compressedBlob;
    return new File([compressedBlob], name, {
      type,
    });
  } catch (e) {
    return file;
  }
};

export function formatNumber(args) {
  if (typeof args === "number" || typeof args === "bigint") {
    return Intl.NumberFormat("en-US", {
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(args);
  }
  return "-";
}

export function handleBack() {
  if (!isServer()) {
    if (
      isParkPlusWebView() ||
      isUCDWebView() ||
      isPndWebView() ||
      isPmsWebView() ||
      isMoaWebView()
    ) {
      const routeName =
        global?.sessionStorage.getItem(SessionStorage.InitialPagePath) ?? "";
      if (
        ["/transaction", "/razorpay/callback", "/payu/"].some((el) =>
          routeName.includes(el)
        ) ||
        global.location.pathname === routeName
      ) {
        resetWebView();
      } else {
        const prev = window.location.pathname;
        window.history.back();
        setTimeout(() => {
          if (window.history.length <= 1 && prev === window.location.pathname) {
            resetWebView();
          }
        }, 2000);
      }
    } else {
      window.history.back();
    }
  }
}

export function formatPriceAndNumber(price: number, notation, style) {
  const formatSetting: any = { minimumFractionDigits: 0 };
  if (notation) {
    formatSetting.notation = notation;
  }
  if (style) {
    formatSetting.style = style;
    if (style === "currency") {
      formatSetting.currency = "INR";
    }
  }
  const formatter = new Intl.NumberFormat("en-IN", formatSetting);
  return formatter.format(price);
}

export async function share({
  msg,
  withScreenShot = false,
}: {
  msg: string;
  withScreenShot?: boolean;
}) {
  if (isParkPlusWebView()) {
    const event = withScreenShot ? SHARE_EVENT : SHARE_WITHOUT_SCEREENSHOT;
    passMessageToNative(event, "Share", msg);
  } else {
    try {
      await window.navigator.share({
        url: window.location.href,
        text: msg,
      });
    } catch (err) {
      console.log("err>>", err);
      customToast({
        variant: "criticalNotPriority",
        label: ERROR_MESSAGE.genericFailure,
      });
    }
  }
}

export const removeSkeletonFromDOM = () => {
  if (!isServer()) {
    if (global?.document) {
      const skeletonNode = document.getElementById("skeleton_wrapper");
      if (skeletonNode) skeletonNode.innerHTML = "";
    }
  }
};

export const formatTitleCase = (string: string) => {
  if (!string) return "";
  const sentence = string.trim().toLowerCase().split(" ");
  for (let i = 0; i < sentence.length; i++) {
    if (sentence[i] && sentence[i].length > 0)
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(" ");
};

function replaceAlphaNumericWithMask(alphaNum) {
  if (alphaNum && alphaNum !== "" && String(alphaNum).length) {
    return String(alphaNum)?.replace(/[a-z0-9]/gi, "*");
  }
  return alphaNum;
}

export function maskAlphaNumericObj(obj) {
  const newObj = {};
  if (obj && typeof obj === "object") {
    const keys = Object.keys(obj);
    for (var keyIter = 0; keyIter < keys.length; keyIter += 1) {
      var item = obj[keys[keyIter]];
      if (item && typeof item === "object") {
        newObj[keys[keyIter]] = maskAlphaNumericObj(item);
      } else {
        newObj[keys[keyIter]] = replaceAlphaNumericWithMask(item);
      }
    }
  }
  return newObj;
}

/**
 * Used for inter app routing (e.g. community -> shop)
 * @returns object { push, redirect }: push works same as router.push and replace works same as router.replace
 * */
export const useRedirection = () => {
  if (!isServer()) {
    const { history, location } = window;

    return {
      /**
       * @param url takes relative url (without origin) as a parameter e.g. /app/content/social-community
       */
      push: (url, withBaseUrl = false) => {
        const redirectionUrl = withBaseUrl ? url : `${getOrigin()}${url}`;
        // temporary fix in case of events
        setTimeout(() => {
          history.pushState({}, "", `${redirectionUrl}`);
          location.href = `${redirectionUrl}`;
        }, 500);
      },

      /**
       * @param url takes relative url (without origin) as a parameter e.g. /app/content/social-community
       */
      replace: (url, withBaseUrl = false) => {
        const redirectionUrl = withBaseUrl ? url : `${getOrigin()}${url}`;
        setTimeout(() => location.replace(redirectionUrl), 500);
      },
    };
  }
};
