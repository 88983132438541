/** @format */

import { Color } from "ui/primitives";
import { IndicatorType } from "ui/primitives/ProgressIndicatorTypes";
import Typography from "../../Typography";
import s from "./LinearWithSteps.module.scss";
import SingleTick from "../../../components/newIcons/SingleTick";

function calcWidth(noOfSteps, currentStep) {
  if (noOfSteps === 0 || currentStep > noOfSteps) {
    return "100%";
  } else if (currentStep <= 1) {
    return "0%";
  } else return `${((currentStep - 1) / (noOfSteps - 1)) * 100}%`;
}

export const LinearWithSteps = ({
                                  noOfSteps,
                                  currentStep,
                                  steps,
                                  color = Color.interaction_60,
                                  textColor = Color.text_default
                                }: IndicatorType) => (
  <div className={s.wrapper}>
    <div className={s.progress}>
      <div
        className={s.progress_done}
        style={{
          width: calcWidth(noOfSteps, currentStep),
          opacity: 1,
          background: `var(--${color})`
        }}
      />
      <div className={s.dots}>
        {steps?.length &&
          steps?.map((item, idx) => (
            <div
              style={
                currentStep > idx
                  ? { background: `var(--${color})` }
                  : { background: "#e2e2e2" }
              }
              className={currentStep > idx ? s.active : ""}
              key={item?.key}
            >
              {currentStep > idx && (
                <SingleTick primaryColor="grey_white" width={12} height={12} />
              )}
            </div>
          ))}
      </div>
      <div className={s.steps_wrapper}>
        {steps?.length &&
          steps?.map((item) => (
            <div key={item?.label}>
              <Typography
                renderAs="P"
                label={item?.label}
                color={textColor}
                variant="small_m"
              />
            </div>
          ))}
      </div>
    </div>
  </div>
);
