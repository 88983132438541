import React from "react";
import { IconTypes } from "../../primitives/IconTypes";
import { Color, getThemeColor } from "../../primitives";

function Cross({
                 variant = "linear",
                 primaryColor = Color.grey_50,
                 width = 32,
                 height = 32
               }: IconTypes) {
  const primaryColorValue = `var(--${primaryColor})`;

  return {
    linear: (
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.3334 15.9998C29.3334 23.3636 23.3639 29.3332 16.0001 29.3332C8.63628 29.3332 2.66675 23.3636 2.66675 15.9998C2.66675 8.63604 8.63628 2.6665 16.0001 2.6665C23.3639 2.6665 29.3334 8.63604 29.3334 15.9998ZM11.9596 11.9594C12.3501 11.5688 12.9833 11.5688 13.3738 11.9594L16 14.5856L18.6263 11.9594C19.0168 11.5689 19.6499 11.5689 20.0405 11.9594C20.431 12.3499 20.431 12.9831 20.0405 13.3736L17.4143 15.9998L20.0404 18.626C20.431 19.0165 20.431 19.6497 20.0404 20.0402C19.6499 20.4307 19.0167 20.4307 18.6262 20.0402L16 17.414L13.3738 20.0403C12.9833 20.4308 12.3501 20.4308 11.9596 20.0403C11.5691 19.6497 11.5691 19.0166 11.9596 18.626L14.5858 15.9998L11.9596 13.3736C11.5691 12.9831 11.5691 12.3499 11.9596 11.9594Z"
          fill="#C4C4C4"
        />
      </svg>
    ),
    bulk: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill={primaryColorValue}
          d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81v8.37C2 19.83 4.17 22 7.81 22h8.37c3.64 0 5.81-2.17 5.81-5.81V7.81C22 4.17 19.83 2 16.19 2z"
          opacity="0.4"
        />
        <path
          fill={primaryColorValue}
          d="M15.712 7.227L12 10.939 8.288 7.227a.755.755 0 00-1.06 0c-.29.29-.29.77 0 1.06L10.938 12l-3.712 3.712c-.29.29-.29.771 0 1.061.29.29.77.29 1.06 0L12 13.061l3.712 3.712c.29.29.771.29 1.061 0 .29-.29.29-.77 0-1.06L13.061 12l3.712-3.712c.29-.29.29-.771 0-1.061a.755.755 0 00-1.06 0z"
        />
      </svg>
    ),
    twoTone: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke={primaryColorValue}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M7.757 16.242l8.486-8.485"
          opacity="0.4"
        />
        <path
          stroke={primaryColorValue}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M16.243 16.243L7.757 7.758"
        />
      </svg>
    ),
    dark: (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 1.00009L1 15M15 14.9999L1 1"
          stroke="#0E0E0E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }[variant];
}

export default Cross;
