import { Typography } from "ui";
import { Color } from "ui/primitives";
import { IndicatorType } from "ui/primitives/ProgressIndicatorTypes";
import TickLinear from "../../icons/Tick";
import s from "./LinearWithIndex.module.scss";

function calcWidth(noOfSteps, currentStep) {
  if (noOfSteps === 0 || currentStep > noOfSteps) {
    return "100%";
  } else return `${(currentStep / (noOfSteps - 1)) * 100}%`;
}
export const LinearWithIndex = ({
  noOfSteps,
  currentStep,
  steps,
  horizontalMargin,
  textColor
}: IndicatorType) => (
  <div className={s.root}>
    <div
      className={s.wrapper}
      style={{ paddingLeft: horizontalMargin, paddingRight: horizontalMargin }}
    >
      <div className={s.progress}>
        <div
          className={s.progress_done}
          style={{ width: calcWidth(noOfSteps, currentStep), opacity: 1 }}
        />
        <div className={s.dots}>
          {steps?.length &&
            steps?.map((item, idx) => (
              <div
                className={currentStep > idx ? s.active : s.unactive}
                key={item?.key}
              >
                {currentStep > idx ? (
                  <TickLinear
                    variant="linear"
                    primaryColor={Color.grey_white}
                    width={12}
                    height={12}
                    secondaryColor={Color.grey_white}
                  />
                ) : (
                  idx + 1
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
    <div className={s.steps_wrapper}>
      {steps?.length &&
        steps?.map((item) => (
          <div key={item?.label}>
            <Typography
              renderAs="P"
              label={item?.label}
              color={textColor}
              variant="small_m"
            />
          </div>
        ))}
    </div>
  </div>
);
