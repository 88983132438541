import { Color, TypeScales } from "ui/primitives";
import { Typography } from "ui";
import s from "./ContentHeader.module.scss";
export interface ContentHeaderPropTypes {
  title: string;
  subtitle: string;
}
export function ContentHeader(props: ContentHeaderPropTypes) {
  const { title, subtitle } = props;

  return (
    <div className={s.root}>
      <Typography
        variant={TypeScales.h2}
        color={Color.text_default}
        label={title}
      />
      <Typography
        variant={TypeScales.body_para_m}
        color={Color.background_tertiary}
        label={subtitle}
      />
    </div>
  );
}
