export enum BadgeVariants {
  Success = "Success",
  SuccessOutline = "SuccessOutline",
  SuccessNaked = "SuccessNaked",

  Warning = "Warning",
  WarningOutline = "WarningOutline",
  WarningNaked = "WarningNaked",

  Error = "Error",
  ErrorOutline = "ErrorOutline",
  ErrorNaked = "ErrorNaked",

  Black = "Black",
  BlackOutline = "BlackOutline",
  BlackNaked = "BlackNaked",

  Blue = "Blue",
  BlueOutline = "BlueOutline",
  BlueNaked = "BlueNaked",

  GeneralPrimary = "GeneralPrimary",
  GeneralSecondary = "GeneralSecondary",
  GeneralTertiary = "GeneralTertiary",

  SuccessPrimary = "SuccessPrimary",
  SuccessSecondary = "SuccessSecondary",
  SuccessTertiary = "SuccessTertiary",

  FailurePrimary = "FailurePrimary",
  FailureSecondary = "FailureSecondary",
  FailureTertiary = "FailureTertiary",

  InformationPrimary = "InformationPrimary",
  InformationSecondary = "InformationSecondary",
  InformationTertiary = "InformationTertiary",

  AlertPrimary = "AlertPrimary",
  AlertSecondary = "AlertSecondary",
  AlertTertiary = "AlertTertiary",
}
