import classNames from "classnames";
import { Badge, Typography } from "ui";
import { ChipVariants } from "ui/primitives/ChipTypes";
import { ChipVariantsType } from "../../primitives";
import Add from "../icons/Add";
import CrossCircle from "../icons/CrossCircle";
import s from "./Chip.module.scss";

type ChipProps = {
  variant: ChipVariantsType;
  state?: "enabled" | "disabled" | "selected";
  label?: string;
  icon?: any;
  suggestiveChipBadgeLabel?: string;
  hideAddIconInAssistive?: boolean;
  onClick?: () => void;
  onCancelFilterClick?: () => void;
  showCross?: boolean;
  isRounded?: boolean;
};

export const Chip = ({
  variant = ChipVariants.Filled,
  label,
  state = "enabled",
  icon,
  suggestiveChipBadgeLabel = "Label",
  hideAddIconInAssistive = false,
  onClick = () => {},
  onCancelFilterClick = () => {},
  showCross = false,
  isRounded = false,
}: ChipProps) => (
  <button
    type="button"
    className={classNames(s.chip, s[variant], s[state], {
      [s.rounded]: isRounded,
    })}
    onClick={() => {
      state === "disabled" ? () => {} : onClick();
    }}
  >
    {icon && (variant !== ChipVariants.Assistive || hideAddIconInAssistive) && (
      <div className={s.icon}>{icon}</div>
    )}
    {variant === ChipVariants.Assistive && !hideAddIconInAssistive && (
      <Add
        width={20}
        height={20}
        variant="linear"
        primaryColor={
          state === "enabled"
            ? "text_default"
            : state === "selected"
            ? "grey_white"
            : state === "disabled"
            ? "grey_60"
            : "text_default"
        }
      />
    )}
    {label && (
      <Typography
        variant={
          variant === ChipVariants.Suggestive && state === "selected"
            ? "body_m"
            : "body_r"
        }
        label={label}
        color="text_default"
      />
    )}

    {variant === ChipVariants.Filter && state === "selected" && showCross && (
      <div
        className={classNames("cmn_touchable_area", { [s.cancel]: showCross })}
        onClick={onCancelFilterClick}
      >
        {showCross && (
          <CrossCircle width={20} height={20} primaryColor="text_default" />
        )}
      </div>
    )}

    {variant === ChipVariants.Suggestive && state !== "disabled" && (
      <div className={s.badge}>
        <Badge
          variant="GeneralSecondary"
          label={suggestiveChipBadgeLabel}
          isRectangle
        />
      </div>
    )}
  </button>
);
