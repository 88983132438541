import profileReducer from './reducer';
import { watchProfile } from './action';

export function getProfileModule() {
  return {
    // Unique id of the module
    id: 'profile',
    // Maps the Store key to the reducer
    reducerMap: {
      profile: profileReducer,
    },
    // This module uses redux-saga middleware
    // This property will be be used by the SagaExtension
    // to run sagas for the moduleD
    sagas: [watchProfile],
  };
}
