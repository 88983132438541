import ip from "icepick";
import {
  UPDATE_APP_MODE,
  UPDATE_REDUX_RESTORE_STATE,
  UPDATE_THEME,
  RESET_SETTING,
  UPDATE_LANGUAGE,
} from "./type";
import { APP_MODE } from "core-lib/data/common";
import { Language, Theme } from "core-lib/constants/setting";

const initialState = ip.freeze({
  appMode: APP_MODE.b2c,
  isStateRestored: false,
  theme: Theme.Default,
  language: Language.English,
});

export function settingReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_APP_MODE: {
      state = ip.setIn(state, ["appMode"], action.payload);
      return state;
    }

    case UPDATE_REDUX_RESTORE_STATE: {
      state = ip.setIn(state, ["isStateRestored"], action.payload);
      return state;
    }

    case UPDATE_THEME: {
      state = ip.setIn(state, ["theme"], action.payload);
      return state;
    }

    case UPDATE_LANGUAGE: {
      state = ip.setIn(state, ["language"], action.payload);
      return state;
    }

    case RESET_SETTING: {
      state = ip.setIn(state, ["appMode"], initialState.appMode);
      state = ip.setIn(
        state,
        ["isStateRestored"],
        initialState.isStateRestored
      );
      return state;
    }

    default:
      return state;
  }
}

export default settingReducer;
