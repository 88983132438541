import PropTypes from "prop-types";
import { Spinner } from "ui";
import { SpinnerSize, VariantType } from "ui/components/Spinner/Spinner";
import s from "./InlinePageLoader.module.scss";

type InlinePageLoaderProps = {
  show: boolean;
  height?: number | string;
  size?: SpinnerSize;
  spinnerVariant?: VariantType;
};

export const InlinePageLoader = ({
  show,
  height,
  size,
  spinnerVariant,
}: InlinePageLoaderProps) => {
  if (show) {
    return (
      <div className={s.inline_loader} style={{ height }}>
        <div className={s.container}>
          <Spinner size={size} variant={spinnerVariant} />
        </div>
      </div>
    );
  }
  return null;
};

InlinePageLoader.propTypes = {
  show: PropTypes.bool,
  height: PropTypes.string,
  size: PropTypes.string,
};

InlinePageLoader.defaultProps = {
  show: true,
  height: "100%",
  size: "m",
};
