import Head from 'next/head';

function Organization() {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://www.schema.org',
            '@type': 'Organization',
            name: 'Park+',
            url: 'https://parkplus.io/',
            sameAs: [
              'https://twitter.com/parkplus_io',
              'https://www.facebook.com/prkpls/',
              'https://www.instagram.com/parkplusio/',
              'https://www.linkedin.com/company/parkplus/',
              'https://www.youtube.com/channel/UCschDYcHV2A6m-qi5fwCNDw',
            ],
            logo: 'https://strapi-file-uploads.parkplus.io/park_Logo_ebc88c4a81.png',
            description:
              'At Park+, we are building an eco-system for car owners which will be a one-stop solution for all their needs. From parking reservation to challan payments, FASTag recharge to RTO related information, real time fuel price information to nearest PUC center search, we have a lot to offer. We also offer smart parking automated solutions to corporates, apartments, malls, hospitals and other commercial establishments. Our app is a life savior for daily commuters as it provides them with a seamless and real-time experience for parking discovery, booking, payment and tracking.',
            address: {
              '@type': 'PostalAddress',
              streetAddress: '5th Floor, Tower A, Unitech Cyber Park, Sec-39',
              addressLocality: 'Gurugram',
              addressRegion: 'Haryana',
              postalCode: '122022',
              addressCountry: 'IN',
            },
            brand: {
              '@type': 'Brand',
              name: 'Park+',
            },
          }),
        }}
      />
    </Head>
  );
}

export default Organization;
