import { SessionStorage } from "core-lib/constants/storage";
import { getRandomNum } from "./helpers";

export function setAndGetBackBtnClass() {
  const randomNum = getRandomNum();
  const randomClass = `backBtnClass${randomNum}`;
  const sessionClasses = global.sessionStorage.getItem(
    SessionStorage.ActiveBackBtnClass
  );
  let activeClasses = [];
  if (sessionClasses) {
    const parsedClasses = JSON.parse(sessionClasses);
    if (!Array.isArray(parsedClasses)) {
      activeClasses = [];
    } else {
      activeClasses = parsedClasses;
    }
  }
  activeClasses.push(randomClass);
  global.sessionStorage.setItem(
    SessionStorage.ActiveBackBtnClass,
    JSON.stringify(activeClasses)
  );
  return randomClass;
}

export function removeClassFromList(cn: any) {
  const sessionClasses = global.sessionStorage.getItem(
    SessionStorage.ActiveBackBtnClass
  );
  if (sessionClasses) {
    const parsedClasses = JSON.parse(sessionClasses);
    if (Array.isArray(parsedClasses)) {
      const index = parsedClasses.indexOf(cn);
      if (index !== -1) {
        parsedClasses.splice(index, 1);
      }
    }
    global.sessionStorage.setItem(
      SessionStorage.ActiveBackBtnClass,
      JSON.stringify(parsedClasses)
    );
  }
}
